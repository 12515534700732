import { connect } from "react-redux";

import ClearAccountForm from "./clear-account-form.component"; 

import {postClearAccount} from "../../../redux/settings/settings.actions"; 
import { onCloseModal } from "core/redux/modal/modal.action";
 

const mapDispatchToProps = {
    postClearAccount, 
    onCloseModal
};

export default connect(
  null,
  mapDispatchToProps
)(ClearAccountForm);
