const crud_form_operators_types = {
  POST_NEW_OPERATOR: "POST_NEW_OPERATOR",
  SUCCESS_POST_NEW_OPERATOR: "SUCCESS_POST_NEW_OPERATOR",
  FAIL_POST_NEW_OPERATOR: "FAIL_POST_NEW_OPERATOR",
  DEL_OPERATOR: "DEL_OPERATOR",
  SUCCESS_DEL_OPERATOR: "SUCCESS_DEL_OPERATOR",
  FAIL_DEL_OPERATOR: "FAIL_DEL_OPERATOR",
  GET_OPERATOR: "GET_OPERATOR",
  LOADING_GET_OPERATOR: "LOADING_GET_OPERATOR",
  SUCCESS_GET_OPERATOR: "SUCCESS_GET_OPERATOR",
  FAIL_GET_OPERATOR: "FAIL_GET_OPERATOR",
  PUT_OPERATOR: "PUT_OPERATOR",
  SUCCESS_PUT_OPERATOR: "SUCCESS_PUT_OPERATOR",
  FAIL_PUT_OPERATOR: "FAIL_PUT_OPERATOR",
  GET_BY_ID_OPERATOR: "GET_BY_ID_OPERATOR",
  LOADING_GET_BY_ID_OPERATOR: "LOADING_GET_BY_ID_OPERATOR",
  SUCCESS_GET_BY_ID_OPERATOR: "SUCCESS_GET_BY_ID_OPERATOR",
  FAIL_GET_BY_ID_OPERATOR: "FAIL_GET_BY_ID_OPERATOR",
};

export default crud_form_operators_types;
