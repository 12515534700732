import crud_form_massified_types from "./form-massified.types";

const INITIAL_STATE = {
  table: { 
    loading: true,
    items: [ ],
    error: false,
  },
  edit: { 
    loading: false,
    items: {},
    error: false
  },
  upload: { 
    loading: false,
    items: {},
    error: false
  }
};

const crud_form_massified_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case crud_form_massified_types.LOADING_GET_MASSIFIED:
      return {
        ...state,
        table: {
          loading: true,
          items: [ ...state.table.items ],
          error: false,
        },
      };

    case crud_form_massified_types.SUCCESS_GET_MASSIFIED:
      return {
        ...state,
        table: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case crud_form_massified_types.FAIL_GET_MASSIFIED:
      return {
        ...state,
        table: { 
          loading: false,
          items: [],
          error: true,
        } 
      };

    case crud_form_massified_types.LOADING_GET_MASSIFIED_BY_ID:
      return {
        ...state,
        edit: {
          loading: true,
          items: {...state.edit.items},
          error: false
        }
      };

    case crud_form_massified_types.SUCCESS_GET_MASSIFIED_BY_ID:
      return {
        ...state,
        edit: {
          loading: false,
          items: action.payload,
          error: false
        }
      };

    case crud_form_massified_types.FAIL_GET_MASSIFIED_BY_ID:
      return {
        ...state,
        edit: {
          loading: false,
          items: action.payload,
          error: true
        }
      };

    case crud_form_massified_types.POST_MASSIFIED_IMPORT_URL:
      return {
        ...state,
        upload: {
          loading: true,
          items:{ ...state.upload.items} ,
          error: false,
        },
      };

    case crud_form_massified_types.SUCCESS_POST_MASSIFIED_IMPORT_URL:
      return {
        ...state,
        upload: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case crud_form_massified_types.FAIL_POST_MASSIFIED_IMPORT_URL:
      return {
        ...state,
        upload: { 
          loading: true,
          items: {},
          error: true,
        } 
      };
    
    case "LOGOUT_USER":
      return INITIAL_STATE;
    
    default:
      return state;
  }
};

export default crud_form_massified_reducer;
