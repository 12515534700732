import crud_form_customer_types from "./crud-form-customer.types";
import moment from "moment";
import { clearRootFees } from "../settings/settings.actions";
import fluxopayAPI, { Api_v2 } from "core/services/api";

/* eslint-disable */
export const postRegisterCustomer = (params) => async (dispatch) => {
  try {
    dispatch({
      type: crud_form_customer_types.LOADING_GET_AUTH_CUSTOMER,
    });
    delete params["confPassword"];
    params["whatsapp"] = params["whatsapp"]
      .replace(/[\(]/gi, "")
      .replace(/[\)]/gi, "")
      .replace(/[\ ]/gi, "")
      .replace(/[\-]/gi, "");
    const { data } = await Api_v2.post(`root-user`, params);
    const token = data.token;
    const end_date = moment().add(6, "days").format("YYYY-MM-DDTHH:mm:ss");
    localStorage.setItem("fluxopay_user_token", JSON.stringify(token));
    localStorage.setItem("fluxopay_user_end_date", JSON.stringify(end_date));
    localStorage.setItem("fluxopay_sub_user_token", JSON.stringify(""));
    await fluxopayAPI.post("cache", { clear_cache: true });

    dispatch({
      type: crud_form_customer_types.SUCCESS_GET_AUTH_CUSTOMER,
      payload: data
    });

    return true;
  } catch (err) {
    dispatch({
      type: crud_form_customer_types.FAIL_GET_AUTH_CUSTOMER,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });

    let fail_response = err?.response?.data?.error || "Erro inesperado!";

    return fail_response;
  }
};

export const patchCustomerStatus = (params, email) => async (dispatch) => {
  try {
    dispatch({
      type: crud_form_customer_types.PATCH_CUSTOMER,
    });

    await Api_v2.patch(`root-user/${email}`, params);

    dispatch({
      type: crud_form_customer_types.SUCCESS_PATCH_CUSTOMER,
    });

    return true;
  } catch (err) {
    dispatch({
      type: crud_form_customer_types.FAIL_PATCH_CUSTOMER,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });

    return err?.response?.data?.error || "Erro inesperado!";
  }
};

export const patchCustomer = (params, email) => async (dispatch) => {
  try {
    dispatch({
      type: crud_form_customer_types.PATCH_CUSTOMER,
    });
    delete params.confirm_password;
    let type_pj = {};
    let others = {};

    if (params?.company_name) type_pj["company_name"] = params?.company_name;

    if (params.company_name)
      type_pj["commercial_name"] = params?.commercial_name;

    if (params.company_name)
      type_pj["cnpj"] = params?.cnpj
        ?.replace(/[\.]/gi, "")
        .replace(/[\/]/gi, "")
        .replace(/[\-]/gi, "");

    if (params.website_url) others["website_url"] = params.website_url;

    const payload = {
      ...type_pj,
      ...others,
      gender: params.gender,
      date_of_birth: moment(params.date_of_birth).unix(),
      social_contract_url: params.social_contract_url,
      identity_url: params.identity_url,
      proof_of_bank_domicile_url: params.extract_bank_url,
      account_type: params.user_type,
      owner_name: params.owner_name,
      mcc: params.mcc,
      owner_cpf: params.owner_cpf.replace(/[\.]/gi, "").replace(/[\-]/gi, ""),
      password: params.password,
      whatsapp: params.whatsapp
        .replace(/[\(]/gi, "")
        .replace(/[\)]/gi, "")
        .replace(/[\ ]/gi, "")
        .replace(/[\-]/gi, ""),
      address: {
        number: params.number,
        city: params.city,
        street: params.street,
        district: params.district,
        state: params.state,
        cep: params.cep,
        reference: params.reference,
      },
      bank_info: {
        bank_ag: `${params.bank_ag}${
          params.bank_ag_digit ? "-" + params.bank_ag_digit : ""
        }`,
        bank: params.bank,
        account_type: params.account_type,
        bank_cc: `${params.bank_cc}-${params.bank_cc_digit}`,
      },
    };

    if (params.complement) payload.address["complement"] = params.complement;

    if (params.social_contract_url === undefined)
      delete payload["social_contract_url"];

    if (params.extract_bank_url === undefined)
      delete payload["proof_of_bank_domicile_url"];

    if (params.identity_url === undefined) delete payload["identity_url"];

    await Api_v2.patch(`root-user/${email}`, payload);

    dispatch({
      type: crud_form_customer_types.SUCCESS_PATCH_CUSTOMER,
    });

    return true;
  } catch (err) {
    dispatch({
      type: crud_form_customer_types.FAIL_PATCH_CUSTOMER,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
    return err?.response?.data?.error || "Erro inesperado!";
  }
};

export const authCustomer = (params) => async (dispatch) => {
  dispatch({ type: crud_form_customer_types.LOADING_GET_AUTH_CUSTOMER });

  try {
    const { data } = await fluxopayAPI.post("login", params, {
      headers: {
        "content-type": "application/json",
      },
    });
    const token = data.token;
    const end_date = moment().add(6, "days").format("YYYY-MM-DDTHH:mm:ss");
    localStorage.setItem("fluxopay_user_token", JSON.stringify(token));
    localStorage.setItem("fluxopay_user_end_date", JSON.stringify(end_date));
    localStorage.setItem("fluxopay_sub_user_token", JSON.stringify(""));
    await fluxopayAPI.post("cache", { clear_cache: true });

    await dispatch({
      type: crud_form_customer_types.SUCCESS_GET_AUTH_CUSTOMER,
      payload: data,
    });

    return true;
  } catch (err) {
    await dispatch({
      type: crud_form_customer_types.FAIL_GET_AUTH_CUSTOMER,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });

    let fail_response = err?.response?.data?.error || "Erro inesperado!";

    return fail_response;
  }
};

export const authCustomerCode = (params) => async (dispatch) => {
  dispatch({ type: crud_form_customer_types.LOADING_GET_AUTH_CUSTOMER });

  try {
    const { data } = await Api_v2.post("login", params, {
      headers: {
        "content-type": "application/json",
      },
    });

    const token = data.token;
    const end_date = moment().add(6, "days").format("YYYY-MM-DDTHH:mm:ss");
    localStorage.setItem("fluxopay_user_token", JSON.stringify(token));
    localStorage.setItem("fluxopay_user_end_date", JSON.stringify(end_date));
    localStorage.setItem("fluxopay_sub_user_token", JSON.stringify(""));
    await fluxopayAPI.post("cache", { clear_cache: true });

    await dispatch({
      type: crud_form_customer_types.SUCCESS_GET_AUTH_CUSTOMER,
      payload: data,
    });

    return true;
  } catch (err) {
    console.error(err);
    await dispatch({
      type: crud_form_customer_types.FAIL_GET_AUTH_CUSTOMER,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });

    let fail_response = err?.response?.data?.error || "Erro inesperado!";

    return fail_response;
  }
};

export const getClientCode = (params) => async (dispatch) => {
  dispatch({ type: crud_form_customer_types.GET_CLIENT });
  try {
    const { data } = await Api_v2.get(
      `login/customer?root_email=${params.email}&document=${params.document}`
    );
    dispatch({
      type: crud_form_customer_types.SUCCESS_GET_CLIENT,
      payload: data,
    });

    return true;
  } catch (err) {
    console.error(err);
    await dispatch({
      type: crud_form_customer_types.FAIL_GET_CLIENT,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
    return err?.response?.data?.error || "Erro inesperado!";
  }
};

export const authClientCode = (params) => async (dispatch) => {
  dispatch({ type: crud_form_customer_types.LOADING_GET_AUTH_CUSTOMER });

  try {
    const { data } = await Api_v2.post("login/customer", params);
    const token = data.token;
    const end_date = moment().add(6, "days").format("YYYY-MM-DDTHH:mm:ss");
    localStorage.setItem("fluxopay_user_token", JSON.stringify(token));
    localStorage.setItem("fluxopay_user_end_date", JSON.stringify(end_date));
    localStorage.setItem("fluxopay_sub_user_token", JSON.stringify(""));
    await fluxopayAPI.post("cache", { clear_cache: true });

    dispatch({
      type: crud_form_customer_types.SUCCESS_GET_AUTH_CUSTOMER,
      payload: data,
    });

    return true;
  } catch (err) {
    await dispatch({
      type: crud_form_customer_types.FAIL_GET_AUTH_CUSTOMER,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
    return err?.response?.data?.error || "Erro inesperado!";
  }
};

export const aceeptedPolices = () => (dispatch) => {
  dispatch({
    type: crud_form_customer_types.ACEEPTED_POLICES,
    payload: { accepted_policies: true },
  });
};

export const resetCustomer = (params) => async (dispatch) => {
  dispatch({ type: crud_form_customer_types.POST_RESET_CUSTOMER });

  try {
    const { data } = await fluxopayAPI.post("reset-password", params, {
      headers: {
        "content-type": "application/json",
      },
    });

    await dispatch({
      type: crud_form_customer_types.SUCCESS_POST_RESET_CUSTOMER,
      payload: data,
    });

    return true;
  } catch (err) {
    await dispatch({
      type: crud_form_customer_types.FAIL_POST_RESET_CUSTOMER,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
    return false;
  }
};

export const logoutUser = () => async (dispatch) => {
  return dispatch({ type: crud_form_customer_types.LOGOUT_USER });
};

export const resetCallbackCustomer = (params) => async (dispatch) => {
  dispatch({ type: crud_form_customer_types.POST_RESET_CALLBACK_CUSTOMER });

  try {
    const { data } = await fluxopayAPI.post("reset-password-callback", params, {
      headers: {
        "content-type": "application/json",
      },
    });

    await dispatch({
      type: crud_form_customer_types.SUCCESS_POST_RESET_CALLBACK_CUSTOMER,
      payload: data,
    });

    return true;
  } catch (err) {
    await dispatch({
      type: crud_form_customer_types.FAIL_POST_RESET_CALLBACK_CUSTOMER,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });

    let fail_response = err?.response?.data?.error || "Erro inesperado!";

    return fail_response;
  }
};

export const getCustomerById = (id) => (dispatch) => {
  dispatch({
    type: crud_form_customer_types.GET_BY_ID_CUSTOMER,
    payload: id,
  });
};

export const getCustomers = (params) => (dispatch) => {
  dispatch({
    type: crud_form_customer_types.GET_CUSTOMER,
    payload: params,
  });
};

export const putCustomer = (params) => async (dispatch) => {
  try {
    dispatch({
      type: crud_form_customer_types.PUT_CUSTOMER,
    });

    const { data } = await fluxopayAPI.put(`root-user/${params.id}`, params);

    dispatch({
      type: crud_form_customer_types.SUCCESS_PUT_CUSTOMER,
      payload: data,
    });

    dispatch({
      type: "GET_CUSTOMER",
    });
    return true;
  } catch (err) {
    dispatch({
      type: crud_form_customer_types.FAIL_PUT_CUSTOMER,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });

    let fail_response = err?.response?.data?.error || "Erro inesperado!";

    return fail_response;
  }
};

export const delCustomer = (params) => async (dispatch) => {
  try {
    dispatch({
      type: crud_form_customer_types.DEL_CUSTOMER,
    });

    const { data } = await fluxopayAPI.delete(`root-user/${params}`);

    dispatch({
      type: crud_form_customer_types.SUCCESS_DEL_CUSTOMER,
      payload: data,
    });

    return true;
  } catch (err) {
    dispatch({
      type: crud_form_customer_types.FAIL_DEL_CUSTOMER,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });

    let fail_response = err?.response?.data?.error || "Erro inesperado!";

    return fail_response;
  }
};

export const getUserCode = (params) => async (dispatch) => {
  dispatch({ type: crud_form_customer_types.GET_USER_CODE });

  try {
    const { data } = await Api_v2.get(`login/${params.email}`);

    dispatch({
      type: crud_form_customer_types.SUCCESS_GET_USER_CODE,
      payload: data,
    });
    return true;
  } catch (err) {
    dispatch({
      type: crud_form_customer_types.FAIL_GET_USER_CODE,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });

    return err?.response?.data?.error || "Erro inesperado!";
  }
};

export const authWithSubUSer = (params) => async (dispatch) => {
  try {
    dispatch({
      type: crud_form_customer_types.AUTH_SUB_USER,
    });
    clearRootFees();
    const { data } = await fluxopayAPI.post(`token`, params);
    data["email"] = params.email;

    dispatch({
      type: crud_form_customer_types.SUCCESS_AUTH_SUB_USER,
      payload: data,
    });

    const token = data.token;

    localStorage.setItem("fluxopay_sub_user_token", JSON.stringify(token)); 
   
    return true;
  } catch (err) {
    dispatch({
      type: crud_form_customer_types.FAIL_AUTH_SUB_USER,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });

    let fail_response = err?.response?.data?.error || "Erro inesperado!";

    return fail_response;
  }
};

export const removeSubUSer = (params) => async (dispatch) => {
  try {
    dispatch({
      type: crud_form_customer_types.REMOVE_SUB_USER,
    });

    localStorage.setItem("fluxopay_sub_user_token", JSON.stringify(""));

    return true;
  } catch (err) {
    dispatch({
      type: crud_form_customer_types.FAIL_DEL_CUSTOMER,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });

    let fail_response = err?.response?.data?.error || "Erro inesperado!";

    return fail_response;
  }
};
