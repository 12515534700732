import billing_table_types from "./billing.types";

const INITIAL_STATE = {
  table: { 
    loading: true,
    items: [],
    error: false,
  }, 
};

const billing_table_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case billing_table_types.LOADING_GET_BILLING:
      return {
        table: {
          loading: true,
          items: [],
          error: false,
        }
      };


    case billing_table_types.SUCCESS_GET_BILLING:
      return {
        table: {
          loading: false,
          items: action.payload,
          error: false,
        }, 
      };


    case billing_table_types.FAIL_GET_BILLING:
      return {
        table: { 
          loading: false,
          items: [],
          error: true,
        }, 
      }; 
    default:
      return state;
  }
};

export default billing_table_reducer;
