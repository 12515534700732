import React from 'react'

import { ButtonContainer } from './button.styles'


const ButtonComponent = React.forwardRef(({ 
    children, 
    ...props 
}, ref) => {
 
    return (
        <ButtonContainer {...props} isexpandablewidth={props.isexpandablewidth} >
            <span>{children}</span>
        </ButtonContainer>
    )
});

export default ButtonComponent