import cache_types from "./cache.types"; 
import fluxopayAPI from "core/services/api"; 

export const postCache = (params) => async (dispatch) => {
    try {
      dispatch({
        type: cache_types.POST_CACHE, 
      });
    
      const { data } = await fluxopayAPI.post(`cache`, params);
  
      dispatch({
        type: cache_types.SUCCESS_POST_CACHE,
        payload: data,
      });
      
      dispatch({ type: cache_types.LOGOUT_USER })
      return true;
    } catch (err) {
      dispatch({
        type: cache_types.FAIL_POST_CACHE,
        payload: err?.response?.data?.error || "Erro inesperado!",
      }); 
  
      return err?.response?.data?.error || "Erro inesperado!";
    }
};

export const getCache = (params) =>  async (dispatch) => {
    dispatch({ type: cache_types.GET_CACHE });
};