import React, { useState } from "react";
import { Input, Form } from "antd";

import Button from "core/components/button/button.component";
import Notification from "core/components/notification/notification.component";

import { ClearAccountStyles } from "./clear-account-form.styles";

const ClearAccountForm = ({
  postClearAccount = () => null,
  onCloseModal = () => null,
}) => {
  const [loading, setLoading] = useState(false);
  const validateMessages = {
    required: "campo obrigatório!",
  };

  const onFinish = async (values) => {
    setLoading(true);
    const payload = {
      code: parseInt(values.code),
    };
    const response = await postClearAccount(payload);
    if (response === true) {
      onCloseModal();
      Notification("success", "Tudo certo! limpamos sua conta pra você.");
    } else Notification("error", response);
    setLoading(false);
  };

  return (
    <ClearAccountStyles>
      <Form
        onFinish={onFinish}
        layout="vertical"
        validateMessages={validateMessages}
      >
        <p style={{ color: "red" }}>
          Enviamos um código em seu email para segurança de seus dados.
        </p>
        <Form.Item
          label="Digite o código"
          name="code"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Button
          loading={loading}
          disabled={loading}
          htmlType="submit"
          btntype="third"
          style={{ width: "100%", textAlign: "center" }}
        >
          Limpar dados
        </Button>
      </Form>
    </ClearAccountStyles>
  );
};

export default ClearAccountForm;
