const drawer_types = {
    SET_DRAWER_PROPS: "SET_DRAWER_PROPS",
    SUCCESS_SET_DRAWER_PROPS: "SUCCESS_SET_DRAWER_PROPS",
    FAIL_SET_DRAWER_PROPS: "FAIL_SET_DRAWER_PROPS",
    CLOSE_DRAWER: "CLOSE_DRAWER",
    FAIL_CLOSE_DRAWER: "FAIL_CLOSE_DRAWER",

};
  
export default drawer_types;
  