import { all, call } from "redux-saga/effects";

import { getReports } from "./form-reports/form-reports.sagas";
import { getTransactions } from "./crud-transactions/crud-transactions.sagas"; 

export default function* rootSaga() {
  yield all([
    call(getReports), 
    call(getTransactions), 
  ]);
}
