import { takeLatest, put } from 'redux-saga/effects'
 
import dashboard_types from "./dashboard.types";

import api from 'core/services/api'

export function* getDashboardAsync({ payload }){
 
  yield put({type: dashboard_types.LOADING_GET_DASHBOARD})

  try{
     
    const {data}= yield api.get('dashboard', payload, {
      
    })

    yield put({
      type: dashboard_types.SUCCESS_GET_DASHBOARD,
      payload: data
    })

   } catch(err){

    yield put({type: dashboard_types.FAIL_GET_DASHBOARD, payload: err?.response?.data?.error || "Erro inesperado!", })
  }
}

export function* dashboard(){
  yield takeLatest(dashboard_types.GET_DASHBOARD, getDashboardAsync)
}