import mcc_types from "./mcc.types";

const INITIAL_STATE = {
  loading: true,
  items: {},
  error: false, 
};

const mcc_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case mcc_types.GET_MCC:
      return {
        loading: true,
        items: [],
        error: false, 
      };

    case mcc_types.SUCCESS_GET_MCC:
      return {
        loading: false,
        items: action.payload,
        error: false, 
      };

    case mcc_types.FAIL_GET_MCC:
      return {
        loading: false,
        items: [],
        error: true, 
      }; 

    default:
      return state;
  }
};

export default mcc_reducer;
