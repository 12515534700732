const plan_panels_types = { 
    GET_PLAN: "GET_PLAN",
    LOADING_GET_PLAN: "LOADING_GET_PLAN",
    SUCCESS_GET_PLAN: "SUCCESS_GET_PLAN",
    FAIL_GET_PLAN: "FAIL_GET_PLAN", 
    UPDATE_PLAN: "UPDATE_PLAN",
    SET_PLAN: "SET_PLAN"
  };
  
  export default plan_panels_types;
  