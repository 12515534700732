import { combineReducers } from "redux";

//reducers
import billing from "./billing-table/billing.reducer"; 
import card from "./crud-card/crud-card.reducer"; 
import plan from "./plan-panel/plan-panel.reducer"; 

export const rootReducer = combineReducers({
  billing,
  card,
  plan
});

export default rootReducer;
