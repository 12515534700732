const types = {
    POST_PLANS: "POST_PLANS",
    SUCCESS_POST_PLANS: "SUCCESS_POST_PLANS",
    FAIL_POST_PLANS: "FAIL_POST_PLANS", 
    GET_PLANS: "GET_PLANS", 
    SUCCESS_GET_PLANS: "SUCCESS_GET_PLANS",
    FAIL_GET_PLANS: "FAIL_GET_PLANS",  
    DELETE_PLANS: "DELETE_PLANS",
    SUCCESS_DELETE_PLANS: "SUCCESS_DELETE_PLANS",
    FAIL_DELETE_PLANS: "FAIL_DELETE_PLANS", 
    PATCH_PLANS: "PATCH_PLANS",
    SUCCESS_PATCH_PLANS: "SUCCESS_PATCH_PLANS",
    FAIL_PATCH_PLANS: "FAIL_PATCH_PLANS", 
    GET_PLANS_BY_ID: "GET_PLANS_BY_ID", 
    SUCCESS_GET_PLANS_BY_ID: "SUCCESS_GET_PLANS_BY_ID",
    FAIL_GET_PLANS_BY_ID: "FAIL_GET_PLANS_BY_ID",  
  };
  
  export default types;
  