import React from "react";

const PolicyPrivacy = () =>{ 
    return (
      <div>
        <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
        <title />
        <meta name="generator" content="LibreOffice 7.0.4.2 (Linux)" />
        <meta name="author" content="Daniel Cruz" />
        <meta name="created" content="2021-02-03T17:22:00" />
        <meta name="changed" content="2021-02-04T16:44:39" />
        <meta name="AppVersion" content={15.0000} />
        <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\t\t@page { size: 21cm 29.7cm; margin-left: 3cm; margin-right: 3cm; margin-top: 2.5cm; margin-bottom: 2.5cm }\n\t\tp { margin-bottom: 0.25cm; direction: ltr; color: #000000; line-height: 115%; text-align: left; orphans: 2; widows: 2; background: transparent }\n\t\tp.western { font-family: \"Calibri\", serif; font-size: 11pt; so-language: pt-BR }\n\t\tp.cjk { font-family: \"Calibri\"; font-size: 11pt; so-language: en-US }\n\t\tp.ctl { font-family: ; font-size: 11pt; so-language: ar-SA }\n\t\ta:link { color: #0563c1; text-decoration: underline }\n\t" }} />
        <p className="western" align="center" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>
          <font size={4} style={{fontSize: '16pt'}}><b>Política de Privacidade</b></font></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Na&nbsp;<i>Fluxo
            Pay</i>, privacidade e segurança são prioridades e nos
          comprometemos com a transparência do tratamento de dados pessoais
          dos nossos usuários/clientes. Por isso, esta presente Política de
          Privacidade estabelece como é feita a coleta, uso e transferência
          de informações de clientes ou outras pessoas que acessam ou usam
          nosso site.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Ao
          utilizar nossos serviços, você entende que coletaremos e usaremos
          suas informações pessoais nas formas descritas nesta Política, sob
          as normas de Proteção de Dados (LGPD, Lei Federal 13.709/2018), das
          disposições consumeristas da Lei Federal 8078/1990 e as demais
          normas do ordenamento jurídico brasileiro aplicáveis.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Dessa
          forma, a&nbsp;<b>Fluxo Resultados ME</b>, doravante denominada
          simplesmente como “Fluxo Pay” pessoa jurídica, inscrita no
          Cadastro Nacional de Pessoas Jurídicas do Ministério da Fazenda
          (CNPJ/MF) sob o nº 37.630.198/0001-05, com sede Rua Me. Rita Amada
          de Jesus, 181 - Granja Julieta, 04721-050, Cidade e Estado de São
          Paulo, <b>no papel de Controladora de Dados</b>, obriga-se ao
          disposto na presente Política de Privacidade.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Esta
          Politica de Privacidade se aplicará ao site da empresa, plataforma e
          aplicativos de computadores e celulares.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><b>1.
            Quais dados coletamos sobre você e para qual finalidade?</b></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Nosso
          site coleta e utiliza alguns dados pessoais seus, de forma a
          viabilizar a prestação de serviços e aprimorar a experiência de
          uso. Não instalamos cookies nossos em seu aparelho e não utilizamos
          de geolocalização. Em hipótese alguma fazemos discriminação de
          dados quanto a raça, questões sexuais, físicas, religiosas,
          filosóficas, ou sindicais. A análise financeira somente é
          realizada quanto a evitar fraudes, não influenciando no tratamento
          ou no marketing. 
        </p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Objetivo:
          cadastro do usuário e verificação se a pessoa é real comparando
          documentos com dados imputados para garantir segurança minima da
          operação, sendo assim todos dados abaixo são obrigatórios para o
          usuário cadastrado.</p>
        <ul>
          <li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- Tipo de
              conta (jurídica ou física)</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- Razão
              social</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- Nome
              fantasia</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- cnpj</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>-
              proprietário</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- cpf</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- email</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- senha</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- whatsapp</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- rua</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- bairro</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>-
              complemento</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- número da
              casa</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- cidade</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- estado</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- Cep</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- agência
              bancaria</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- conta
              bancaria</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- banco</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- tipo de
              conta (corrente ou poupança)</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>-
              identificação pessoal (identidade ou cnh)</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- contrato
              social da empresa</p>
          </li></ul>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><u>Com
            estes dados é possível verificar o usuário e evitar fraudes,
            portanto após iserí-los em nossa plataforma receberão máxima
            proteção e somente poderão ser excluídos ou reiterados mediante
            solicitação pelo e-mail </u><font color="#0563c1"><u><a href="mailto:pay@fluxoresultados.com.br">pay@fluxoresultados.com.br</a></u></font><u>.
            Em caso de exclusão de algum destes dados a conta do usuário na
            plataforma também será excluída.</u></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><br />
          <br />
        </p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><b>Dados
            dos clientes do titular (apenas nome e email obrigatórios)</b></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Na
          nossa  plataforma é possivel que terceiros cadastrem seus dados para
          gerenciamento de cobranças, caso você deseje retirar estes dados ou
          corrigi-los basta fazê-lo pela própria plataforma <font color="#0563c1"><u><a href="https://app.fluxopay.com.br/auth/customers">CLICANDO
                AQUI</a></u></font>.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Objetivo:
          (Emitir cobranças registradas, como boleto, enviar emails de
          cobrança)</p>
        <ul>
          <li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- nome</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- email</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- celular</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- telefone
              fixo</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- cpf ou
              cnpj</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- cep</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- rua</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- número</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>-
              complemento</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- cidade</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- estado</p>
          </li></ul>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>&nbsp;</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><b>Dados
            coletados de quem o titular cadastra como sub usuário na plataforma
            (funcionários do titular)</b></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Na
          nossa plataforma é possivel que o Usuário cadastre sua equipe e
          funcionários para facilitar o serviço. Assim alguns dados são
          requisitados, caso você deseje modificar ou retirar estes dados
          basta fazê-lo pela própria plataforma <font color="#0563c1"><u><a href="https://app.fluxopay.com.br/auth/operators">CLICANDO
                AQUI</a></u></font>.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Objetivo
          (permitir o acesso na plataforma por terceiros designados pelo
          titular)</p>
        <ul>
          <li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- nome</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- email</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- senha</p>
          </li></ul>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><br />
          <br />
        </p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><b>Dados
            coletados no blog, por quem comenta na plataforma.</b> 
        </p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Caso
          o usuário comente em nossa plataforma alguns dados obrigatórios
          serão exigidos tanto para eventual responsabilização jurídica do
          que a pessoa escreveu quanto para fins de marketing da empresa. Assim
          caso o usuário deseje excluir algum dado deverá enviar um e-mail
          para <font color="#0563c1"><u><a href="mailto:pay@fluxoresultados.com.br">pay@fluxoresultados.com.br</a></u></font>
          e consequentemente seu comentário será excluído.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Objetivo:
          marketing via email e telefone para quem comenta no blog</p>
        <ul>
          <li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- nome</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- email</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- whatsapp</p>
          </li></ul>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><br />
          <br />
        </p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><b>DADOS
            DO TITULAR E DO CLIENTE DO TITULAR SÃO ENVIADOS PARA TERCEIROS PARA
            PROCESSAMENTO DA TRANSAÇÃO.</b></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>-
          São enviados para os gateways de pagamento (Safe2Pay)</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>-
          São enviados para o antifraude (ClearSale)</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><u>Como
            a Fluxo Pay é mera mediadora e facilitadora da conexão entre
            usuário e processadora financeira, os dados serão enviados para a
            processadora Safe2Pay e para empresa de segurança ClearSale.</u></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><br />
          <br />
        </p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><b>Dados
            pessoais coletados automaticamente </b>
        </p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Nossa
          plataforma não utiliza cookies e nem coleta seus dados diretamente,
          porém para finalidade de marketing utilizamos dos rastreadores da
          Google e do Facebook que seguem politicas de privacidade próprias
          além do nosso controle. Caso deseje conferir clique
          https://policies.google.com/privacy?hl=pt-BR e
          https://www.facebook.com/policy.php.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Destaque:</p>
        <ul>
          <li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- Temos os
              rastreadores do Facebook (Pixel) e do Google&nbsp; (Analytics).
              Usamos para campanhas de marketing e remarketing.</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- Não temos
              cookies próprios</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- Não
              coletamos dados por nós mesmos, apenas por essas duas plataformas
              terceiras</p>
          </li></ul>
        <p className="western" style={{textIndent: '0.19cm', marginBottom: '0.28cm', lineHeight: '108%'}}>
          <br />
          <br />
        </p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><b>2.
            Como coletamos os seus dados?</b></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Nesse
          sentido, a coleta dos seus dados pessoais ocorre da seguinte forma: 
        </p>
        <ul>
          <li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- Todos os
              dados são coletados através de formulários nas páginas do
              aplicativo que o usuário preenche.</p>
          </li><li><p style={{marginBottom: '0.28cm', lineHeight: '108%'}}>- Dados do
              Facebook e Google são coletados através de código que eles mesmos
              fornecem para colocar na página</p>
          </li></ul>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><br />
          <br />
        </p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><br />
          <br />
        </p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><b>2.1.
            Consentimento</b></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>É
          a partir do seu consentimento que tratamos os seus dados pessoais. O
          consentimento é a manifestação livre, informada e inequívoca pela
          qual você autoriza a&nbsp;<i>Fluxo Pay a</i> tratar seus dados.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Assim,
          em consonância com a Lei Geral de Proteção de Dados, seus dados só
          serão coletados, tratados e armazenados mediante prévio e expresso
          consentimento.&nbsp;</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>O
          seu consentimento será obtido de forma específica para cada
          finalidade acima descrita, evidenciando o compromisso de
          transparência e boa-fé da&nbsp;<i>Fluxo</i>&nbsp;Pay para com seus
          usuários/clientes, seguindo as regulações legislativas
          pertinentes.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Ao
          utilizar os serviços da&nbsp;<i>Fluxo Pay</i>&nbsp;e fornecer seus
          dados pessoais, você está ciente e consentindo com as disposições
          desta Política de Privacidade, além de conhecer seus direitos e
          como exercê-los.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>A
          qualquer tempo e sem nenhum custo, você poderá revogar seu
          consentimento.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>É
          importante destacar que a revogação do consentimento para o
          tratamento dos dados pode implicar a impossibilidade da performance
          adequada de alguma funcionalidade do site que dependa da operação.
          Tais consequências serão informadas previamente.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Para
          acessar a funcionalidade de “saque imediato” o usuário deverá
          consentir com o preenchimento de formulário especial que será
          analisado evitando fraudes e clonagem. Portanto, tais dados serão
          submetidos a terceiros que verificarão sua validade.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>A
              Fluxo Resultados ME poderá utilizar estes dados para envio de
              mensagens sms, e-mail e ligação em caso de inadimplência,
              atualização da plataforma ou funcionalidades de lembretes.</font></font></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}><u><b>Usuários
                  podem preencher dados de terceiros (clientes ou funcionários do
                  usuário) para gerar cobranças, boletos ou acesso a plataforma. Em
                  todos estes casos especiais o titular do dado pode pedir a exclusão
                  ou retirada ou modificação deste na plataforma.</b></u></font></font></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><br />
          <br />
        </p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><b>3.
            Quais são os seus direitos?</b></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>A&nbsp;<i>Fluxo
            Pay </i>assegura a seus usuários/clientes seus direitos de titular
          previstos no artigo 18 da Lei Geral de Proteção de Dados. Dessa
          forma, você pode, de maneira gratuita e a qualquer tempo:</p>
        <ul>
          <li><p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>
              <b>Confirmar a existência de tratamento de dados</b>, de maneira
              simplificada ou em formato claro e completo.</p>
          </li><li><p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>
              <b>Acessar seus dados</b>, podendo solicitá-los em uma cópia
              legível sob forma impressa ou por meio eletrônico, seguro e
              idôneo.</p>
          </li><li><p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>
              <b>Corrigir seus dados</b>, ao solicitar a edição, correção ou
              atualização destes.</p>
          </li><li><p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>
              <b>Limitar seus dados</b>&nbsp;quando desnecessários, excessivos ou
              tratados em desconformidade com a legislação através da
              anonimização, bloqueio ou eliminação.</p>
          </li><li><p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>
              <b>Solicitar a portabilidade de seus dados</b>, através de um
              relatório de dados cadastrais que a&nbsp;<i>Fluxo Pay </i>trata a
              seu respeito.</p>
          </li><li><p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>
              <b>Eliminar seus dados</b>&nbsp;tratados a partir de seu
              consentimento, exceto nos casos previstos em lei.</p>
          </li><li><p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>
              <b>Revogar seu consentimento</b>, desautorizando o tratamento de
              seus dados.</p>
          </li><li><p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>
              <b>Informar-se sobre a possibilidade de não fornecer seu
                consentimento</b>&nbsp;e sobre as consequências da negativa.</p>
          </li></ul>
        <p className="western" style={{marginLeft: '1.27cm', marginBottom: '0.28cm', lineHeight: '108%'}}>
          <br />
          <br />
        </p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><b>4.
            Como você pode exercer seus direitos de titular?</b></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Para
          exercer seus direitos de titular, você deve entrar em contato com
          a&nbsp;<i>Fluxo Pay</i>&nbsp;através dos seguintes meios
          disponíveis:</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><b>-
            pay@fluxoresultados.com.br</b></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><b>-
            (11) 9 7603-6522</b></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>De
          forma a garantir a sua correta identificação como titular dos dados
          pessoais objeto da solicitação, é possível que solicitemos
          documentos ou demais comprovações que possam comprovar sua
          identidade. Nessa hipótese, você será informado previamente.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><br />
          <br />
        </p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><b>5.
            Como e por quanto tempo seus dados serão armazenados?</b></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Seus
          dados pessoais coletados pela&nbsp;<i>Fluxo Pay</i>&nbsp;serão
          utilizados e armazenados durante o tempo necessário para a prestação
          do serviço ou para que as finalidades elencadas na presente Política
          de Privacidade sejam atingidas, considerando os direitos dos
          titulares dos dados e dos controladores.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>De
          modo geral, seus dados serão mantidos enquanto a relação
          contratual entre você e a&nbsp;<i>Fluxo Pay</i>&nbsp;perdurar.
          Findado o período de armazenamento dos dados pessoais, estes serão
          excluídos de nossas bases de dados ou anonimizados, ressalvadas as
          hipóteses legalmente previstas no artigo 16 lei geral de proteção
          de dados, a saber:</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>I
          – cumprimento de obrigação legal ou regulatória pelo
          controlador;</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>II
          – estudo por órgão de pesquisa, garantida, sempre que possível,
          a anonimização dos dados pessoais;</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>III
          – transferência a terceiro, desde que respeitados os requisitos de
          tratamento de dados dispostos nesta Lei; ou</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>IV
          – uso exclusivo do controlador, vedado seu acesso por terceiro, e
          desde que anonimizados os dados.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Isto
          é, informações pessoais sobre você que sejam imprescindíveis
          para o cumprimento de determinações legais, judiciais e
          administrativas e/ou para o exercício do direito de defesa em
          processos judiciais e administrativos serão mantidas, a despeito da
          exclusão dos demais dados.&nbsp;</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>O
          armazenamento de dados coletados pela&nbsp;<i>Fluxo Pay</i>&nbsp;reflete
          o nosso compromisso com a segurança e privacidade dos seus dados.
          Empregamos medidas e soluções técnicas de proteção aptas a
          garantir a confidencialidade, integridade e inviolabilidade dos seus
          dados. Além disso, também contamos com medidas de segurança
          apropriadas aos riscos e com controle de acesso às informações
          armazenadas.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><b>6.
            O que fazemos para manter seus dados seguros?</b></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Para
          mantermos suas informações pessoais seguras, usamos ferramentas
          físicas, eletrônicas e gerenciais orientadas para a proteção da
          sua privacidade.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Aplicamos
          essas ferramentas levando em consideração a natureza dos dados
          pessoais coletados, o contexto e a finalidade do tratamento e os
          riscos que eventuais violações gerariam para os direitos e
          liberdades do titular dos dados coletados e tratados.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Entre
          as medidas que adotamos, destacamos as seguintes:</p>
        <ul>
          <li><p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>
              Apenas pessoas autorizadas têm acesso a seus dados pessoais</p>
          </li><li><p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>
              O acesso a seus dados pessoais é feito somente após o compromisso
              de confidencialidade</p>
          </li><li><p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>
              Seus dados pessoais são armazenados em ambiente seguro e idôneo em
              uma conta da Plataforma Amazon Nothern Virgínia, Estados Unidos da
              América.</p>
          </li><li><p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>
              Dados sensíveis como senhas são criptografados, e possuem <i>hash
                + salt</i> que torna impossível a Fluxo Pay saber, conhecer ou
              utilizar sua senha</p>
          </li><li><p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>
              Toda a comunicação entre quem acessa o dado e onde ele está
              armazenado é criptografada (TLS)</p>
          </li></ul>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>A&nbsp;<i>Fluxo
            Pay </i>&nbsp;se compromete a adotar as melhores posturas para evitar
          incidentes de segurança. Contudo, é necessário destacar que
          nenhuma página virtual é inteiramente segura e livre de riscos. É
          possível que, apesar de todos os nossos protocolos de segurança,
          problemas de culpa exclusivamente de terceiros ocorram, como ataques
          cibernéticos de&nbsp;<i>hackers</i>, ou também em decorrência da
          negligência ou imprudência do próprio usuário/cliente.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Em
          caso de incidentes de segurança que possa gerar risco ou dano
          relevante para você ou qualquer um de nossos usuários/clientes,
          comunicaremos aos afetados e a Autoridade Nacional de Proteção de
          Dados sobre o ocorrido, em consonância com as disposições da Lei
          Geral de Proteção de Dados.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><b>7.
            Com quem seus dados podem ser compartilhados?</b></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Tendo
          em vista a preservação de sua privacidade, a&nbsp;<i>Fluxo Pay</i>&nbsp;não
          compartilhará seus dados pessoais com nenhum terceiro não
          autorizado.&nbsp;</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><i>Seus
            dados poderão ser compartilhados com nossos parceiros comerciais
          </i><b>SAFETOPAY INTERMEDIAÇÃO DE NEGÓCIOS LTDA, com sede na
            cidade de Porto Alegre, estado do Rio Grande do Sul, na Rua Barao de
            Santo Angelo, 479, CEP 90.570-090, pessoa jurídica de direito
            privado, inscrita no CNPJ/MF sob o nº 31.037.942/0001-78, denominada
            (“Safe2Pay”) que é a processadora de pagamento eletrônico</b><i><b>.</b></i><font color="#000000"><font size={4} style={{fontSize: '13pt'}}><b>
              </b></font></font><font color="#000000"><i><b>Ademais,</b></i></font><font color="#000000"><font size={4} style={{fontSize: '13pt'}}><b>
              </b></font></font><i><b>seus dados pessoais serão validados e
              arquivados no Banco de Dados da CLEAR SALE e poderão, a exclusivo
              critério da CLEAR SALE, ser tratados: (i) para mitigar as fraudes
              eletrônicas considerando a análise do perfil do Cliente Final,
              histórico de transações realizadas e informações existentes nos
              bancos de dados da CLEARSALE e aplicando o modelo estatístico de
              indicador de propensão à fraude pelas mesas de análise de risco, a
              fim de conferir o status sobre a suspeita de fraude eletrônica e
              evitar que sejam realizadas transações por terceiros em nome do
              Cliente Final e/ou utilizando informações falsas; (ii) para criar
              um cadastro de pontuação baseada no histórico de transações
              realizadas pelos Clientes Finais; e (iii) para fins estatísticos sem
              a individualização dos referidos dados. Bem como com </b></i><font face="Calibri Light, serif"><i><b>a
              </b></i></font><font face="Calibri Light, serif"><font size={2} style={{fontSize: '10pt'}}><span style={{letterSpacing: '0.2pt'}}><b>Google
                  LLC organizada de acordo com as leis do estado de Delaware, EUA, e
                  operada de acordo com as leis dos EUA, 1600 Amphitheatre Parkway,
                  Mountain View, Califórnia 94043 EUA </b></span></font></font><font face="Calibri Light, serif"><i><b>que
                realiza o marketing </b></i></font><font face="Calibri Light, serif"><b>e
              com o Facebook 1 Hacker Way, Menlo Park, California 94025. Por fim os
              dados são salvos na Plataforma Amazon.com, Iinc. Nothern Virginia,
              EUA.</b></font></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><i>Estes
            recebem seus dados apenas na medida do necessário para a prestação
            dos serviços contratados e nossos contratos são orientados pelas
            normas de proteção de dados do ordenamento jurídico brasileiro.</i></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><i>Todavia,
            nossos parceiros têm suas próprias Políticas de Privacidade, que
            podem divergir desta. Recomendamos a leitura desses documentos, que
            você pode acessar aqui:</i></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><i>Política
            de Privacidade do nosso parceiro:&nbsp;</i></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>1-<font color="#0563c1"><u><a href="https://safe2pay.com.br/">https://safe2pay.com.br</a></u></font></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>2-<font color="#0563c1"><u><a href="https://br.clear.sale/sobrenos/LGPD">Lei
                Geral de Proteção de Dados Pessoais | LGPD (clear.sale)</a></u></font></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>3-
          <font color="#0563c1"><u><a href="https://policies.google.com/privacy?hl=pt-BR">Política
                de Privacidade – Privacidade &amp; Termos – Google</a></u></font></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>4-
          <font color="#0563c1"><u><a href="https://www.facebook.com/business/help/742478679120153?id=1205376682832142">Sobre
                o pixel do Facebook | Central de Ajuda do Facebook para Empresas</a></u></font></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Além
          disso, também existem outras hipóteses em que seus dados poderão
          ser compartilhados, que são:</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>I
          – Determinação legal, requerimento, requisição ou ordem
          judicial, com autoridades judiciais, administrativas ou
          governamentais competentes.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>II
          – Caso de movimentações societárias, como fusão, aquisição e
          incorporação, de forma automática</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>III
          – Proteção dos direitos da&nbsp;<i>Fluxo Pay </i>em qualquer tipo
          de conflito, inclusive os de teor judicial.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><b>7.1.
            Transferência internacional de dados</b></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Alguns
          dos terceiros com quem compartilhamos seus dados podem ser
          localizados ou possuir instalações localizadas em países
          estrangeiros. Nessas condições, de toda forma, seus dados pessoais
          estarão sujeitos à Lei Geral de Proteção de Dados e às demais
          legislações brasileiras de proteção de dados</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Nesse
          sentido, a&nbsp;<i>Fluxo Pay</i>&nbsp;se compromete a sempre adotar
          eficientes padrões de segurança cibernética e de proteção de
          dados, nos melhores esforços de garantir e cumprir as exigências
          legislativas.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Ao
          concordar com essa Política de Privacidade, você concorda com esse
          compartilhamento, que se dará conforme as finalidades descritas no
          presente instrumento.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><b>8.
            Cookies ou dados de navegação</b></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>A&nbsp;<i>Fluxo
            Pay </i><b>não</b>&nbsp;faz uso de Cookies contudo alguns de seus
          parceiros podem o fazer. Para tanto, explicamos que são arquivos de
          texto enviados pela plataforma ao seu computador e que nele se
          armazenam, que contém informações relacionadas à navegação do
          site. Em suma, os Cookies são utilizados para aprimorar a
          experiência de uso.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Ao
          acessar nosso site e consentir com o uso de Cookies, você manifesta
          conhecer e aceitar a utilização de um sistema de coleta de dados de
          navegação com o uso de Cookies em seu dispositivo.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Você
          pode, a qualquer tempo e sem nenhum custo, alterar as permissões,
          bloquear ou recusar os Cookies. Todavia, a revogação do
          consentimento de determinados Cookies pode inviabilizar o
          funcionamento correto de alguns recursos da plataforma.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Para
          gerenciar os cookies do seu navegador, basta fazê-lo diretamente nas
          configurações do navegador, na área de gestão de Cookies. Você
          pode acessar tutoriais sobre o tema diretamente nos links abaixo:</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Se
          você usa o&nbsp;<font color="#0563c1"><u><a rel="noopener noreferrer" href="https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">Internet
                Explorer</a></u></font>.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Se
          você usa o&nbsp;<font color="#0563c1"><u><a rel="noopener noreferrer" href="https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s" target="_blank">Firefox</a></u></font>.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Se
          você usa o&nbsp;<font color="#0563c1"><u><a rel="noopener noreferrer" href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac" target="_blank">Safari</a></u></font>.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Se
          você usa o&nbsp;<font color="#0563c1"><u><a rel="noopener noreferrer" href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&oco=1&hl=pt-BR" target="_blank">Google
                Chrome</a></u></font>.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Se
          você usa o&nbsp;<font color="#0563c1"><u><a rel="noopener noreferrer" href="https://support.microsoft.com/pt-br/help/4027947/microsoft-edge-delete-cookies" target="_blank">Microsoft
                Edge</a></u></font>.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Se
          você usa o&nbsp;<font color="#0563c1"><u><a rel="noopener noreferrer" href="_blank">Opera</a></u></font>.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Conferindo
          os sites dos nossos parceiros você saberá quais Cookies podem estar
          sendo usados.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><br />
          <br />
        </p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><b>9.
            Alteração desta Política de Privacidade</b></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>A
          atual versão da Política de Privacidade foi formulada e atualizada
          pela última vez em:&nbsp;<i>01 de fevereiro de 2021</i>.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Reservamos
          o direito de modificar essa Política de Privacidade a qualquer
          tempo, principalmente em função da adequação a eventuais
          alterações feitas em nosso site ou em âmbito legislativo.
          Recomendamos que você a revise com frequência.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Eventuais
          alterações entrarão em vigor a partir de sua publicação em nosso
          site e sempre lhe notificaremos acerca das mudanças ocorridas.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Ao
          utilizar nossos serviços e fornecer seus dados pessoais após tais
          modificações, você as consente.&nbsp;</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><b>10.
            Responsabilidade</b></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>A&nbsp;<i>Fluxo
            Pay&nbsp;</i>prevê a responsabilidade dos agentes que atuam nos
          processos de tratamento de dados, em conformidade com os artigos 42
          ao 45 da Lei Geral de Proteção de Dados.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Nos
          comprometemos em manter esta Política de Privacidade atualizada,
          observando suas disposições e zelando por seu cumprimento.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Além
          disso, também assumimos o compromisso de buscar condições técnicas
          e organizativas seguramente aptas a proteger todo o processo de
          tratamento de dados.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Caso
          a Autoridade Nacional de Proteção de Dados exija a adoção de
          providências em relação ao tratamento de dados realizado
          pela&nbsp;Fluxo Pay<i>,&nbsp;</i>comprometemo-nos a segui-las.&nbsp;</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><b>10.1
            Isenção de responsabilidade</b></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Conforme
          mencionado no Tópico 6, embora adotemos elevados padrões de
          segurança a fim de evitar incidentes, não há nenhuma página
          virtual inteiramente livre de riscos. Nesse sentido, a&nbsp;<i>Fluxo
            Pay</i>&nbsp;não se responsabiliza por:</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>I
          – Quaisquer consequências decorrentes da negligência, imprudência
          ou imperícia dos usuários em relação a seus dados individuais.
          Garantimos e nos responsabilizamos apenas pela segurança dos
          processos de tratamento de dados e do cumprimento das finalidades
          descritas no presente instrumento.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Destacamos
          que a responsabilidade em relação à confidencialidade dos dados de
          acesso é do usuário.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>II
          – Ações maliciosas de terceiros, como fraudes ou ataques
          de&nbsp;<i>hackers</i>, exceto se comprovada conduta culposa ou
          deliberada da&nbsp;<i>Fluxo Pay.</i></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Destacamos
          que em caso de incidentes de segurança que possam gerar risco ou
          dano relevante para você ou qualquer um de nossos usuários/clientes,
          comunicaremos aos afetados e a Autoridade Nacional de Proteção de
          Dados sobre o ocorrido e cumpriremos as providências necessárias.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>III
          – Inveracidade das informações inseridas pelo usuário/cliente
          nos registros necessários para a utilização dos serviços da&nbsp;<i>Fluxo
            Pay</i>; quaisquer consequências decorrentes de informações falsas
          ou inseridas de má-fé são de inteiramente responsabilidade do
          usuário/cliente.</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><b>11.
            Encarregado de Proteção de Dados</b></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>A&nbsp;<i>Fluxo
            Pay</i>&nbsp;disponibiliza os seguintes meios para que você possa
          entrar em contato conosco para exercer seus direitos de titular:&nbsp;</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><b>-
            pay@fluxoresultados.com.br</b></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><b>-
            (11) 9 7603-6522</b></p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><br />
          <br />
        </p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}>Caso
          tenha dúvidas sobre esta Política de Privacidade ou sobre os dados
          pessoais que tratamos, você pode entrar em contato com o nosso
          Encarregado de Proteção de Dados Pessoais, através dos seguintes
          canais:</p>
        <p className="western" style={{marginBottom: '0.28cm', lineHeight: '108%'}}><i><b>BRENNO
              FLÁVIO DE ALMEIDA</b></i><i>, CPF 10994328630. </i>
        </p>
        <p className="western" align="left" style={{marginBottom: '0.28cm', lineHeight: '108%', orphans: 2, widows: 2}}>
          brenno.flavio@fluxoresultados.com.br</p>
      </div>
    );
  };

  export default PolicyPrivacy;