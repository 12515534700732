import React from "react";
import moment from "moment";
const locale = "pt-br";

export const FormatPhoneNumber = (str) => {
  let cleaned = ("" + str).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

export const FormatCellNumber = (str) => {
  let cleaned = ("" + str).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};
 
export const FormatCnpjNumber = (str) => {  
  if (str === null || str === undefined) {
    return "--";
  }
  let match = str.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/);

  if (match) {
    return `${match[1]}.${match[2]}.${match[3]}/${match[4]}-${match[5]}`;
  }
  return null;
};

export const FormatCpfNumber = (str) => {  
  if (str === null || str === undefined) {
    return "--";
  }
  let match = str.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/);

  if (match) {
    return `${match[1]}.${match[2]}.${match[3]}-${match[4]}`;
  }
  return null;
};

export const MonthDiff = (dateFrom, dateTo) => {
  return (
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear())
  );
};

export const DateToParcel = (dt1, dt2) => {
  const transaction_date = new Date(dt1);
  const due_date = new Date(dt2);
  return MonthDiff(transaction_date, due_date);
};

export const UnixToDate = (date) => {
  return moment.unix(date).format("DD/MM/YYYY");
};

export const floatToCurrency = (value) => {
  var intl = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "BRL",
  }); 
  return intl.format(isNaN(value)? 0 : value);
};

export const floatToCurrencyFormated = (value) => {
  var intl = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "BRL",
  });
  return <div>{intl.format(isNaN(value)? 0 : value)}</div>;
};

export const floatToPercentage = (value) => {
  var intl = new Intl.NumberFormat(locale, {
    style: "percent",
    maximumFractionDigits: 2
  });
  return intl.format(isNaN(value)? 0 : value);
};

export const convertToSlug = (text) => {
      const a = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
      const b = 'aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
      const p = new RegExp(a.split('').join('|'), 'g')
      return text.toString().toLowerCase().trim()
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special chars
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[\s\W-]+/g, '-') // Replace spaces, non-word characters and dashes with a single dash (-)
}