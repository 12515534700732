import settings_types from "./settings.types";

const INITIAL_STATE = {
  fee: { 
    loading: true,
    items: {},
    error: false,
  },
  image: { 
    loading: true,
    items: {},
    error: false,
  }, 
  identity:{
    loading: true,
    items: {},
    error: false,
  },
  social_contract:{
    loading: true,
    items: {},
    error: false,
  },
  bank_statement:{
    loading: true,
    items: {},
    error: false,
  }
};

const settings_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case settings_types.GET_ROOT_FEE:
      return {
        ...state,
        fee: {
          loading: true,
          items: {},
          error: false,
        }
      };
    case settings_types.LOADING_GET_ROOT_FEE:
      return {
        ...state,
        fee: {
          loading: true,
          items: {},
          error: false,
        }
      };

    case settings_types.SUCCESS_GET_ROOT_FEE:
      return {
        ...state,
        fee: {
          loading: false,
          items: action.payload,
          error: false,
        }
      };

    case settings_types.FAIL_GET_ROOT_FEE:
      return {
        ...state,
        fee: {
          loading: false,
          items: {},
          error: true,
        }
      };  

    case settings_types.POST_SETTINGS_IMPORT_URL:
      return {
        ...state,
        image: {
          loading: true,
          items: {...state.fee.items},
          error: false,
        }
      };

    case settings_types.SUCCESS_POST_SETTINGS_IMPORT_URL:
      return {
        ...state,
        image: {
          loading: false,
          items: action.payload,
          error: false,
        }
      };

    case settings_types.FAIL_POST_SETTINGS_IMPORT_URL:
      return {
        ...state,
        image: {
          loading: false,
          items: {},
          error: true,
        }
      }; 

    case settings_types.LOADING_GET_DOCUMENT_IMPORT_URL:
      return {
        ...state,
        identity: {
          loading: true,
          items: {},
          error: false,
        }
      };

    case settings_types.SUCCESS_GET_DOCUMENT_IMPORT_URL:
      return {
        ...state,
        identity: {
          loading: false,
          items: action.payload,
          error: false,
        }
      };

    case settings_types.FAIL_GET_DOCUMENT_IMPORT_URL:
      return {
        ...state,
        identity: {
          loading: false,
          items: {},
          error: true,
        }
      };   
 
    case settings_types.LOADING_GET_SOCIAL_CONTRACT:
      return {
        ...state,
        social_contract: {
          loading: true,
          items: {},
          error: false,
        }
      };

    case settings_types.SUCCESS_GET_SOCIAL_CONTRACT:
      return {
        ...state,
        social_contract: {
          loading: false,
          items: action.payload,
          error: false,
        }
      };

    case settings_types.FAIL_GET_SOCIAL_CONTRACT:
      return {
        ...state,
        social_contract: {
          loading: false,
          items: {},
          error: true,
        }
      };     

      
    case settings_types.LOADING_GET_BANK_STATEMENT:
      return {
        ...state,
        bank_statement: {
          loading: true,
          items: {},
          error: false,
        }
      };

    case settings_types.SUCCESS_GET_BANK_STATEMENT:
      return {
        ...state,
        bank_statement: {
          loading: false,
          items: action.payload,
          error: false,
        }
      };

    case settings_types.FAIL_GET_BANK_STATEMENT:
      return {
        ...state,
        bank_statement: {
          loading: false,
          items: {},
          error: true,
        }
      };   
    case settings_types.CLEAR_ROOT_FEE:
      return {
        ...state,
        fee: {
          loading: true,
          items: {},
          error: false,
        }
      };  
    default:
      return state;
  }
};

export default settings_reducer;
