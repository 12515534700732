import types from "./token-validation.types";
import {Api_v2} from "core/services/api";

export const postToken = () => async (dispatch) => {
  try {
    dispatch({
      type: types.POST_TOKEN,
    });

    const { data } = await Api_v2.post(`login/check-token`);

    dispatch({
      type: types.SUCCESS_POST_TOKEN,
      payload: data,
    });
    return true;
  } catch (err) {
    dispatch({
      type: types.FAIL_POST_TOKEN,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });

    return err?.response?.data?.error || "Erro inesperado";
  }
};
