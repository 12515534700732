import crud_form_operator_types from "./crud-form-operators.types";

const INITIAL_STATE = {
  table: { 
    loading: true,
    items: [],
    error: false,
  },
  edit: {
    loading: false,
    items: {},
    error: false,
  },
};

const crud_form_operator_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case crud_form_operator_types.LOADING_GET_OPERATOR:
      return {
        table: {
          loading: true,
          items: [ ...state.table.items ],
          error: false,
        },
        edit: {...state.edit}
      };


    case crud_form_operator_types.SUCCESS_GET_OPERATOR:
      return {
        table: {
          loading: false,
          items: action.payload,
          error: false,
        },
        edit: {...state.edit}
      };


    case crud_form_operator_types.FAIL_GET_OPERATOR:
      return {
        table: { 
          loading: false,
          items: [],
          error: true,
        },
        edit: {...state.edit}
      };

    case crud_form_operator_types.LOADING_GET_BY_ID_OPERATOR:
      return {
        ...state,
        edit: {
          loading: true,
          items: { ...state.edit.items },
          error: false,
        },
      };

    case crud_form_operator_types.SUCCESS_GET_BY_ID_OPERATOR:
      return {
        ...state,
        edit: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case crud_form_operator_types.FAIL_GET_BY_ID_OPERATOR:
      return {
        ...state,
        edit: {
          loading: false,
          items: action.payload,
          error: true,
        },
      };

    case "LOGOUT_USER":
      return INITIAL_STATE;
    
    default:
      return state;
  }
};

export default crud_form_operator_reducer;
