import { takeLatest, put } from "redux-saga/effects";
import moment from "moment";

import crud_transactions_types from "./crud-transactions.types";

import api from "core/services/api";

export function* getTransactionsAsync({ payload }) {
  yield put({ type: crud_transactions_types.LOADING_GET_TRANSACTIONS });

  try {
    const params = {
      transaction_date_min: payload[0].unix(),
      transaction_date_max: payload[1].unix(),
    };
    let { data } = yield api.get(
      `financial/receivable?start_date=${params.transaction_date_min}&end_date=${params.transaction_date_max}`
    );
    data.total_expense = Math.abs(data.total_expense);
    data.payed_expense = Math.abs(data.payed_expense);
    data.payments.map((item, index) => {
      item["key"] = index;
      item["loading"] = false;
      item["deleted"] = false;
      item["edited"] = false;
      item["editedSelected"] = false;
      return null;
    });
    yield put({
      type: crud_transactions_types.SUCCESS_GET_TRANSACTIONS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: crud_transactions_types.FAIL_GET_TRANSACTIONS,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}

export function* getCategorysAsync() {
  yield put({ type: crud_transactions_types.LOADING_GET_CATEGORYS });

  try {
    let { data } = yield api.get(`financial/category`, {
      headers: {
        "content-type": "application/json",
      },
    });
    data.map((item, index) => {
      item["key"] = index;
      return null;
    });
    yield put({
      type: crud_transactions_types.SUCCESS_GET_CATEGORYS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: crud_transactions_types.FAIL_GET_CATEGORYS,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}

export function* getCenterCostAsync({ payload }) {
  yield put({ type: crud_transactions_types.LOADING_GET_CENTER_COST });

  try {
    let { data } = yield api.get(`financial/cost-center`, {
      headers: {
        "content-type": "application/json",
      },
    });
    data.map((item, index) => {
      item["key"] = index;
      return null;
    });
    yield put({
      type: crud_transactions_types.SUCCESS_GET_CENTER_COST,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: crud_transactions_types.FAIL_GET_CENTER_COSTEGORYS,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}

export function* getBankAccountAsync() {
  yield put({ type: crud_transactions_types.LOADING_GET_BANK_ACCOUNT });

  try {
    let { data } = yield api.get(`financial/bank-account`, {
      headers: {
        "content-type": "application/json",
      },
    });
    data.map((item, index) => {
      item["key"] = index;
      return null;
    });
    yield put({
      type: crud_transactions_types.SUCCESS_GET_BANK_ACCOUNT,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: crud_transactions_types.FAIL_GET_BANK_ACCOUNTEGORYS,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}

export function* getTransactionByIdAsync({ payload }) {
  yield put({ type: crud_transactions_types.LOADING_GET_TRANSACTIONS_BY_ID });

  try {
    let { data } = yield api.get(`financial/receivable/${payload}`, {
      headers: {
        "content-type": "application/json",
      },
    });
    const dateFormat = "DD/MM/YYYY";
    let newPayload = data
      ? {
          ...data,
          amount: Math.abs(data?.amount),
          customer_id: data?.customer_id,
          payment_date: moment(
            moment.unix(data.payment_date).format("DD/MM/YYYY"),
            dateFormat
          ),
          pay_type:
            data?.has_installments === false &&
            data?.recurring_schema_id !== null
              ? "retryTransaction"
              : data?.has_installments
              ? "createParcels"
              : "inCash",
        }
      : null;

    if (data.competence_date !== undefined)
      newPayload["competence_date"] = moment(
        moment.unix(data.competence_date).format("DD/MM/YYYY"),
        dateFormat
      );

    yield put({
      type: crud_transactions_types.SUCCESS_GET_TRANSACTIONS_BY_ID,
      payload: newPayload,
    });
  } catch (err) {
    yield put({
      type: crud_transactions_types.FAIL_GET_TRANSACTIONS_BY_ID,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}

export function* getTransactions() {
  yield takeLatest(
    crud_transactions_types.GET_TRANSACTIONS,
    getTransactionsAsync
  );
  yield takeLatest(crud_transactions_types.GET_CATEGORYS, getCategorysAsync);
  yield takeLatest(crud_transactions_types.GET_CENTER_COST, getCenterCostAsync);
  yield takeLatest(
    crud_transactions_types.GET_BANK_ACCOUNT,
    getBankAccountAsync
  );
  yield takeLatest(
    crud_transactions_types.GET_TRANSACTIONS_BY_ID,
    getTransactionByIdAsync
  );
}
