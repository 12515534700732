import { takeLatest, put } from "redux-saga/effects"; 
import fluxopayAPI from "core/services/api";
import notification_types from "./notification.types";

export function* getNotificationAsync() {
  yield put({ type: notification_types.LOADING_GET_NOTIFICATION });

  try { 
    const { data } = yield fluxopayAPI.get(`notification`); 
    data.map((item, index) => {
      item["key"] = index;
      item["loading_btn1"] = false;
      item["loading_btn2"] = false;
      item["removing"] = false;
      return item;
    });
    yield put({
      type: notification_types.SUCCESS_GET_NOTIFICATION,
      payload: data,
    });
  } catch(err) {
    yield put({
      type: notification_types.FAIL_GET_NOTIFICATION,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}

export function* getNotification() {
  yield takeLatest(notification_types.GET_NOTIFICATION, getNotificationAsync);
}
