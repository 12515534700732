import cache_types from "./cache.types";

const INITIAL_STATE = {
  loading: false,
  items: {},
  error: false, 
};

const cache_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) { 
    case cache_types.LOADING_GET_CACHE:
      return {
        loading: true,
        items: {},
        error: false, 
      };

    case cache_types.SUCCESS_GET_CACHE:
      return {
        loading: false,
        items: action.payload,
        error: false, 
      };

    case cache_types.FAIL_GET_CACHE:
      return {
        loading: false,
        items: {},
        error: true, 
      };
         
    default:
      return state;
  }
};

export default cache_reducer;
