const crud_cards_types = {
  POST_CARD: "POST_CARD",
  SUCCESS_POST_CARD: "SUCCESS_POST_CARD",
  FAIL_POST_CARD: "FAIL_POST_CARD",
  DEL_CARD: "DEL_CARD",
  SUCCESS_DEL_CARD: "SUCCESS_DEL_CARD",
  FAIL_DEL_CARD: "FAIL_DEL_CARD",
  GET_CARD: "GET_CARD",
  LOADING_GET_CARD: "LOADING_GET_CARD",
  SUCCESS_GET_CARD: "SUCCESS_GET_CARD",
  FAIL_GET_CARD: "FAIL_GET_CARD", 
  GET_BY_ID_CARD: "GET_BY_ID_CARD",
  LOADING_GET_BY_ID_CARD: "LOADING_GET_BY_ID_CARD",
  SUCCESS_GET_BY_ID_CARD: "SUCCESS_GET_BY_ID_CARD",
  FAIL_GET_BY_ID_CARD: "FAIL_GET_BY_ID_CARD",
};

export default crud_cards_types;
