import { all, call } from 'redux-saga/effects';

import { authUser } from './crud-form-customer/crud-form-customer.sagas'
import { dashboard } from './dashboard/dashboard.sagas'
import { settings } from './settings/settings.sagas'

export default function* rootSaga(){
    yield all([
        call(authUser),
        call(dashboard),
        call(settings),

    ])
}