import moment from 'moment'
import { takeLatest, put } from "redux-saga/effects";

import negociations_panel from "./negociations-panel.types";

import api from "core/services/api";

export function* getNegociationsPanelAsync({ payload }) {
  yield put({ type: negociations_panel.LOADING_GET_NEGOCIATION_PANEL });

  try {
    const { data } = yield api.get("negotiation");

    const new_data = []

    data.map((item) => {
      new_data.push({
        open: false,
        customer_id: item.customer_id,
        key: item.id,
        status: item.status,
        date: moment.unix(item.created_at).format("DD/MM/YYYY h:mm:ss A"),
        customer: item.customer_info?.email,
        cpf_cnpj_number: item.customer_info.cpf_cnpj_number,
        operator_name: item.operator?.owner_name,
        customer_name: item.customer_info?.name,
        begin_date: moment.unix(item.created_at).format("DD/MM/YYYY   h:mm:ss A"),
        description: item.description,
        customer_info: [
          {
            title: 'Nome',
            value: item.customer_info.name,
          },
          {
            title: 'E-mail',
            value: item.customer_info.email,
          },
          {
            title: 'Celular',
            value: item.customer_info.cell_phone,
          },
          {       
            title: 'Cidade',
            value: item.customer_info.address_city,    
          }
        ]     
      })
 
      return null
    });

    yield put({
      type: negociations_panel.SUCCESS_GET_NEGOCIATION_PANEL,
      payload: new_data,
    });
  } catch (err) {
    yield put({ type: negociations_panel.FAIL_GET_NEGOCIATION_PANEL, payload: err?.response?.data?.error || "Erro inesperado!" });
  }
}


export function* getNegociationsByIdAsync({ payload }) {
  yield put({ type: negociations_panel.LOADING_GET_NEGOCIATION_BY_ID });

  try {
    const { data } = yield api.get(`negotiation/${payload}`);
    data['key'] = data.id;
    yield put({
      type: negociations_panel.SUCCESS_GET_NEGOCIATION_BY_ID,
      payload: data,
    }); 
  } catch (err) {  
    yield put({ type: negociations_panel.FAIL_GET_NEGOCIATION_BY_ID, payload: err?.response?.data?.error || "Erro inesperado!" });
  }
}

export function* getNegociationsAsync() {
  yield takeLatest(negociations_panel.GET_NEGOCIATION_PANEL, getNegociationsPanelAsync);
  yield takeLatest(negociations_panel.GET_NEGOCIATION_BY_ID, getNegociationsByIdAsync);

}
