import { combineReducers } from "redux";

//reducers
import imports from "./imports/reducer";
 
export const rootReducer = combineReducers({
  imports,
});

export default rootReducer;
