const negociations_panel = {
  "POST_NEGOCIATION_PANEL": "POST_NEGOCIATION_PANEL",
  "SUCCESS_POST_NEGOCIATION_PANEL": "SUCCESS_POST_NEGOCIATION_PANEL",
  "FAIL_POST_NEGOCIATION_PANEL": "FAIL_POST_NEGOCIATION_PANEL",
  "GET_NEGOCIATION_PANEL": "GET_NEGOCIATION_PANEL",
  "LOADING_GET_NEGOCIATION_PANEL": "LOADING_GET_NEGOCIATION_PANEL",
  "SUCCESS_GET_NEGOCIATION_PANEL": "SUCCESS_GET_NEGOCIATION_PANEL",
  "FAIL_GET_NEGOCIATION_PANEL": "FAIL_GET_NEGOCIATION_PANEL",
  "PATCH_ANOTATION": "PATCH_ANOTATION",
  "SUCCESS_PATCH_ANOTATION": "SUCCESS_PATCH_ANOTATION",
  "FAIL_PATCH_ANOTATION": "FAIL_PATCH_ANOTATION",
  "GET_NEGOCIATION_BY_ID": "GET_NEGOCIATION_BY_ID",
  "LOADING_GET_NEGOCIATION_BY_ID": "LOADING_GET_NEGOCIATION_BY_ID",
  "SUCCESS_GET_NEGOCIATION_BY_ID": "SUCCESS_GET_NEGOCIATION_BY_ID",
  "FAIL_GET_NEGOCIATION_BY_ID": "FAIL_GET_NEGOCIATION_BY_ID",
  "NEGOCIATION_FINISH": "NEGOCIATION_FINISH",
  "SUCCESS_NEGOCIATION_FINISH": "SUCCESS_NEGOCIATION_FINISH",
  "FAIL_NEGOCIATION_FINISH": "FAIL_NEGOCIATION_FINISH",
};

export default negociations_panel;
