import crud_card_types from "./crud-card.types";

const INITIAL_STATE = {
  table: { 
    loading: true,
    items: [],
    error: false,
  },
  one: {
    loading: false,
    items: {},
    error: false,
  },
};

const crud_card_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case crud_card_types.LOADING_GET_CARD:
      return {
        ...state,
        table: {
          loading: true,
          items: [],
          error: false,
        }, 
      };
    case crud_card_types.SUCCESS_GET_CARD:
      return {
        ...state,
        table: {
          loading: false,
          items: action.payload,
          error: false,
        }, 
      };
    case crud_card_types.FAIL_GET_CARD:
      return {
        ...state,
        table: { 
          loading: false,
          items: [],
          error: true,
        }, 
      };
    case crud_card_types.LOADING_GET_BY_ID_CARD:
      return {
        ...state,
        edit: {
          loading: true,
          items: {},
          error: false,
        },
      };
    case crud_card_types.SUCCESS_GET_BY_ID_CARD:
      return {
        ...state,
        edit: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };
    case crud_card_types.FAIL_GET_BY_ID_CARD:
      return {
        ...state,
        edit: {
          loading: false,
          items: action.payload,
          error: true,
        },
      };
 
    default:
      return state;
  }
};

export default crud_card_reducer;
