import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import cnpj_types from "./cnpj.types";

export function* authCnpjAsync({ payload }) {
  yield put({ type: cnpj_types.LOADING_GET_CNPJ_VALIDATION });

  try {
    const baseURL = "https://public.fluxoresultados.com.br/v1/";

    const Api = axios.create({
      baseURL,
      headers: { Accept: "application/json" },
    });
    const response = yield Api(`cnpj/${payload}`);

    yield put({
      type: cnpj_types.SUCCESS_GET_CNPJ_VALIDATION,
      payload: response.data,
    });
  } catch {
    yield put({
      type: cnpj_types.FAIL_GET_CNPJ_VALIDATION,
    });
  }
}

export function* authCnpj() {
  yield takeLatest(cnpj_types.GET_CNPJ_VALIDATION, authCnpjAsync);
}
