import { takeLatest, put } from "redux-saga/effects";
import moment from "moment";
import biling_tables_types from "./billing.types";

import api from "core/services/api";

export function* getBillingAsync({ payload }) {
  yield put({ type: biling_tables_types.LOADING_GET_BILLING });

  try {
    const params = {
      start_date: moment(payload[0]).unix(),
      end_date: moment(payload[1]).unix(), 
    };

    const { data } = yield api.get("billing", {
      params: params,
    }); 

    data.map((item) => {
      item["key"] = item.id;
      return null
    });

    yield put({
      type: biling_tables_types.SUCCESS_GET_BILLING,
      payload: data,
    });
  } catch (err) {
    yield put({ type: biling_tables_types.FAIL_GET_BILLING, payload: err?.response?.data?.error || "Erro inesperado!" });
  }
}
 
export function* getBilling() {
  yield takeLatest(biling_tables_types.GET_BILLING, getBillingAsync); 
}
