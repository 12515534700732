import { takeLatest, put } from "redux-saga/effects";

import settings_types from "./settings.types";

import api, { Api_v2 } from "core/services/api";
import {
  FormatCellNumber,
  FormatCnpjNumber,
} from "core/components/format-string/format-string";

export function* asynGetRootFees({ payload }) {
  yield put({ type: settings_types.LOADING_GET_ROOT_FEE });

  try {
    const { data } = yield api.get(`root-user/${payload}`);
    const bank_cc = data?.bank_info?.bank_cc?.slice(0, -2);
    const bank_cc_digit = data?.bank_info?.bank_cc?.slice(-1);
    var bank_ag = null;
    var bank_ag_digit = null;
    if (data?.bank_info?.bank_ag.includes("-")) {
      bank_ag = data?.bank_info?.bank_ag?.slice(0, -2);
      bank_ag_digit = data?.bank_info?.bank_ag?.slice(-1);
    } else {
      bank_ag = data?.bank_info?.bank_ag;
      bank_ag_digit = undefined;
    }
    const financial = {
      website_url: data.website_url,
      gender: data?.gender,
      date_of_birth: data?.date_of_birth,
      mcc: data?.mcc,
      secondary_document: data?.secondary_document,
      subaccount_creation_status: data?.subaccount_creation_status,
      cnpj: data?.cnpj ? FormatCnpjNumber(data?.cnpj) : "",
      company_name: data?.company_name,
      commercial_name: data?.commercial_name,
      user_type: data?.account_type,
      account_type: data?.bank_info?.account_type,
      owner_name: data?.owner_name,
      owner_cpf: data?.owner_cpf,
      whatsapp: data?.whatsapp ? FormatCellNumber(data?.whatsapp) : "",
      number: data?.address?.number,
      city: data?.address?.city,
      street: data?.address?.street,
      district: data?.address?.district,
      state: data?.address?.state,
      complement: data?.address?.complement,
      reference: data?.address?.reference,
      cep: data?.address?.cep,
      bank_ag: bank_ag,
      bank_ag_digit: bank_ag_digit,
      bank: data?.bank_info?.bank,
      bank_cc: bank_cc,
      bank_cc_digit: bank_cc_digit,
      automatic_advance: data?.automatic_advance,
    };
    if (financial.user_type === undefined) delete financial.user_type;
    if (financial.gender === undefined) delete financial.gender;
    const new_data = {
      registration_completed: data?.registration_completed,
      documents: {
        social_contract_url: data?.social_contract_url,
        identity_url: data?.identity_url,
        proof_of_bank_domicile_url: data.proof_of_bank_domicile_url,
      },
      logo: {
        image: data?.logo,
      },
      financial: financial,
      fees: {
        fee: {
          ...data.fee,
          promotion_remaining_value: data.promotion_remaining_value,
        },
        status: data?.status,
        max_sale_price: data?.max_sale_price,
        max_monthly_amount: data?.max_monthly_amount,
        subaccount_tax_payer_boleto: data?.subaccount_tax_payer_boleto,
        subaccount_tax_payer_card: data?.subaccount_tax_payer_card,
      },
    };

    yield put({
      type: settings_types.SUCCESS_GET_ROOT_FEE,
      payload: new_data,
    });
  } catch (err) {
    yield put({
      type: settings_types.FAIL_GET_ROOT_FEE,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}

export function* asynGetDocumentImportUrl({ payload }) {
  yield put({ type: settings_types.LOADING_GET_DOCUMENT_IMPORT_URL });

  try {
    const { data } = yield Api_v2.get(
      `root-user/document-url?extension=${payload}&document_type=identity`
    );

    yield put({
      type: settings_types.SUCCESS_GET_DOCUMENT_IMPORT_URL,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: settings_types.FAIL_GET_DOCUMENT_IMPORT_URL,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}

export function* asynGetSocialContractImportUrl({ payload }) {
  yield put({ type: settings_types.LOADING_GET_SOCIAL_CONTRACT });
  try {
    const { data } = yield Api_v2.get(
      `root-user/document-url?extension=${payload}&document_type=social_contract`
    );

    yield put({
      type: settings_types.SUCCESS_GET_SOCIAL_CONTRACT,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: settings_types.FAIL_GET_SOCIAL_CONTRACT,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}

export function* asynGetBankStatementImportUrl({ payload }) {
  yield put({ type: settings_types.LOADING_GET_BANK_STATEMENT });
  try {
    const { data } = yield Api_v2.get(
      `root-user/document-url?extension=${payload}&document_type=proof_of_bank_domicile`
    );

    yield put({
      type: settings_types.SUCCESS_GET_BANK_STATEMENT,
      payload: data,
    });
  } catch (err) {
    yield put({ type: settings_types.FAIL_GET_BANK_STATEMENT });
  }
}

export function* settings() {
  yield takeLatest(
    settings_types.GET_DOCUMENT_IMPORT_URL,
    asynGetDocumentImportUrl
  );
  yield takeLatest(
    settings_types.GET_SOCIAL_CONTRACT,
    asynGetSocialContractImportUrl
  );
  yield takeLatest(
    settings_types.GET_BANK_STATEMENT,
    asynGetBankStatementImportUrl
  );
  yield takeLatest(settings_types.GET_ROOT_FEE, asynGetRootFees);
}
