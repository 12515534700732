import { takeLatest, put } from "redux-saga/effects";
import cache_types from "./cache.types";

import api from "core/services/api";

export function* getCashAsync({ payload }) {
  yield put({ type: cache_types.LOADING_GET_CACHE });

  try {
    const { data } = yield api.get("cache", payload, {
      headers: {
        "content-type": "application/json",
      },
    });

    yield put({
      type: cache_types.SUCCESS_GET_CACHE,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: cache_types.FAIL_GET_CACHE,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}

export function* getCache() {
  yield takeLatest(cache_types.GET_CACHE, getCashAsync);
}
