import { createStore, applyMiddleware, compose, combineReducers } from "redux"; 
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as Sentry from "@sentry/react";
import thunk from "redux-thunk";

import ErrorReport from "core/redux/error-report/error-report.action";

// Reducers
import default_reducer from "./modules/default/redux/root-reducer";
import operators_reducer from "./modules/operators/redux/root-reducer";
import customers_reducer from "./modules/customers/redux/root.reducer";
import core_reducer from "core/redux/root-reducer";
import charges_reducer from "./modules/charges/redux/root-reducer";
import subaccount_reducer from "./modules/subaccount/redux/root-reducer";
import financial_reducer from "./modules/financial/redux/root-reducer";
import negociations_reducer from "./modules/negociations/redux/root-reducer"; 
import professional_reducer from "./modules/professional/redux/root-reducer"; 
import integration_reducer from "./modules/integration/redux/root-reducer";
import client_reducer from "./modules/client/redux/client-panel/client-panel.reducer"; 
import plans_reducer from "modules/plans/redux/crud-plans/crud-plans.reducer";
import imports_reducer from "modules/imports/redux/root-reducer";

// Sagas
import default_sagas from "./modules/default/redux/root-sagas";
import charges_sagas from "./modules/charges/redux/root-sagas";
import operators_sagas from "./modules/operators/redux/root-sagas";
import customers_sagas from "./modules/customers/redux/root.sagas";
import subaccount_sagas from "modules/subaccount/redux/root-sagas";
import financial_sagas from "./modules/financial/redux/root-sagas";
import negociations_sagas from "./modules/negociations/redux/root-sagas"; 
import professional_sagas from "./modules/professional/redux/root-sagas";

import core_sagas from "core/redux/root-sagas";
 
const reducers = combineReducers({
  default: default_reducer,
  core: core_reducer,
  operators: operators_reducer,
  charges: charges_reducer,
  customers: customers_reducer,
  subaccount: subaccount_reducer,
  financial: financial_reducer,
  negociations: negociations_reducer,
  professional: professional_reducer,
  plans: plans_reducer,
  client: client_reducer,
  integration: integration_reducer,
  imports: imports_reducer
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({}); 

const rootReducer = (state, action) => {
  ErrorReport(state, action, Sentry) 
  if (action.type === 'LOGOUT_USER') { 
    localStorage.clear(); 
    storage.removeItem('persist:fluxopay_front')
    return reducers(undefined, action)
  }

  return reducers(state, action)
}

const persistConfig = {
  key: "fluxopay_front",
  storage,
  blacklist: [/*"default" */ "core", "charges", "operators", "subaccount", "financial", "integration"],
};

//rebuild

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, thunk];

if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

export const store = createStore(
  persistedReducer,
  compose( applyMiddleware(...middlewares), sentryReduxEnhancer)
 
);

export const persistor = persistStore(store);

sagaMiddleware.run(default_sagas);
sagaMiddleware.run(core_sagas);
sagaMiddleware.run(operators_sagas);
sagaMiddleware.run(charges_sagas);
sagaMiddleware.run(customers_sagas);
sagaMiddleware.run(subaccount_sagas);
sagaMiddleware.run(financial_sagas);
sagaMiddleware.run(negociations_sagas);
sagaMiddleware.run(professional_sagas);

export default {
  store,
};
