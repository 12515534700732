import { notification } from "antd";

const Notification = (type, description, message) => {
  notification[type]({
    message:
      message !== undefined
        ? message
        : type === "success"
        ? "Sucesso!"
        : "Erro!",
    description: description,
  });
};

export default Notification;
