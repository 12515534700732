import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({
  user = {},
  path = "/",
  component = <div>loading...</div>,
  permission = true,
}) => {
  return (
    <Route
      exact
      path={path}
      render={() =>
        user.is_on && permission ? (
          component
        ) : user.is_on ? (
          <Redirect to="/auth/financial/transactions" />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
