import axios from "axios";

import { takeLatest, put } from "redux-saga/effects";

import bank_types from "./bank.types";

export function* getBanksAsync({ payload }) {
  yield put({ type: bank_types.LOADING_GET_BANK });

  try {
    const baseURL = "https://public.fluxoresultados.com.br/v1/";

    const Api = axios.create({
      baseURL,
      headers: { Accept: "application/json" },
    });

    const { data } = yield Api.get(`bank`);

    yield put({
      type: bank_types.SUCCESS_GET_BANK,
      payload: data,
    });
  } catch(err) {
    yield put({
      type: bank_types.FAIL_GET_BANK,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}

export function* authBank() {
  yield takeLatest(bank_types.GET_BANK, getBanksAsync);
}
