import notification_types from "./notification.types";
 
const INITIAL_STATE = {
  loading: false,
  items: [],
  error: false,
};

const notification_reducer = (state = INITIAL_STATE, action) => { 
  switch (action.type) {  
      case notification_types.LOADING_GET_NOTIFICATION:
      return { 
        loading: true,
        items: [],
        error: false, 
      };

    case notification_types.SUCCESS_GET_NOTIFICATION:
      return {
        loading: false,
        items: action.payload,
        error: false,
      };

    case notification_types.FAIL_GET_NOTIFICATION:
      return {
        loading: false,
        items: [],
        error: true,
      }; 
  
 
    default:
      return state;
  }
};

export default notification_reducer;