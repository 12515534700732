import { store } from "store";
import plan_panels_types from "./plan-panel.types";

export const getPlans = () => (dispatch) => {
  dispatch({
    type: plan_panels_types.GET_PLAN,
  });
};

export const getPlan = (params) => () => {
  const quota = store?.getState()?.professional?.plan?.quota?.items;
  if (Object.keys(quota).length === 0) return;
  if (
    quota.current_plan === "FluxoPay Free" &&
    quota.pro_quota_usage[params.name] > quota.pro_quota_max_usage
  )
    return true;
  return false;
};

export const updatePlan = (params) => (dispatch) => {
  const quota = store?.getState()?.professional?.plan?.quota?.items; 
  if (Object.keys(quota).length === 0) return;
  if (
    quota.current_plan === "FluxoPay Free" &&
    quota.pro_quota_usage[params.name] > quota.pro_quota_max_usage
  )
    return true;
  dispatch({
    type: plan_panels_types.UPDATE_PLAN,
    payload: params,
  });
  return false;
};
