import { connect } from "react-redux";

import UpdatePasswordForm from "./uptade-password-form.component"; 

import { patchCustomerStatus } from '../../../redux/crud-form-customer/crud-form-customer.actions'; 

const mapDispatchToProps = { 
    patchCustomerStatus
};
 
export default connect(
  null,
  mapDispatchToProps
)(UpdatePasswordForm);
