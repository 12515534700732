import { Form, Input } from "antd";
import React from "react";
import { UnlockOutlined } from "@ant-design/icons";

export const InputPassword = ({ name, label, required = true, rules={}}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[ 
        { required: required },
        { min: 8 },
        {
          pattern: /[a-z]+/,
          message: "uma letra minúscula",
        },
        {
          pattern: /[A-Z]+/,
          message: "uma letra maiúscula",
        },
        {
          pattern: /[@$!%*#?&]+/,
          message: "um caracter especial",
        },
        {
          pattern: /\d+/,
          message: "um número",
        },
        (rules)
      ]}
    >
      <Input.Password
        prefix={
          <UnlockOutlined
            style={{
              color: "rgba(0, 0, 0, 0.25)",
              fontSize: "20px",
            }}
          />
        }
        allowClear
      />
    </Form.Item>
  );
};
