const tour_types = {
    SET_NOTIFICATION_PROPS: "SET_NOTIFICATION_PROPS",
    SUCCESS_SET_NOTIFICATION_PROPS: "SUCCESS_SET_NOTIFICATION_PROPS",
    FAIL_SET_NOTIFICATION_PROPS: "FAIL_SET_NOTIFICATION_PROPS",
    CLOSE_NOTIFICATION: "CLOSE_NOTIFICATION",
    FAIL_CLOSE_NOTIFICATION: "FAIL_CLOSE_NOTIFICATION", 

    POST_NOTIFICATION: "POST_NOTIFICATION",
    SUCCESS_POST_NOTIFICATION: "SUCCESS_POST_NOTIFICATION",
    FAIL_POST_NOTIFICATION: "FAIL_POST_NOTIFICATION",
 
    POST_CALLBACK_NOTIFICATION: "POST_CALLBACK_NOTIFICATION",
    SUCCESS_POST_CALLBACK_NOTIFICATION: "SUCCESS_POST_CALLBACK_NOTIFICATION",
    FAIL_POST_CALLBACK_NOTIFICATION: "FAIL_POST_CALLBACK_NOTIFICATION",
    
    DEL_NOTIFICATION: "DEL_NOTIFICATION",
    SUCCESS_DEL_NOTIFICATION: "SUCCESS_DEL_NOTIFICATION",
    FAIL_DEL_NOTIFICATION: "FAIL_DEL_NOTIFICATION",
 
    GET_NOTIFICATION: "GET_NOTIFICATION",
    LOADING_GET_NOTIFICATION: "LOADING_GET_NOTIFICATION",
    SUCCESS_GET_NOTIFICATION: "SUCCESS_GET_NOTIFICATION",
    FAIL_GET_NOTIFICATION: "FAIL_GET_NOTIFICATION"
};
  
export default tour_types;
  