import cep_types from "./cep.types";

const INITIAL_STATE = {
  loading: true,
  items: {},
  error: false,
  error_message: {},
};

const cep_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case cep_types.LOADING_GET_CEP:
      return {
        loading: true,
        items: { ...state.items },
        error: false,
        error_message: {},
      };

    case cep_types.SUCCESS_GET_CEP:
      return {
        loading: false,
        items: action.payload,
        error: false,
        error_message: {},
      };

    case cep_types.FAIL_GET_CEP:
      return {
        loading: false,
        items: {},
        error: true,
        error_message: action.error_message,
      };
    
    case "LOGOUT_USER":
      return INITIAL_STATE; 
    
    default:
      return state;
  }
};

export default cep_reducer;
