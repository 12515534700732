import { connect } from "react-redux";

import MenuSlider from "./menu.component";

import { logoutUser } from "../../../modules/default/redux/crud-form-customer/crud-form-customer.actions.js";

import { removeSubUSer } from "modules/default/redux/crud-form-customer/crud-form-customer.actions";

import {
  getNotifications,
  postNotificationCallback,
  deleteNotifications,
} from "core/redux/notification/notification.action";
import { setModalProps } from "core/redux/modal/modal.action";
import { getClearAccount } from "modules/default/redux/settings/settings.actions";
import { setDrawerProps, onCloseDrawer } from "core/redux/drawer/drawer.action";

const mapStateToProps = (state) => {
  return {
    sub_user: state.default.user.sub_user,
    user: state.default.user,
    plan_active: state.professional.plan.panel,
    notifications: state.core.notification,
  };
};

const mapDispatchToProps = {
  postNotificationCallback,
  getNotifications,
  deleteNotifications,
  setModalProps,
  setDrawerProps,
  onCloseDrawer,
  getClearAccount,
  logoutUser,
  removeSubUSer,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuSlider);
