import bank_types from "./bank.types";

const INITIAL_STATE = {
  loading: true,
  items: [],
  error: false,
};

const bank_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case bank_types.LOADING_GET_BANK:
      return {
        loading: true,
        items: [ ...state.items ],
        error: false,
       };

    case bank_types.SUCCESS_GET_BANK:
      return {
        loading: false,
        items: action.payload,
        error: false,
 
      };

    case bank_types.FAIL_GET_BANK:
      return {
        loading: false,
        items: [],
        error: true,
       };
    
    case "LOGOUT_USER":
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default bank_reducer;
