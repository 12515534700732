import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import cep_types from "./cep.types";

export function* authCepAsync({ payload }) {
  yield put({ type: cep_types.LOADING_GET_CEP });

  try {
    const baseURL = "https://public.fluxoresultados.com.br/v1/";

    const Api = axios.create({
      baseURL,
      headers: { Accept: "application/json" },
    });

    const { data } = yield Api.get(`cep/${payload}`);

    yield put({
      type: cep_types.SUCCESS_GET_CEP,
      payload: data,
    });
  } catch(err) {
    yield put({
      type: cep_types.FAIL_GET_CEP,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}

export function* authCep() {
  yield takeLatest(cep_types.GET_CEP, authCepAsync);
}
