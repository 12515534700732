import form_logs_types from "./crud-table-logs.types";

const INITIAL_STATE = {
  table: {
    loading: true,
    items: [],
    error: false,
  },
};

const form_reports_reducer = (state = INITIAL_STATE, action) => {
   switch (action.type) {
    case form_logs_types.LOADING_GET_USER_LOGS:
      return {
        ...state,
        table: {
          loading: true,
          items: [...state.table.items],
          error: false,
        },
      };

    case form_logs_types.SUCCESS_GET_USER_LOGS:
      return {
        ...state,
        table: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case form_logs_types.FAIL_GET_USER_LOGS:
      return {
        ...state,
        table: {
          loading: false,
          items: "",
          error: true,
        },
      };
    
    case "LOGOUT_USER":
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default form_reports_reducer;
