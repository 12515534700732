import  { connect } from 'react-redux'

import FormTermsUse from './form-terms-use.component' 
 
import { postRegisterCustomer, patchCustomerStatus, aceeptedPolices }  from '../../redux/crud-form-customer/crud-form-customer.actions';
import { getPlans } from "../../../professional/redux/plan-panel/plan-panel.actions";

import { setModalProps, onCloseModal } from "core/redux/modal/modal.action";
  
const mapStateToProps = (state) => {
    return {  
      user: state.default.user,
      modal: state.core.modal_props
    };
};

const mapDispatchToProps =  {
  postRegisterCustomer,
  patchCustomerStatus,
  aceeptedPolices, 
  getPlans,
  setModalProps,
  onCloseModal
}

export default connect(mapStateToProps, mapDispatchToProps)(FormTermsUse)