import { takeLatest, put } from "redux-saga/effects";

import crud_form_subaccount_types from "./crud-form-subaccount.types";

import api from "core/services/api";

function formataCPF(cpf) {
  if (cpf === null || cpf === undefined) {
    return "--";
  }
  cpf = cpf.replace(/[^\d]/g, "");
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

function formataCNPJ(cnpj) {
  if (cnpj === null || cnpj === undefined) {
    return "--";
  }
  cnpj = cnpj.replace(/[^\d]/g, "");
  return cnpj.replace(
    /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
    "$1.$2.$3/$4-$5"
  );
}

export function* authSubaccountAsync({ payload }) {
  yield put({ type: crud_form_subaccount_types.LOADING_GET_AUTH_SUBACCOUNT });

  try {
    const { data } = yield api.post("login", payload, {
      headers: {
        "content-type": "application/json",
      },
    });

    yield put({
      type: crud_form_subaccount_types.SUCCESS_GET_AUTH_SUBACCOUNT,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: crud_form_subaccount_types.FAIL_GET_AUTH_SUBACCOUNT,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}

export function* getSubaccountAsync({ payload }) {
  yield put({ type: crud_form_subaccount_types.LOADING_GET_SUBACCOUNT });

  try {
    const { data } = yield api.get("root-user", {
      headers: {
        "content-type": "application/json",
      },
    });

    data.map((item, index) => {
      item["cpf_cnpj_number"] =
        item.account_type === "pf"
          ? formataCPF(item.owner_cpf)
          : formataCNPJ(item.cnpj);
      item["key"] = index;
      return item;
    });

    yield put({
      type: crud_form_subaccount_types.SUCCESS_LOADING_GET_SUBACCOUNT,
      payload: data,
    });
  } catch (err) {
    console.error(err);
    yield put({
      type: crud_form_subaccount_types.FAIL_LOADING_GET_SUBACCOUNT,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}

export function* getSubaccountByIdAsync({ payload }) {
  yield put({ type: crud_form_subaccount_types.LOADING_GET_SUBACCOUNT_BY_ID });

  try {
    const { data } = yield api.get(`root-user/${payload}`, {
      headers: {
        "content-type": "application/json",
      },
    });

    yield put({
      type: crud_form_subaccount_types.SUCCESS_GET_SUBACCOUNT_BY_ID,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: crud_form_subaccount_types.FAIL_GET_SUBACCOUNT_BY_ID,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}

export function* authSubaccount() {
  yield takeLatest(
    crud_form_subaccount_types.GET_AUTH_SUBACCOUNT,
    authSubaccountAsync
  );
  yield takeLatest(
    crud_form_subaccount_types.GET_SUBACCOUNT,
    getSubaccountAsync
  );
  yield takeLatest(
    crud_form_subaccount_types.GET_SUBACCOUNT_BY_ID,
    getSubaccountByIdAsync
  );
}
