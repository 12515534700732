import { takeLatest, put } from "redux-saga/effects";

import form_report_types from "./table-report-panel.types";

import api from "core/services/api";

export function* getReportUserAsync({ id }) {
  yield put({ type: form_report_types.LOADING_GET_USER_REPORT });

  try {
    let { data } = yield api.get(`logs/user/${id}`);
    data.map((item, index) => {
      item["key"] = index;
      return null;
    });
    yield put({
      type: form_report_types.SUCCESS_GET_USER_REPORT,
      payload: data,
    });
  } catch (err) {
    yield put({ type: form_report_types.FAIL_GET_USER_REPORT, payload: err?.response?.data?.error || "Erro inesperado!" });
  }
}

export function* getReports() {
  yield takeLatest(form_report_types.GET_USER_REPORT, getReportUserAsync);
}
