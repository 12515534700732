import types from "./types";

const INITIAL_STATE = {
  table: { 
    loading: true,
    items: [],
    error: false,
  }, 
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_IMPORTS:
        return {
          table: {
            loading: true,
            items: [ ...state.table.items ],
            error: false,
          }, 
        };
 
    case types.SUCCESS_GET_IMPORTS:
      return {
        table: {
          loading: false,
          items: action.payload,
          error: false,
        }, 
      };

    case types.FAIL_GET_IMPORTS:
      return {
        table: { 
          loading: false,
          items: [],
          error: true,
        }, 
      };
  
    default:
      return state;
  }
};

export default reducer;
