import { takeLatest, put } from "redux-saga/effects";

import crud_form_invoices_types from "./form-invoices.types";

import { Api_v2 } from "core/services/api";

export function* getInvoicesAsync({ payload }) {
  yield put({ type: crud_form_invoices_types.LOADING_GET_INVOICES });

  try {
    const params = {
      start_date: payload[0].unix(),
      end_date: payload[1].unix(),
    };
    let { data } = yield Api_v2.get(`transaction`, { params: params });

    data.map((item) => {
      item["key"] = item.id;
      return null;
    });

    yield put({
      type: crud_form_invoices_types.SUCCESS_GET_INVOICES,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: crud_form_invoices_types.FAIL_GET_INVOICES,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}

export function* getInvoicesByCustomerIdAsync({ payload }) {
  yield put({
    type: crud_form_invoices_types.LOADING_GET_INVOICES_BY_CUSTOMER_ID,
  });
  try {
    let { data } = yield Api_v2.get(`transaction/customer/${payload}`);
    data.map((d) => {
      d["key"] = d.id;
      return null;
    });
    yield put({
      type: crud_form_invoices_types.SUCCESS_GET_INVOICES_BY_CUSTOMER_ID,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: crud_form_invoices_types.FAIL_GET_INVOICES_BY_CUSTOMER_ID,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}

export function* getInvoicesByIdAsync({ id }) {
  yield put({ type: crud_form_invoices_types.LOADING_GET_INVOICES_BY_ID }); 
  try {
    let { data } = yield Api_v2.get(`transaction/${id}`);
    yield put({
      type: crud_form_invoices_types.SUCCESS_GET_INVOICES_BY_ID,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: crud_form_invoices_types.FAIL_GET_INVOICES_BY_ID,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}

export function* getInvoices() {
  yield takeLatest(crud_form_invoices_types.GET_INVOICES, getInvoicesAsync);

  yield takeLatest(
    crud_form_invoices_types.GET_INVOICES_BY_CUSTOMER_ID,
    getInvoicesByCustomerIdAsync
  );

  yield takeLatest(
    crud_form_invoices_types.GET_INVOICES_BY_ID,
    getInvoicesByIdAsync
  );
}
