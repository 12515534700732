import form_reports_types from "./form-reports.types";

const INITIAL_STATE = {
  table: {
    loading: false,
    items: {},
    error: false,
  },
  table1: {
    loading: false,
    items: {},
    error: false,
  },
  table2: {
    loading: false,
    items: {},
    error: false,
  },
  table3: {
    loading: false,
    items: {},
    error: false,
  },
  table4: {
    loading: false,
    items: {},
    error: false,
  },
  table5: {
    loading: false,
    items: {},
    error: false,
  },
  panel: {
    loading: false,
    items: {},
    error: false,
  },
  panel1: {
    loading: false,
    items: {},
    error: false,
  },  
};

const form_reports_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case form_reports_types.LOADING_GET_FINANCIAL_STATEMENT:
      return {
        ...state,
        table: {
          loading: true,
          items: {},
          error: false,
        },
      };

    case form_reports_types.SUCCESS_GET_FINANCIAL_STATEMENT:
      return {
        ...state,
        table: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case form_reports_types.FAIL_GET_FINANCIAL_STATEMENT:
      return {
        ...state,
        table: {
          loading: false,
          items: {},
          error: true,
        },
      };

    case form_reports_types.LOADING_GET_RECONCILIATION_WITHDRAWALS:
      return {
        ...state,
        table1: {
          loading: true,
          items: {},
          error: false,
        },
      };

    case form_reports_types.SUCCESS_GET_RECONCILIATION_WITHDRAWALS:
      return {
        ...state,
        table1: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case form_reports_types.FAIL_GET_RECONCILIATION_WITHDRAWALS:
      return {
        ...state,
        table1: {
          loading: false,
          items1: [],
          error: true,
        },
      };

    case form_reports_types.LOADING_GET_EXTRACT_PAID_INVOICES:
      return {
        ...state,
        table2: {
          loading: true,
          items: {},
          error: false,
        },
      };

    case form_reports_types.SUCCESS_GET_EXTRACT_PAID_INVOICES:
      return {
        ...state,
        table2: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case form_reports_types.FAIL_GET_EXTRACT_PAID_INVOICES:
      return {
        ...state,
        table2: {
          loading: false,
          items: {},
          error: true,
        },
      };
 
    case form_reports_types.LOADING_GET_REVENUE_BY_AGGREGATOR:
      return {
        ...state,
        table3: {
          loading: true,
          items: {},
          error: false,
        },
      };

    case form_reports_types.SUCCESS_GET_REVENUE_BY_AGGREGATOR:
      return {
        ...state,
        table3: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case form_reports_types.FAIL_GET_REVENUE_BY_AGGREGATOR:
      return {
        ...state,
        table2: {
          loading: false,
          items: {},
          error: true,
        },
      };

    case form_reports_types.LOADING_GET_INCOME_STATEMENT:
      return {
        ...state,
        panel: {
          loading: true,
          items: { },
          error: false,
        },
      };

    case form_reports_types.SUCCESS_GET_INCOME_STATEMENT:
      return {
        ...state,
        panel: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case form_reports_types.FAIL_GET_INCOME_STATEMENT:
      return {
        ...state,
        panel: {
          loading: false,
          items: {},
          error: true,
        },
      };   
    case form_reports_types.LOADING_GET_ANALYSIS:
      return {
        ...state,
        panel1: {
          loading: true,
          items: {},
          error: false,
        },
      }; 
    case form_reports_types.SUCCESS_GET_ANALYSIS:
      return {
        ...state,
        panel1: {
          loading: false,
          items: action.payload,
          error: false,
        },
      }; 
    case form_reports_types.FAIL_GET_ANALYSIS:
      return {
        ...state,
        panel1: {
          loading: false,
          items: {},
          error: true,
        },
      };  

    case form_reports_types.LOADING_GET_DAILY_REVENUE:
      return {
        ...state,
        table4: {
          loading: true,
          items: {},
          error: false,
        },
      };

    case form_reports_types.SUCCESS_GET_DAILY_REVENUE:
      return {
        ...state,
        table4: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case form_reports_types.FAIL_GET_DAILY_REVENUE:
      return {
        ...state,
        table4: {
          loading: false,
          items: {},
          error: true,
        },
      };   

      case form_reports_types.LOADING_GET_CASH_FLOW:
        return {
          ...state,
          table5: {
            loading: true,
            items: {},
            error: false,
          },
        };
  
      case form_reports_types.SUCCESS_GET_CASH_FLOW:
        return {
          ...state,
          table5: {
            loading: false,
            items: action.payload,
            error: false,
          },
        };
  
      case form_reports_types.FAIL_GET_CASH_FLOW:
        return {
          ...state,
          table5: {
            loading: false,
            items: {},
            error: true,
          },
        };   
  
    default:
      return state;
  }
};

export default form_reports_reducer;
