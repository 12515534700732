import { connect } from "react-redux";

import Anotation from "./anotation.component";

import { setModalProps, onCloseModal } from "core/redux/modal/modal.action";
import { postNotification } from "core/redux/anotation/anotation.action"; 
import { getPlan } from "modules/professional/redux/plan-panel/plan-panel.actions";


const mapDispatchToProps = {
  setModalProps,
  onCloseModal,
  postNotification,
  getPlan
};

export default connect(null, mapDispatchToProps)(Anotation);
