import { connect } from "react-redux";

import PrivateRoute from "./private-route.component";

 
const mapStateToProps = (state) => {

  return({
    user : state.default.user
  })
  
};

 

export default connect(mapStateToProps, null)(PrivateRoute);
