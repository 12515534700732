import tour_types from "./tour.types";
import fluxopayAPI from "core/services/api";
import { store } from 'store'; 

export const postTours = (params) => async (dispatch) => {
  try {
    dispatch({
      type: tour_types.POST_TOUR,
    });
  
    const payload = {
      tours: params
    }
    await fluxopayAPI.post(`onboarding`, payload);

    dispatch({
      type: tour_types.SUCCESS_POST_TOUR,
      payload,
    }); 
    return true;
  } catch (err) {
    dispatch({
      type: tour_types.FAIL_POST_TOUR,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}; 


export const getTours = () => (dispatch) => {   
  dispatch({
    type: tour_types.GET_TOUR, 
  }); 
};

export const setTourProps = (params) => async (dispatch) => {
  try {
    dispatch({
      type: tour_types.SET_TOUR_PROPS,
    });   
    const tours = store.getState().core.tour_props.tours.items.tours;
    const found = tours.find(element => element === params.name);
    const payload = {
      tours: tours
    }   
    params["visible"] = found === undefined ? true : false;  
    dispatch({
      type: tour_types.SUCCESS_SET_TOUR_PROPS,
      payload: params,
    });   
    
    if(found === undefined){ 
      payload.tours.push(params.name);   
      await fluxopayAPI.post(`onboarding`, payload);
    }    
  } catch (err) {
    dispatch({
      type: tour_types.FAIL_SET_TOUR_PROPS,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
};


export const onCloseTour = () => async (dispatch) => {
  try {
    dispatch({
      type: tour_types.CLOSE_TOUR,
    });
  } catch (err) {
    dispatch({
      type: tour_types.FAIL_CLOSE_TOUR,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
};
