import { takeLatest, put } from 'redux-saga/effects'
 
import crud_form_massified_types from "./form-massified.types";

import api from 'core/services/api'

export function* getMassifiedAsync( ){
  yield put({type: crud_form_massified_types.LOADING_GET_MASSIFIED})

  try{
    const {data}= yield api.get('massified', {
      headers: {
        'content-type': 'application/json'
      }
    })

    data.map(d => { 
      d['key'] = d.id
      return null
    })

    yield put({
      type: crud_form_massified_types.SUCCESS_GET_MASSIFIED,
      payload: data
    })

   } catch(err){

    yield put({type: crud_form_massified_types.FAIL_GET_MASSIFIED,payload: err?.response?.data?.error || "Erro inesperado!", })
  }
}


export function* getMassifiedByIdAsync(params){
  yield put({type: crud_form_massified_types.LOADING_GET_MASSIFIED_BY_ID})

  try{
    const {data}= yield api.get(`massified/${params.payload}`, {
      headers: {
        'content-type': 'application/json'
      }
    })

    yield put({
      type: crud_form_massified_types.SUCCESS_GET_MASSIFIED_BY_ID,
      payload: data
    })

   } catch(err){

    yield put({type: crud_form_massified_types.FAIL_GET_MASSIFIED_BY_ID, payload: err?.response?.data?.error || "Erro inesperado!", })
  }
}


export function* getMassified(){
  yield takeLatest(crud_form_massified_types.GET_MASSIFIED, getMassifiedAsync)
  yield takeLatest(crud_form_massified_types.GET_MASSIFIED_BY_ID, getMassifiedByIdAsync)

}