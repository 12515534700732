import crud_anotation_types from "./anotation.types";
import fluxopayAPI from "core/services/api";
import moment from "moment"

export const postNotification = (params) => async (dispatch) => {
    try {
      dispatch({
        type: crud_anotation_types.POST_ANOTATION,
      }); 
      
      const payload = {
        ...params,
        notification_date: moment(params.notification_date).unix()
      }

      const { data } = await fluxopayAPI.post(`notification`, payload);
  
      dispatch({
        type: crud_anotation_types.SUCCESS_POST_ANOTATION,
        payload: data,
      });
   
      return true;
    } catch (err) {
      dispatch({
        type: crud_anotation_types.FAIL_POST_ANOTATION,
        payload: err?.response?.data?.error || "Erro inesperado!",
      }); 
      return err?.response?.data?.error? err.response.data.error : "Erro inesperado!"
    }
  };