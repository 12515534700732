const crud_form_subaccount_types = {
  POST_NEW_SUBACCOUNT: "POST_NEW_SUBACCOUNT",
  SUCCESS_POST_NEW_SUBACCOUNT: "SUCCESS_POST_NEW_SUBACCOUNT",
  FAIL_POST_NEW_SUBACCOUNT: "FAIL_POST_NEW_SUBACCOUNT",
  GET_AUTH_SUBACCOUNT: "GET_AUTH_SUBACCOUNT",
  LOADING_GET_AUTH_SUBACCOUNT: "LOADING_GET_AUTH_SUBACCOUNT",
  SUCCESS_GET_AUTH_SUBACCOUNT: "SUCCESS_GET_AUTH_SUBACCOUNT",
  FAIL_GET_AUTH_SUBACCOUNT: "FAIL_GET_AUTH_SUBACCOUNT",
  LOGOUT_USER: "LOGOUT_USER",
  GET_SUBACCOUNT: "GET_SUBACCOUNT",
  LOADING_GET_SUBACCOUNT: "LOADING_GET_SUBACCOUNT",
  SUCCESS_LOADING_GET_SUBACCOUNT: "SUCCESS_LOADING_GET_SUBACCOUNT",
  FAIL_LOADING_GET_SUBACCOUNT: "FAIL_LOADING_GET_SUBACCOUNT",
  DEL_SUBACCOUNT: "DEL_SUBACCOUNT",
  SUCCESS_DEL_SUBACCOUNT: "SUCCESS_DEL_SUBACCOUNT",
  FAIL_DEL_SUBACCOUNT: "FAIL_DEL_SUBACCOUNT",
  ACTIVE_SUBACCOUNT: "ACTIVE_SUBACCOUNT",
  SUCCESS_ACTIVE_SUBACCOUNT: "SUCCESS_ACTIVE_SUBACCOUNT",
  FAIL_ACTIVE_SUBACCOUNT: "FAIL_ACTIVE_SUBACCOUNT",
  GET_SUBACCOUNT_BY_ID: "GET_SUBACCOUNT_BY_ID",
  LOADING_GET_SUBACCOUNT_BY_ID: "LOADING_GET_SUBACCOUNT_BY_ID",
  SUCCESS_GET_SUBACCOUNT_BY_ID: "SUCCESS_GET_SUBACCOUNT_BY_ID", 
  GET_SUBACCOUNT_FILTER: "GET_SUBACCOUNT_FILTER",
  SUCCESS_GET_SUBACCOUNT_FILTER: "SUCCESS_GET_SUBACCOUNT_FILTER", 
  FAIL_GET_SUBACCOUNT_FILTER: "FAIL_GET_SUBACCOUNT_FILTER",
  FAIL_GET_SUBACCOUNT_BY_ID: "FAIL_GET_SUBACCOUNT_BY_ID",
  PUT_SUBACCOUNT: "PUT_SUBACCOUNT",
  SUCCESS_PUT_SUBACCOUNT: "SUCCESS_PUT_SUBACCOUNT",
  FAIL_PUT_SUBACCOUNT: "FAIL_PUT_SUBACCOUNT",
};

export default crud_form_subaccount_types;
