import plan_panel_types from "./plan-panel.types";

const INITIAL_STATE = {
  panel: {
    loading: true,
    items: [],
    error: false,
  },
  quota: {
    items: {},
  },
};

const plan_panel_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case plan_panel_types.LOADING_GET_PLAN:
      return {
        ...state,
        panel: {
          loading: true,
          items: [],
          error: false,
        },
      };

    case plan_panel_types.SUCCESS_GET_PLAN:
      return {
        ...state,
        panel: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case plan_panel_types.FAIL_GET_PLAN:
      return {
        ...state,
        panel: {
          loading: false,
          items: [],
          error: true,
        },
      };

    case plan_panel_types.SET_PLAN:
      return {
        ...state,
        quota: {
          items: {
            pro_quota_max_usage: action.payload.pro_quota_max_usage,
            pro_quota_usage: action.payload.pro_quota_usage,
            current_plan: action.payload.current_plan.name,
          },
        },
      };

    case plan_panel_types.UPDATE_PLAN: 
      return {
        ...state,
        quota: {
          items: {
            current_plan: state.quota.items.current_plan,
            pro_quota_max_usage: state.quota.items.pro_quota_max_usage,
            pro_quota_usage: {
              ...state.quota.items.pro_quota_usage,
              [action.payload.name]:
                state.quota.items.pro_quota_usage[action.payload.name] + 1,
            },
          },
        },
      };
    default:
      return state;
  }
};

export default plan_panel_reducer;
