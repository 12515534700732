import { takeLatest, put } from "redux-saga/effects";

import form_reports_types from "./form-reports.types";

import api from "core/services/api";

export function* getFinancialStatementAsync({ payload }) {
  yield put({ type: form_reports_types.LOADING_GET_FINANCIAL_STATEMENT });

  try {
    const params = {
      date_min: payload[0].unix(),
      date_max: payload[1].unix(),
      format_file: "xlsx",
    };
    let { data } = yield api.get(`report/financial-statement`, {
      params: params,
    });

    yield put({
      type: form_reports_types.SUCCESS_GET_FINANCIAL_STATEMENT,
      payload: data,
    });
  } catch (err) {
    yield put({ type: form_reports_types.FAIL_GET_FINANCIAL_STATEMENT, payload: err?.response?.data?.error || "Erro inesperado!" });
  }
}

export function* getReconciliationWithdrawalsAsync({ payload }) {
  yield put({
    type: form_reports_types.LOADING_GET_RECONCILIATION_WITHDRAWALS,
  });

  try {
    const params = {
      date_min: payload[0].unix(),
      date_max: payload[1].unix(),
      format_file: "xlsx",
    };

    let { data } = yield api.get(`report/reconciliation-withdrawals`, {
      params: params,
    });

    yield put({
      type: form_reports_types.SUCCESS_GET_RECONCILIATION_WITHDRAWALS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: form_reports_types.FAIL_GET_RECONCILIATION_WITHDRAWALS, payload: err?.response?.data?.error || "Erro inesperado!" });
  }
}

export function* getExtractPaidInvoicesAsync({ payload }) {
  yield put({ type: form_reports_types.LOADING_GET_EXTRACT_PAID_INVOICES });

  try {
    const params = {
      date_min: payload[0].unix(),
      date_max: payload[1].unix(),
      format_file: "xlsx",
    };

    let { data } = yield api.get(`report/extract-paid-invoices`, {
      params: params,
    });

    yield put({
      type: form_reports_types.SUCCESS_GET_EXTRACT_PAID_INVOICES,
      payload: data,
    });
  } catch (err) {
    yield put({ type: form_reports_types.FAIL_GET_EXTRACT_PAID_INVOICES, payload: err?.response?.data?.error || "Erro inesperado!" });
  }
}

export function* getIncomeStatementAsync({ payload }) {
  yield put({ type: form_reports_types.LOADING_GET_INCOME_STATEMENT });

  try {
    const params = {
     start_date: payload[0].unix(),
     end_date: payload[1].unix(), 
    };

    let { data } = yield api.get(`financial/report/dre`, {
      params: params,
    });
     
    yield put({
      type: form_reports_types.SUCCESS_GET_INCOME_STATEMENT,
      payload: data,
    });
  } catch (err) {
    yield put({ type: form_reports_types.FAIL_GET_INCOME_STATEMENT, payload: err?.response?.data?.error || "Erro inesperado!" });
  }
}

export function* getAnalysisAsync({ payload }) {
  yield put({ type: form_reports_types.LOADING_GET_ANALYSIS });

  try {
    const params = {
      month: payload[0],
      year: payload[1], 
    };

    let { data } = yield api.get(`financial/report/analysis`, {
      params: params,
    }); 
 
    yield put({
      type: form_reports_types.SUCCESS_GET_ANALYSIS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: form_reports_types.FAIL_GET_ANALYSIS, payload: err?.response?.data?.error || "Erro inesperado!" });
  }
}

export function* getRevenueAggregatorAsync({ payload, category, date_type }) {
  yield put({ type: form_reports_types.LOADING_GET_REVENUE_BY_AGGREGATOR });

  try {
    const params = {
      start_date: payload[0].unix(),
      end_date: payload[1].unix(), 
      id: category,
      date_type: date_type
    }; 

    let { data } = yield api.get(`financial/report/revenue-by-aggregator`, {
      params: params,
    });
 
    yield put({
      type: form_reports_types.SUCCESS_GET_REVENUE_BY_AGGREGATOR,
      payload: data,
    });
  } catch (err) {
    yield put({ type: form_reports_types.FAIL_GET_REVENUE_BY_AGGREGATOR, payload: err?.response?.data?.error || "Erro inesperado!" });
  }
}

export function* getDailyRevenueAsync({ payload }) {
  yield put({ type: form_reports_types.LOADING_GET_DAILY_REVENUE });
 
  try { 
    const params = {
      start_date: payload[0].unix(),
      end_date: payload[1].unix(),  
    };  
    let { data } = yield api.get(`financial/report/daily-revenue`, {
      params: params,
    }); 
    yield put({
      type: form_reports_types.SUCCESS_GET_DAILY_REVENUE,
      payload: data,
    });
  } catch (err) {
    yield put({ type: form_reports_types.FAIL_GET_DAILY_REVENUE, payload: err?.response?.data?.error || "Erro inesperado!" });
  }
}

export function* getCashFlowAsync({ payload, account, projected }) {
  yield put({ type: form_reports_types.LOADING_GET_CASH_FLOW });
 
  try { 
    const params = {
      start_date: payload[0].unix(),
      end_date: payload[1].unix(),  
      bank_account: account,
      projected: projected
    };  
    let { data } = yield api.get(`financial/report/cash-flow`, {
      params: params,
    });  
    const items = data.items.map((item, index)=>{
      return {...item, key: index}
    })  
    data.items = items;
    yield put({
      type: form_reports_types.SUCCESS_GET_CASH_FLOW,
      payload: data,
    });
  } catch (err) {
    yield put({ type: form_reports_types.FAIL_GET_CASH_FLOW, payload: err?.response?.data?.error || "Erro inesperado!" });
  }
}

export function* getReports() {
  yield takeLatest(
    form_reports_types.GET_FINANCIAL_STATEMENT,
    getFinancialStatementAsync
  );
  yield takeLatest(
    form_reports_types.GET_RECONCILIATION_WITHDRAWALS,
    getReconciliationWithdrawalsAsync
  );
  yield takeLatest(
    form_reports_types.GET_EXTRACT_PAID_INVOICES,
    getExtractPaidInvoicesAsync
  );
  yield takeLatest(
    form_reports_types.GET_INCOME_STATEMENT,
    getIncomeStatementAsync
  ); 
  yield takeLatest(
    form_reports_types.GET_ANALYSIS,
    getAnalysisAsync
  ); 
  yield takeLatest(
    form_reports_types.GET_REVENUE_BY_AGGREGATOR,
    getRevenueAggregatorAsync
  ); 
  yield takeLatest(
    form_reports_types.GET_DAILY_REVENUE,
    getDailyRevenueAsync
  ); 
  yield takeLatest(
    form_reports_types.GET_CASH_FLOW,
    getCashFlowAsync
  ); 
}
