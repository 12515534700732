const crud_transactions_types = {
  POST_TRANSACTION: "POST_TRANSACTION",
  SUCCESS_POST_TRANSACTION: "SUCCESS_POST_TRANSACTION",
  FAIL_POST_TRANSACTION: "FAIL_POST_TRANSACTION",

  POST_CATEGORY: "POST_CATEGORY",
  SUCCESS_POST_CATEGORY: "SUCCESS_POST_CATEGORY",
  FAIL_POST_CATEGORY: "FAIL_POST_CATEGORY",

  POST_CENTER_COST: "POST_CENTER_COST",
  SUCCESS_POST_CENTER_COST: "SUCCESS_POST_CENTER_COST",
  FAIL_POST_CENTER_COST: "FAIL_POST_CENTER_COST",

  POST_BANK_ACCOUNT: "POST_BANK_ACCOUNT",
  SUCCESS_POST_BANK_ACCOUNT: "SUCCESS_POST_BANK_ACCOUNT",
  FAIL_POST_BANK_ACCOUNT: "FAIL_POST_BANK_ACCOUNT",

  POST_IMPORT_FINANCIAL: "POST_IMPORT_FINANCIAL",
  SUCCESS_POST_IMPORT_FINANCIAL: "SUCCESS_POST_IMPORT_FINANCIAL",
  FAIL_POST_IMPORT_FINANCIAL: "FAIL_POST_IMPORT_FINANCIAL",

  POST_IMPORT_FINANCIAL_URL: "POST_IMPORT_FINANCIAL_URL",
  SUCCESS_POST_IMPORT_FINANCIAL_URL: "SUCCESS_POST_IMPORT_FINANCIAL_URL",
  FAIL_POST_IMPORT_FINANCIAL_URL: "FAIL_POST_IMPORT_FINANCIAL_URL",

  POST_IMPORT_DOCUMENT_URL: "POST_IMPORT_DOCUMENT_URL",
  SUCCESS_POST_IMPORT_DOCUMENT_URL: "SUCCESS_POST_IMPORT_DOCUMENT_URL",
  FAIL_POST_IMPORT_DOCUMENT_URL: "FAIL_POST_IMPORT_DOCUMENT_URL",

  POST_BANK_TRANSFER: "POST_BANK_TRANSFER",
  SUCCESS_POST_BANK_TRANSFER: "SUCCESS_POST_BANK_TRANSFER",
  FAIL_POST_BANK_TRANSFER: "FAIL_POST_BANK_TRANSFER",

  POST_PAYMENT_METHOD: "POST_PAYMENT_METHOD",
  SUCCESS_POST_PAYMENT_METHOD: "SUCCESS_POST_PAYMENT_METHOD",
  FAIL_POST_PAYMENT_METHOD: "FAIL_POST_PAYMENT_METHOD",

  GET_TRANSACTIONS: "GET_TRANSACTIONS",
  LOADING_GET_TRANSACTIONS: "LOADING_GET_TRANSACTIONS",
  SUCCESS_GET_TRANSACTIONS: "SUCCESS_GET_TRANSACTIONS",
  FAIL_GET_TRANSACTIONS: "FAIL_GET_TRANSACTIONS",
  CLEAR_GET_TRANSACTIONS: "CLEAR_GET_TRANSACTIONS",

  GET_TRANSACTIONS_BY_ID: "GET_TRANSACTIONS_BY_ID",
  LOADING_GET_TRANSACTIONS_BY_ID: "LOADING_GET_TRANSACTIONS_BY_ID",
  SUCCESS_GET_TRANSACTIONS_BY_ID: "SUCCESS_GET_TRANSACTIONS_BY_ID",
  FAIL_GET_TRANSACTIONS_BY_ID: "FAIL_GET_TRANSACTIONS_BY_ID",
  CLEAR_GET_TRANSACTIONS_BY_ID: "CLEAR_GET_TRANSACTIONS_BY_ID",

  GET_CATEGORYS: "GET_CATEGORYS",
  LOADING_GET_CATEGORYS: "LOADING_GET_CATEGORYS",
  SUCCESS_GET_CATEGORYS: "SUCCESS_GET_CATEGORYS",
  FAIL_GET_CATEGORYS: "FAIL_GET_CATEGORYS",
  CLEAR_GET_CATEGORYS: "CLEAR_GET_CATEGORYS",

  GET_CENTER_COST: "GET_CENTER_COST",
  LOADING_GET_CENTER_COST: "LOADING_GET_CENTER_COST",
  SUCCESS_GET_CENTER_COST: "SUCCESS_GET_CENTER_COST",
  FAIL_GET_CENTER_COST: "FAIL_GET_CENTER_COST",
  CLEAR_GET_CENTER_COST: "CLEAR_GET_CENTER_COST",

  GET_BANK_ACCOUNT: "GET_BANK_ACCOUNT",
  LOADING_GET_BANK_ACCOUNT: "LOADING_GET_BANK_ACCOUNT",
  SUCCESS_GET_BANK_ACCOUNT: "SUCCESS_GET_BANK_ACCOUNT",
  FAIL_GET_BANK_ACCOUNT: "FAIL_GET_BANK_ACCOUNT",
  CLEAR_GET_BANK_ACCOUNT: "CLEAR_GET_BANK_ACCOUNT",

  GET_PAYMENT_METHOD: "GET_PAYMENT_METHOD",
  SUCCESS_GET_PAYMENT_METHOD: "SUCCESS_GET_PAYMENT_METHOD",
  FAIL_GET_PAYMENT_METHOD: "FAIL_GET_PAYMENT_METHOD",

  PATCH_TRANSACTION: "PATCH_TRANSACTION",
  SUCCESS_PATCH_TRANSACTION: "SUCCESS_PATCH_TRANSACTION",
  FAIL_PATCH_TRANSACTION: "FAIL_PATCH_TRANSACTION",

  PATCH_BANK_ACCOUNT: "PATCH_BANK_ACCOUNT",
  SUCCESS_PATCH_BANK_ACCOUNT: "SUCCESS_PATCH_BANK_ACCOUNT",
  FAIL_PATCH_BANK_ACCOUNT: "FAIL_PATCH_BANK_ACCOUNT",

  PATCH_BANK_TRANSFER: "PATCH_BANK_TRANSFER",
  SUCCESS_PATCH_BANK_TRANSFER: "SUCCESS_PATCH_BANK_TRANSFER",
  FAIL_PATCH_BANK_TRANSFER: "FAIL_PATCH_BANK_TRANSFER",
 
  PATCH_CATEGORY: "PATCH_CATEGORY",
  SUCCESS_PATCH_CATEGORY: "SUCCESS_PATCH_CATEGORY",
  FAIL_PATCH_CATEGORY: "FAIL_PATCH_CATEGORY",
 
  DEL_TRANSACTION: "DEL_TRANSACTION",
  SUCCESS_DEL_TRANSACTION: "SUCCESS_DEL_TRANSACTION",
  FAIL_DEL_TRANSACTION: "FAIL_DEL_TRANSACTION",

  DEL_CATEGORY: "DEL_CATEGORY",
  SUCCESS_DEL_CATEGORY: "SUCCESS_DEL_CATEGORY",
  FAIL_DEL_CATEGORY: "FAIL_DEL_CATEGORY",

  DEL_BANK_ACCOUNT: "DEL_BANK_ACCOUNT",
  SUCCESS_DEL_BANK_ACCOUNT: "SUCCESS_DEL_BANK_ACCOUNT",
  FAIL_DEL_BANK_ACCOUNT: "FAIL_DEL_BANK_ACCOUNT",

  DEL_CENTER_COST: "DEL_CENTER_COST",
  SUCCESS_DEL_CENTER_COST: "SUCCESS_DEL_CENTER_COST",
  FAIL_DEL_CENTER_COST: "FAIL_DEL_CENTER_COST",

  DEL_BANK_TRANSFER: "DEL_BANK_TRANSFER",
  SUCCESS_DEL_BANK_TRANSFER: "SUCCESS_DEL_BANK_TRANSFER",
  FAIL_DEL_BANK_TRANSFER: "FAIL_DEL_BANK_TRANSFER",

  DEL_PAYMENT_METHOD: "DEL_PAYMENT_METHOD",
  SUCCESS_DEL_PAYMENT_METHOD: "SUCCESS_DEL_PAYMENT_METHOD",
  FAIL_DEL_PAYMENT_METHOD: "FAIL_DEL_PAYMENT_METHOD",
};

export default crud_transactions_types;
