import styled, { keyframes } from "styled-components";
import Button from "core/components/button/button.component";

const ListBottomToTop = keyframes`
  from {
    margin-top: 80px;
  }

  to {
    margin-top: 10px;
  }
`;

export const ButtonNotification = styled(Button)`
  font-size: 10px !important;
  font-weight: 300;
`;

export const NotificationsList = styled.ul`
  width: 368px;
  height: 60vh;
  max-height: 60vh;
  overflow-y: auto;
  padding: 4px 16px 0px 24px;
  overflow-y: scroll;
  li:nth-child(${({ activeNotifications }) => activeNotifications[1] + 1}) {
    animation: ${({ activeNotifications }) =>
        activeNotifications[0] ? ListBottomToTop : "none"}
      0.4s;
  }

  @media only screen and (max-width: 650px) {
    width: 100vw;
  }
`;

export const Notification = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  padding-bottom: 10px;
  margin-top: 16px;
  width: 100%;
  height: auto;
`;

export const NotificationIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 20px;
`;

export const NotificationTexts = styled.div`
  display: flex;
  flex-flow: column;
  gap: 4px;
  width: 85%;
`;

export const NotificationTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-top: -5px;
  color: rgba(0, 0, 0, 0.9);
  p {
    white-space: normal;
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
`;

export const NotificationDescription = styled.div`
  width: 100%;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  margin-top: -20px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
`;

export const NotificationDate = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
`;

export const NotificationClose = styled.div`
  font-size: 17px;
  margin-top: -4px;
  color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;

export const NotificationButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const HeaderNotification = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 60px;
  padding: 16px 24px 16px 24px;
  button {
    width: 100%;
  }
`;

export const IconMenu = styled.div`
  @media only screen and (min-width: 1025px) {
    display: none;
  }
`;

export const FocusStyles = styled.button`
  all: unset;
  cursor: pointer;
  font-size: 20px;
  color: #808080;
  &:hover {
    opacity: 0.6;
  }
  &:focus {
    opacity: 0.6;
  }
`;

export const NotificationOthersItem = styled.div`
  cursor: pointer;
  font-size: 14px;
  color: #333333;
  &:hover {
    opacity: 0.6;
  }
  &:focus {
    opacity: 0.6;
  }
`;
