import { combineReducers } from 'redux'

//reducers
import user from './crud-form-customer/crud-form-customer.reducer'
import dashboard from './dashboard/dashboard.reducer'
import settings from './settings/settings.reducer'
import checkout from './form-checkout-panel/form-checkout-panel.reducer'

export const rootReducer = combineReducers({
	user,
	dashboard,
	settings,
	checkout
})

export default rootReducer