import { combineReducers } from "redux";

//reducers
import customer from "./crud-form-customers/crud-form-customers.reducer";

export const rootReducer = combineReducers({
  customer,
});

export default rootReducer;
