import { combineReducers } from "redux";

//reducers
import integration from "./crud-integration/crud-integration.reducer"; 
 
export const rootReducer = combineReducers({
  integration, 
});

export default rootReducer;
