import { connect } from "react-redux";

import Notifications from "./notifications.component"; 

import { getNotifications, postNotificationCallback, deleteNotifications } from "core/redux/notification/notification.action";
import { setModalProps, onCloseModal } from "core/redux/modal/modal.action";
import { updatePlan } from "modules/professional/redux/plan-panel/plan-panel.actions";

const mapStateToProps = state =>{
  return ({ 
      notifications_props: state.core.notification,
      plan_active: state.professional.plan.panel, 
  })
}

const mapDispatchToProps = {
  postNotificationCallback,
  getNotifications,
  deleteNotifications, 
  setModalProps,
  onCloseModal,
  updatePlan, 
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
