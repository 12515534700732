import crud_form_checkout_types from "./form-checkout-panel.types";

const INITIAL_STATE = {
  boleto: {
    loading: false,
    items: {},
    error: false,
  },
};

const dashboard_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case crud_form_checkout_types.GET_BOLETO:
      return {
        ...state,
        boleto: {
          loading: true,
          items: {},
          error: false,
        },
      };
    case crud_form_checkout_types.SUCCESS_GET_BOLETO:
      return {
        ...state,
        boleto: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };
    case crud_form_checkout_types.FAIL_GET_BOLETO:
      return {
        ...state,
        boleto: {
          loading: false,
          items: {},
          error: true,
        },
      };
    default:
      return state;
  }
};

export default dashboard_reducer;
