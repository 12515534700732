import React, { useState } from "react";
import { Checkbox, Form, message } from "antd";

import TermsUseHTML from "./terms-use/terms-use.component";
import PolicyPrivacyHTML from "./policy-privacy/policy-privacy.component";
import Notification from "core/components/notification/notification.component";
import Button from "core/components/button/button.component";

import {
  FormTermsUseStyle,
  BodyTermsStyle,
  ButtonContainer,
} from "./form-terms-use.styles";

const FormTermsUse = ({
  setModalProps = () => null,
  postRegisterCustomer = () => null,
  patchCustomerStatus = () => null,
  aceeptedPolices = () => null,
  getPlans = () => null,
  user = {},
  modal = {},
  form_register,
}) => {
  const [terms, setTerms] = useState(true);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [step, setStep] = useState(1);
  const params = new URLSearchParams(window.location.search);

  const onFinish = async (fields) => {
    if (step === 1) {
      if (fields.terms_use === false && fields.terms_use !== undefined) {
        message.error("Concorde com os termos de uso para continuar");
      } else {
        setModalProps({
          ...modal,
          title: "Política de privacidade",
        });
        setStep(2);
        setTerms(true);
        return;
      }
    }
    if (step === 2) {
      if (
        fields.privacy_policy === false &&
        fields.privacy_policy !== undefined
      ) {
        message.error("Concorde com as politica de privacidade para continuar");
        return;
      }
      if (modal?.isLogged === undefined) {
        setLoading(true);
        let promotion = {};
        if (params.get("promotion_token") !== null) {
          promotion = { promotion_token: params.get("promotion_token") };
        }
        const response = await postRegisterCustomer({
          ...form_register.getFieldsValue(),
          ...promotion,
          accepted_policies: true,
        });
        if (response === true) {
          getPlans();
          setLoading(false);
        } else {
          Notification("error", response);
          setLoading(false);
          return;
        }
      } else {
        setLoading(true);
        const response = await patchCustomerStatus(
          { accepted_policies: true },
          user.items.root_email
        );
        if (response === true) aceeptedPolices();
        else {
          Notification("error", response);
          setLoading(false);
          return;
        }
      }
      setModalProps({
        ...modal,
        visible: false,
      });
    } else {
      setTerms(true);
      setModalProps({
        ...modal,
        title: "Políticas de privacidade",
      });
    }
  };

  return (
    <FormTermsUseStyle>
      <Form
        onFinish={onFinish}
        form={form}
        initialValues={{
          terms_use: false,
          privacy_policy: false,
        }}
      >
        {step === 1 ? (
          <>
            <BodyTermsStyle
              key="1"
              id="scrollDiv"
              name="scrollDiv"
              onScroll={() => {
                const element = document.getElementById("scrollDiv");
                setTerms(true);
                if (
                  element.scrollTop +
                    Math.max(
                      document.documentElement.clientHeight,
                      window.innerHeight || 0
                    ) >=
                  element.scrollHeight - 1
                ) {
                  setTerms(false);
                }
              }}
            >
              <TermsUseHTML />
            </BodyTermsStyle>
            <div
              style={{
                marginTop: "10px",
                color: "#262626",
                opacity: terms === false ? 1 : 0.7,
              }}
            >
              <Form.Item name="terms_use" valuePropName="checked">
                <Checkbox disabled={terms}>
                  Li e concordo com os{" "}
                  <a
                    href="https://drive.google.com/file/d/1CCfbEn-adnohJRW5xOaP3ha63rLjHuQw/view"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Termos de Uso
                  </a>
                </Checkbox>
              </Form.Item>
            </div>
          </>
        ) : (
          <>
            <BodyTermsStyle
              key="2"
              id="scrollDiv1"
              name="scrollDiv1"
              onScroll={() => {
                const element = document.getElementById("scrollDiv1");
                setTerms(true);
                if (
                  element.scrollTop +
                    Math.max(
                      document.documentElement.clientHeight,
                      window.innerHeight || 0
                    ) >=
                  element.scrollHeight - 1
                ) {
                  setTerms(false);
                }
              }}
            >
              <PolicyPrivacyHTML />
            </BodyTermsStyle>
            <div
              style={{
                marginTop: "10px",
                color: "#262626",
                opacity: terms === false ? 1 : 0.7,
              }}
            >
              <Form.Item name="privacy_policy" valuePropName="checked">
                <Checkbox disabled={terms}>
                  Li e concordo com as{" "}
                  <a
                    href="https://drive.google.com/file/d/1loH7bYZoz33Z29__v8DAvZrqqFfphhqR/view"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Políticas de privacidade
                  </a>
                </Checkbox>
              </Form.Item>
            </div>
          </>
        )}

        <ButtonContainer>
          {step > 1 ? (
            <Button
              btntype="defaultOutlined"
              onClick={() => {
                setStep(step - 1);
              }}
            >
              Voltar
            </Button>
          ) : null}
          <Button loading={loading} btntype="secondary" htmlType="submit">
            {step > 1 ? "Aceitar" : "Proximo"}
          </Button>
        </ButtonContainer>
      </Form>
    </FormTermsUseStyle>
  );
};

export default FormTermsUse;
