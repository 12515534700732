import { combineReducers } from "redux";

//reducers
import reports from "./form-reports/form-reports.reducer";
import transactions from "./crud-transactions/crud-transactions.reducer"; 

export const rootReducer = combineReducers({
  reports,
  transactions, 
});

export default rootReducer;
