import crud_transactions_types from "./crud-transactions.types";

const INITIAL_STATE = { 
  table: {
    loading: false,
    items: [],
    error: false,
  },  
  categorys: {
    loading: false,
    items: [],
    error: false,
  },
  center_cost: {
    loading: false,
    items: [],
    error: false,
  },
  bank_account: {
    loading: false,
    items: [],
    error: false,
  }, 
  transactions_by_id: {
    loading: false,
    items: [],
    error: false,
  },  
  document: {
    loading: true,
    items: [],
    error: false,
  }, 
};

const crud_transactions_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case crud_transactions_types.LOADING_GET_TRANSACTIONS:
      return {
        ...state,
        table: {
          loading: true,
          items: [],
          error: false,
        },
      };

    case crud_transactions_types.SUCCESS_GET_TRANSACTIONS:
      return {
        ...state,
        table: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case crud_transactions_types.FAIL_GET_TRANSACTIONS:
      return {
        ...state,
        table: {
          loading: false,
          items: [],
          error: true,
        },
      }; 
    
    case crud_transactions_types.CLEAR_GET_TRANSACTIONS:
      return {
        ...state,
        table: {
          loading: false,
          items: [],
          error: false,
        },
      }; 
        
    case crud_transactions_types.LOADING_GET_CATEGORYS:
      return {
        ...state,
        categorys: {
          loading: true,
          items: [],
          error: false,
        },
      };

    case crud_transactions_types.SUCCESS_GET_CATEGORYS:
      return {
        ...state,
        categorys: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case crud_transactions_types.FAIL_GET_CATEGORYS:
      return {
        ...state,
        categorys: {
          loading: false,
          items: [],
          error: true,
        },
      }; 
     
    case crud_transactions_types.CLEAR_GET_CATEGORYS:
      return {
        ...state,
        categorys: {
          loading: false,
          items: [],
          error: false,
        },
    };     
        
    case crud_transactions_types.LOADING_GET_CENTER_COST:
      return {
        ...state,
        center_cost: {
          loading: true,
          items: [],
          error: false,
        },
      };

    case crud_transactions_types.SUCCESS_GET_CENTER_COST:
      return {
        ...state,
        center_cost: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case crud_transactions_types.FAIL_GET_CENTER_COST:
      return {
        ...state,
        center_cost: {
          loading: false,
          items: [],
          error: true,
        },
      }; 
     
    case crud_transactions_types.CLEAR_GET_CENTER_COST:
      return {
        ...state,
        center_cost: {
          loading: false,
          items: [],
          error: false,
        },
    };    
    
    case crud_transactions_types.LOADING_GET_BANK_ACCOUNT:
      return {
        ...state,
        bank_account: {
          loading: true,
          items: [],
          error: false,
        },
      };

    case crud_transactions_types.SUCCESS_GET_BANK_ACCOUNT:
      return {
        ...state,
        bank_account: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case crud_transactions_types.FAIL_GET_BANK_ACCOUNT:
      return {
        ...state,
        bank_account: {
          loading: false,
          items: [],
          error: true,
        },
      }; 
     
    case crud_transactions_types.CLEAR_GET_BANK_ACCOUNT:
      return {
        ...state,
        bank_account: {
          loading: false,
          items: [],
          error: false,
        },
    };    
    
    case crud_transactions_types.LOADING_GET_TRANSACTIONS_BY_ID:
      return {
        ...state,
        transactions_by_id: {
          loading: true,
          items: [],
          error: false,
        },
      };

    case crud_transactions_types.SUCCESS_GET_TRANSACTIONS_BY_ID:
      return {
        ...state,
        transactions_by_id: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case crud_transactions_types.FAIL_GET_TRANSACTIONS_BY_ID:
      return {
        ...state,
        transactions_by_id: {
          loading: false,
          items: [],
          error: true,
        },
      }; 

    case crud_transactions_types.CLEAR_GET_TRANSACTIONS_BY_ID:
      return {
        ...state,
        transactions_by_id: {
          loading: false,
          items: [],
          error: false,
        },
    }; 

    case crud_transactions_types.POST_IMPORT_DOCUMENT_URL:
      return {
        ...state,
        document: {
          loading: true,
          items: [],
          error: false,
        },
      };

    case crud_transactions_types.SUCCESS_POST_IMPORT_DOCUMENT_URL:
      return {
        ...state,
        document: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case crud_transactions_types.FAIL_POST_IMPORT_DOCUMENT_URL:
      return {
        ...state,
        document: {
          loading: false,
          items: [],
          error: true,
        },
      }; 
    default:
      return state;
  }
};

export default crud_transactions_reducer;
