const crud_form_checkout_types = {
  POST_NEW_CHECKOUT: "POST_NEW_CHECKOUT",
  SUCCESS_POST_NEW_CHECKOUT: "SUCCESS_POST_NEW_CHECKOUT",
  FAIL_POST_NEW_CHECKOUT: "FAIL_POST_NEW_CHECKOUT",
  GET_BOLETO: "GET_BOLETO",
  SUCCESS_GET_BOLETO: "SUCCESS_GET_BOLETO",
  FAIL_GET_BOLETO: "FAIL_GET_BOLETO"
};

export default crud_form_checkout_types;
