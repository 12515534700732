const crud_form_customer_types = {
  POST_NEW_CUSTOMER: "POST_NEW_CUSTOMER",
  SUCCESS_POST_NEW_CUSTOMER: "SUCCESS_POST_NEW_CUSTOMER",
  FAIL_POST_NEW_CUSTOMER: "FAIL_POST_NEW_CUSTOMER",
  POST_NEW_SUBACCOUNT: "POST_NEW_SUBACCOUNT",
  SUCCESS_POST_NEW_SUBACCOUNT: "SUCCESS_POST_NEW_SUBACCOUNT",
  FAIL_POST_NEW_SUBACCOUNT: "FAIL_POST_NEW_SUBACCOUNT",
  GET_AUTH_CUSTOMER: "GET_AUTH_CUSTOMER",
  LOADING_GET_AUTH_CUSTOMER: "LOADING_GET_AUTH_CUSTOMER",
  SUCCESS_GET_AUTH_CUSTOMER: "SUCCESS_GET_AUTH_CUSTOMER",
  FAIL_GET_AUTH_CUSTOMER: "FAIL_GET_AUTH_CUSTOMER",
  POST_RESET_CUSTOMER: "POST_RESET_CUSTOMER",
  SUCCESS_POST_RESET_CUSTOMER: "SUCCESS_POST_RESET_CUSTOMER",
  FAIL_POST_RESET_CUSTOMER: "FAIL_POST_RESET_CUSTOMER",
  POST_RESET_CALLBACK_CUSTOMER: "POST_RESET_CALLBACK_CUSTOMER",
  SUCCESS_POST_RESET_CALLBACK_CUSTOMER: "SUCCESS_POST_RESET_CALLBACK_CUSTOMER",
  FAIL_POST_RESET_CALLBACK_CUSTOMER: "FAIL_POST_RESET_CALLBACK_CUSTOMER",
  GET_DASHBOARD: "GET_DASHBOARD",
  SUCCESS_GET_DASHBOARD: "SUCCESS_GET_DASHBOARD",
  FAIL_GET_DASHBOARD: "FAIL_GET_DASHBOARD",
  LOGOUT_USER: "LOGOUT_USER",
  AUTH_SUB_USER: "AUTH_SUB_USER",
  SUCCESS_AUTH_SUB_USER: "SUCCESS_AUTH_SUB_USER",
  FAIL_AUTH_SUB_USER: "FAIL_AUTH_SUB_USER",
  REMOVE_SUB_USER: "REMOVE_SUB_USER",
  LOUGOUT_WITHOUT_TIME: "LOUGOUT_WITHOUT_TIME",
  SUCCESS_LOUGOUT_WITHOUT_TIME: "SUCCESS_LOUGOUT_WITHOUT_TIME",
  FAIL_LOUGOUT_WITHOUT_TIME: "FAIL_LOUGOUT_WITHOUT_TIME", 
  PATCH_CUSTOMER: "PATCH_CUSTOMER",
  SUCCESS_PATCH_CUSTOMER: "SUCCESS_PATCH_CUSTOMER",
  FAIL_PATCH_CUSTOMER: "FAIL_PATCH_CUSTOMER", 
  GET_USER_CODE: "GET_USER_CODE", 
  SUCCESS_GET_USER_CODE: "SUCCESS_GET_USER_CODE",
  FAIL_GET_USER_CODE: "FAIL_GET_USER_CODE",
  ACEEPTED_POLICES: "ACEEPTED_POLICES",
  GET_UPDATE_FLAG_USER: "GET_UPDATE_FLAG_USER", 
  SUCCESS_GET_UPDATE_FLAG_USER: "SUCCESS_GET_UPDATE_FLAG_USER",
  FAIL_GET_UPDATE_FLAG_USER: "FAIL_GET_UPDATE_FLAG_USER", 
  GET_CLIENT: "GET_CLIENT",
  SUCCESS_GET_CLIENT: "SUCCESS_GET_CLIENT",
  FAIL_GET_CLIENT: "FAIL_GET_CLIENT",
  AUTH_CLIENT_CODE: "AUTH_CLIENT_CODE",
  SUCCESS_AUTH_CLIENT_CODE: "SUCCESS_AUTH_CLIENT_CODE",
  FAIL_AUTH_CLIENT_CODE: "FAIL_AUTH_CLIENT_CODE",
};

export default crud_form_customer_types;
