const types = { 
    GET_CLIENT: "GET_CLIENT", 
    SUCCESS_GET_CLIENT: "SUCCESS_GET_CLIENT",
    FAIL_GET_CLIENT: "FAIL_GET_CLIENT", 
    DEL_CLIENT: "DEL_CLIENT",
    SUCCESS_DEL_CLIENT: "SUCCESS_DEL_CLIENT",
    FAIL_DEL_CLIENT: "FAIL_DEL_CLIENT",
  };
  
  export default types;
  