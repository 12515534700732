import React from "react";
import { Spin } from "antd";

const LoadingSpinner = ({style}) => { 
    
    return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...style
      }}
    >
      <Spin size="large" />
    </div>
  );
};

export default LoadingSpinner;

