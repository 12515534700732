import types from "./crud-integration.types";

const INITIAL_STATE = { 
  table: {
    loading: true,
    items: [],
    error: false,
  },   
};

const crud_integration_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_INTEGRATION:
      return {
        ...state,
        table: {
          loading: true,
          items: [],
          error: false,
        },
      };

    case types.SUCCESS_GET_INTEGRATION:
      return {
        ...state,
        table: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case types.FAIL_GET_INTEGRATION:
      return {
        ...state,
        table: {
          loading: false,
          items: [],
          error: true,
        },
      };  
    default:
      return state;
  }
};

export default crud_integration_reducer;
