import { takeLatest, put } from "redux-saga/effects";

import crud_card_types from "./crud-card.types";

import api from "core/services/api";

export function* getCardsAsync({ payload }) {
  yield put({ type: crud_card_types.LOADING_GET_CARD });

  try {
    const { data } = yield api.get("plan/card", payload, {
      headers: {
        "content-type": "application/json",
      },
    });

    data.map((item) => {
      item["key"] = item.id;
      item["visible"] = false;
      item["loading"] = false;
      return null;
    });

    yield put({
      type: crud_card_types.SUCCESS_GET_CARD,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: crud_card_types.FAIL_GET_CARD,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}

export function* getCardsByIdAsync(params) {
  yield put({ type: crud_card_types.LOADING_GET_BY_ID_CARD });

  try {
    const { data } = yield api.get(`plan/card/${params.payload}`, {
      headers: {
        "content-type": "application/json",
      },
    });

    yield put({
      type: crud_card_types.SUCCESS_GET_BY_ID_CARD,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: crud_card_types.FAIL_GET_BY_ID_CARD,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}

export function* getCards() {
  yield takeLatest(crud_card_types.GET_CARD, getCardsAsync);
  yield takeLatest(crud_card_types.GET_BY_ID_CARD, getCardsByIdAsync);
}
