const cep_types = {
    GET_CACHE: "GET_CACHE", 
    LOADING_GET_CACHE: "LOADING_GET_CACHE",
    SUCCESS_GET_CACHE: "SUCCESS_GET_CACHE",
    FAIL_GET_CACHE: "FAIL_GET_CACHE",
    
    POST_CACHE: "POST_CACHE", 
    SUCCESS_POST_CACHE: "SUCCESS_POST_CACHE",
    FAIL_POST_CACHE: "FAIL_POST_CACHE",
    
    LOGOUT_USER: "LOGOUT_USER"
};
  
export default cep_types;
  