import { takeLatest, put } from "redux-saga/effects";

import crud_form_customer_types from "./crud-form-customer.types";

import api from "core/services/api";

export function* authUserAsync({ payload }) {
  yield put({ type: crud_form_customer_types.LOADING_GET_AUTH_CUSTOMER });

  try {
    const { data } = yield api.post("login", payload, {
      headers: {
        "content-type": "application/json",
      },
    });

    yield put({
      type: crud_form_customer_types.SUCCESS_GET_AUTH_CUSTOMER,
      payload: data,
    });
  } catch (err) {
    yield put({ type: crud_form_customer_types.FAIL_GET_AUTH_CUSTOME, payload: err?.response?.data?.error || "Erro inesperado!", });
  }
}

export function* logoutUserAsync() {
  try {
    localStorage.setItem("fluxopay_sub_user_token", JSON.stringify(''));
    localStorage.setItem("fluxopay_user_token", JSON.stringify(''));
    localStorage.setItem("fluxopay_user_end_date", JSON.stringify(''));

    yield put({
      type: crud_form_customer_types.LOGOUT_USER
    });

  } catch (err) {
    yield put({ type: crud_form_customer_types.FAIL_LOUGOUT_WITHOUT_TIME, payload: err?.response?.data?.error || "Erro inesperado!", });
  }
}


export function* authUser() {
  yield takeLatest(crud_form_customer_types.GET_AUTH_CUSTOMER, authUserAsync);
  yield takeLatest(crud_form_customer_types.LOUGOUT_WITHOUT_TIME, logoutUserAsync);

}
