import { takeLatest, put } from "redux-saga/effects";

import form_logs_types from "./crud-table-logs.types";

import api from "core/services/api";

export function* getUserLogsAsync() {
  yield put({ type: form_logs_types.LOADING_GET_USER_LOGS });

  try {
    let { data } = yield api.get(`log`);
    data.map((item, index) => {
      item["key"] = index;
      return null;
    });
    yield put({
      type: form_logs_types.SUCCESS_GET_USER_LOGS,
      payload: data,
    });
  } catch (err) {
    yield put({ type: form_logs_types.FAIL_GET_USER_LOGS, payload: err?.response?.data?.error || "Erro inesperado!", });
  }
}

export function* getReports() {
  yield takeLatest(form_logs_types.GET_USER_LOGS, getUserLogsAsync);
}
