import styled, { css, keyframes } from "styled-components";
import { Button } from "antd";

const fadeIn = keyframes`
  from {
    background: rgba(51, 153, 255, 0.1);
    box-shadow: 0 10px 6px -6px #777;
  }

  to {
    background: rgba(51, 153, 255, 1);
    box-shadow: 0 0px 0px 0px #777;
  }
`;

const PrimaryButton = css`
  color: ${(props) => props.theme.secondary_font_color} !important;
  background: ${(props) => props.theme.primary_color} !important;
  border: none;
`;

const SecondaryButton = css`
  color: ${(props) => props.theme.secondary_font_color} !important;
  background: ${(props) => props.theme.secondary_color} !important;
  border: none;
`;

const ThirdButton = css`
  color: ${(props) => props.theme.secondary_font_color} !important;
  background: ${(props) => props.theme.third_color} !important;
  border: none;
`;

const FourthButton = css`
  color: ${(props) => props.theme.secondary_font_color} !important;
  background: ${(props) => props.theme.fourth_color} !important;
  border: none;
`;

const FiveButton = css`
  color: ${(props) => props.theme.secondary_font_color} !important;
  background: ${(props) => props.theme.five_color} !important;
  border: none;
`;

const DefaultButton = css` 
`;

const OutlinedPrimaryButton = css`
  color: ${(props) => props.theme.primary_color} !important;
  background: white !important;
  border: 1px solid ${(props) => props.theme.primary_color} !important ;
`;

const OutlinedSecondaryButton = css`
  color: ${(props) => props.theme.secondary_color} !important;
  background: white !important;
  border: 1px solid ${(props) => props.theme.secondary_color} !important ;
`;

const OutlinedThirdButton = css`
  color: ${(props) => props.theme.third_color} !important;
  background: white !important;
  border: 1px solid ${(props) => props.theme.third_color} !important ;
`;

const OutlinedFourthButton = css`
  color: ${(props) => props.theme.fourth_color} !important;
  background: white !important;
  border: 1px solid ${(props) => props.theme.fourth_color} !important ;
`;

const OutlinedFiveButton = css`
  color: ${(props) => props.theme.five_color}  !important;
  background: white !important;
  border: 1px solid ${(props) => props.theme.five_color} !important ;
`;

const OutlinedDefaultButton = css`
  color: #3399ff !important;
  background: white !important;
  border: 1px solid transparent !important;
  padding: 0px;
  height: auto;
`;

const FadeInButton = css` 
  color: white !important; 
  border: none !important ; 
  animation: ${fadeIn} 1s 0s both;
`;

const GradientButton = css`
  background: ${(props) => props.gradient} !important; 
  color: white !important; 
  border: none !important;
`;

export const ButtonContainer = styled(Button)`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.25px;
  font-size: 14px;
  line-height: 22px; 
  border-radius: 4px; 
  text-align: center; 
  text-transform:  ${(props) => props.texttransform? "none" : "lowercase"};  
  @media only screen and (max-width: 500px) {
    width: 100%;  
  }
  &:disabled {
    opacity: 0.7;
  } 

  &:hover{
    opacity: 0.7;
  }
  
  &:active{
    opacity: 0.5;
  }

  &:focus{
    opacity:  0.7;
  }

  span{
    &::first-letter {
      text-transform: uppercase;
    }
  }
  
  ${({ btntype }) => {
    if (btntype === "primary") {
      return PrimaryButton;
    }
    if (btntype === "secondary") {
      return SecondaryButton;
    }
    if (btntype === "third") {
      return ThirdButton;
    }
    if (btntype === "fourth") {
      return FourthButton;
    }
    if (btntype === "five") {
      return FiveButton;
    }
    if (btntype === "primaryOutlined") {
      return OutlinedPrimaryButton;
    }
    if (btntype === "secondaryOutlined") {
      return OutlinedSecondaryButton;
    }
    if (btntype === "thirdOutlined") {
      return OutlinedThirdButton;
    }
    if (btntype === "fourthOutlined") {
      return OutlinedFourthButton;
    }
    if (btntype === "fiveOutlined") {
      return OutlinedFiveButton;
    } 
    if (btntype === "defaultOutlined") {
      return OutlinedDefaultButton;
    }  
    if (btntype === "gradient") {
      return GradientButton;
    }
    if (btntype === "fadeIn") {
      return FadeInButton;
    }
    return DefaultButton;
  }}
`;
