const tour_types = {
    SET_TOUR_PROPS: "SET_TOUR_PROPS",
    SUCCESS_SET_TOUR_PROPS: "SUCCESS_SET_TOUR_PROPS",
    FAIL_SET_TOUR_PROPS: "FAIL_SET_TOUR_PROPS",
    CLOSE_TOUR: "CLOSE_TOUR",
    FAIL_CLOSE_TOUR: "FAIL_CLOSE_TOUR", 

    POST_TOUR: "POST_TOUR",
    SUCCESS_POST_TOUR: "SUCCESS_POST_TOUR",
    FAIL_POST_TOUR: "FAIL_POST_TOUR",

    GET_TOUR: "GET_TOUR",
    LOADING_GET_TOUR: "LOADING_GET_TOUR",
    SUCCESS_GET_TOUR: "SUCCESS_GET_TOUR",
    FAIL_GET_TOUR: "FAIL_GET_TOUR"
};
  
export default tour_types;
  