import form_invoices_types from "./form-invoices.types";

const INITIAL_STATE = {
  table: {
    loading: true,
    items: [],
    error: false,
  },
  edit: {
    loading: true,
    items: [],
    error: false,
  },
  table1: {
    loading: true,
    items: [],
    error: false,
  },
  customer: {
    loading: false,
    items: [],
    error: false,
  },
  qr_code: {
    loading: false,
    items: {},
    error: false,
  },
  calendary:{ 
    loading: false,
    items: [],
    error: false,
  }
};

const form_invoices_reducer = (state = INITIAL_STATE, action ) => {
  switch (action.type) {
    case form_invoices_types.LOADING_GET_INVOICES:
      return {
        ...state,
        table: {
          loading: true,
          items: [...state.table.items],
          error: false,
        },
      };

    case form_invoices_types.SUCCESS_GET_INVOICES:
      return {
        ...state,
        table: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case form_invoices_types.FAIL_GET_INVOICES:
      return {
        ...state,
        table: {
          loading: false,
          items: [],
          error: true,
        },
      };

    case form_invoices_types.LOADING_GET_INVOICES_BY_ID:
      return {
        ...state,
        table1: {
          loading: true,
          items: { ...state.table1.items },
          error: false,
        },
      };

    case form_invoices_types.SUCCESS_GET_INVOICES_BY_ID:
      return {
        ...state,
        table1: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case form_invoices_types.FAIL_GET_INVOICES_BY_ID:
      return {
        ...state,
        table1: {
          loading: false,
          items: "",
          error: true,
        },
      };
 
    case form_invoices_types.LOADING_GET_INVOICES_BY_CUSTOMER_ID:
      return {
        ...state,
        customer: {
          loading: true,
          items: [...state.edit.items],
          error: false,
        },
      };

    case form_invoices_types.SUCCESS_GET_INVOICES_BY_CUSTOMER_ID:
      return {
        ...state,
        customer: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case form_invoices_types.FAIL_GET_INVOICES_BY_CUSTOMER_ID:
      return {
        ...state,
        customer: {
          loading: false,
          items: [...state.edit.items],
          error: true,
        },
      };
    
      case form_invoices_types.GET_INVOICES_CALENDARY:
      return {
        ...state,
        calendary: {
          loading: true,
          items: [],
          error: false,
        },
      };

    case form_invoices_types.SUCCESS_GET_INVOICES_CALENDARY:
      return {
        ...state,
        calendary: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case form_invoices_types.FAIL_GET_INVOICES_CALENDARY:
      return {
        ...state,
        calendary: {
          loading: false,
          items: [],
          error: true,
        },
      };
    default:
      return state;
  }
};

export default form_invoices_reducer;
