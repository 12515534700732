import drawer_types from "./drawer.types";

export const setDrawerProps = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: drawer_types.SET_DRAWER_PROPS,
    });

    dispatch({
      type: drawer_types.SUCCESS_SET_DRAWER_PROPS,
      payload,
    });
  } catch (err) {
    dispatch({
      type: drawer_types.FAIL_SET_DRAWER_PROPS,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
};

export const onCloseDrawer = () => async (dispatch) => {
  try {
    dispatch({
      type: drawer_types.CLOSE_DRAWER,
    });
  } catch (err) {
    dispatch({
      type: drawer_types.FAIL_CLOSE_DRAWER,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
};
