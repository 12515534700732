import { all, call } from "redux-saga/effects";

import { getBilling } from "./billing-table/billing.sagas"; 
import { getCards } from "./crud-card/crud-card.sagas"; 
import { getPlans } from "./plan-panel/plan-panel.sagas"; 

export default function* rootSaga() {
  yield all([
    call(getBilling),
    call(getCards),
    call(getPlans),
  ]
  );
}
