import { takeLatest, put } from "redux-saga/effects"; 
import fluxopayAPI from "core/services/api";
import tour_types from "./tour.types";

export function* getTourAsync() {
  yield put({ type: tour_types.LOADING_GET_TOUR });

  try { 
    const { data } = yield fluxopayAPI.get(`onboarding`); 
    yield put({
      type: tour_types.SUCCESS_GET_TOUR,
      payload: data,
    });
  } catch(err) {
    yield put({
      type: tour_types.FAIL_GET_TOUR,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}

export function* getTour() {
  yield takeLatest(tour_types.GET_TOUR, getTourAsync);
}
