import { combineReducers } from "redux";

//reducers
import negociations from "./negociations-panel/negociations-panel.reducer";
 
export const rootReducer = combineReducers({
  negociations,
});

export default rootReducer;
