import crud_form_customer_types from "./crud-form-customer.types";

const INITIAL_STATE = {
  loading: true,
  items: {},
  error: false,
  is_on: false,
  sub_user: {},
};

const crud_form_customer_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case crud_form_customer_types.LOADING_GET_AUTH_CUSTOMER:
      return {
        ...state,
        loading: true,
        items: {},
        error: false,
        is_on: false,
      };

    case crud_form_customer_types.SUCCESS_GET_AUTH_CUSTOMER:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: false,
        is_on: true,
      };

    case crud_form_customer_types.FAIL_GET_AUTH_CUSTOMER:
      return {
        ...state,
        loading: false,
        items: { ...state.items },
        error: true,
        is_on: false,
      };
 

    case crud_form_customer_types.FAIL_POST_NEW_CUSTOMER:
      return {
        ...state,
        loading: false,
        items: { ...state.items },
        error: true,
        is_on: false,
      };

    case crud_form_customer_types.REMOVE_SUB_USER:
      return {
        ...state,
        sub_user: {},
      };

    case crud_form_customer_types.AUTH_SUB_USER:
      return {
        ...state,
        sub_user: {
          loading: true,
          items: {},
          error: false,
        },
      };

    case crud_form_customer_types.SUCCESS_AUTH_SUB_USER:
      return {
        ...state,
        sub_user: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case crud_form_customer_types.FAIL_AUTH_SUB_USER:
      return {
        ...state,
        sub_user: {},
      };

    case crud_form_customer_types.ACEEPTED_POLICES:
      return {
        ...state,
        loading: false,
        items: {
          ...state.items,
          ...action.payload,
        },
        error: false,
        is_on: true,
      };

    case crud_form_customer_types.LOGOUT_USER:
      return {
        loading: true,
        items: {},
        error: false,
        is_on: false,
        sub_user: {},
      };

    default:
      return state;
  }
};

export default crud_form_customer_reducer;
