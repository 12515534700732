import { combineReducers } from "redux";

//reducers
import form_massified from "./form-massified/form-massified.reducer";
import form_invoices from "./form-invoices/form-invoices.reducer";

export const rootReducer = combineReducers({
  form_massified,
  form_invoices,
});

export default rootReducer;
