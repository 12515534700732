const settings_types = {
  GET_ROOT_FEE: "GET_ROOT_FEE",
  LOADING_GET_ROOT_FEE: "LOADING_GET_ROOT_FEE",
  SUCCESS_GET_ROOT_FEE: "SUCCESS_GET_ROOT_FEE",
  FAIL_GET_ROOT_FEE: "FAIL_GET_ROOT_FEE",
  GET_DOCUMENT_IMPORT_URL: "GET_DOCUMENT_IMPORT_URL",
  LOADING_GET_DOCUMENT_IMPORT_URL: "LOADING_GET_DOCUMENT_IMPORT_URL",
  SUCCESS_GET_DOCUMENT_IMPORT_URL: "SUCCESS_GET_DOCUMENT_IMPORT_URL",
  FAIL_GET_DOCUMENT_IMPORT_URL: "FAIL_GET_DOCUMENT_IMPORT_URL",
  GET_BANK_STATEMENT: "GET_BANK_STATEMENT",
  LOADING_GET_BANK_STATEMENT: "LOADING_GET_BANK_STATEMENT",
  SUCCESS_GET_BANK_STATEMENT: "SUCCESS_GET_BANK_STATEMENT",
  FAIL_GET_BANK_STATEMENT: "FAIL_GET_BANK_STATEMENT",

  GET_CLEAR_ACCOUNT: "GET_CLEAR_ACCOUNT",
  SUCCESS_GET_CLEAR_ACCOUNT: "SUCCESS_GET_CLEAR_ACCOUNT",
  FAIL_GET_CLEAR_ACCOUNT: "FAIL_GET_CLEAR_ACCOUNT",

  POST_CLEAR_ACCOUNT: "POST_CLEAR_ACCOUNT",
  SUCCESS_POST_CLEAR_ACCOUNT: "SUCCESS_POST_CLEAR_ACCOUNT",
  FAIL_POST_CLEAR_ACCOUNT: "FAIL_POST_CLEAR_ACCOUNT",

  GET_SOCIAL_CONTRACT: "GET_SOCIAL_CONTRACT",
  LOADING_GET_SOCIAL_CONTRACT: "LOADING_GET_SOCIAL_CONTRACT",
  SUCCESS_GET_SOCIAL_CONTRACT: "SUCCESS_GET_SOCIAL_CONTRACT",
  FAIL_GET_SOCIAL_CONTRACT: "FAIL_GET_SOCIAL_CONTRACT",
  PATCH_ROOT_FEE: "PATCH_ROOT_FEE",
  SUCCESS_PATCH_ROOT_FEE: "SUCCESS_PATCH_ROOT_FEE",
  FAIL_PATCH_ROOT_FEE: "FAIL_PATCH_ROOT_FEE",
  PATCH_ROOT_FINANCIAL: "PATCH_ROOT_FINANCIAL",
  SUCCESS_PATCH_ROOT_FINANCIAL: "SUCCESS_PATCH_ROOT_FINANCIAL",
  FAIL_PATCH_ROOT_FINANCIAL: "FAIL_PATCH_ROOT_FINANCIAL",
  POST_SETTINGS_IMPORT_URL: "POST_SETTINGS_IMPORT_URL",
  SUCCESS_POST_SETTINGS_IMPORT_URL: "SUCCESS_POST_SETTINGS_IMPORT_URL",
  FAIL_POST_SETTINGS_IMPORT_URL: "FAIL_POST_SETTINGS_IMPORT_URL",
  PATCH_ROOT_IMAGE: "PATCH_ROOT_IMAGE",
  SUCCESS_PATCH_ROOT_IMAGE: "SUCCESS_PATCH_ROOT_IMAGE",
  FAIL_PATCH_ROOT_IMAGE: "FAIL_PATCH_ROOT_IMAGE",
  POST_REVIEWFEE: "POST_REVIEWFEE",
  SUCCESS_POST_REVIEWFEE: "SUCCESS_POST_REVIEWFEE",
  FAIL_POST_REVIEWFEE: "FAIL_POST_REVIEWFEE",
  CLEAR_ROOT_FEE: "CLEAR_ROOT_FEE",
};

export default settings_types;
