import types from "./client-panel.types";

const INITIAL_STATE = {
  panel: {
    loading: true,
    items: [],
    error: false,
  }, 
};

const crud_form_customer_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_CLIENT:
      return {
        panel: {
          loading: true,
          items: [],
          error: false,
        }, 
      };

    case types.SUCCESS_GET_CLIENT:
      return {
        panel: {
          loading: false,
          items: action.payload,
          error: false,
        }, 
      };

    case types.FAIL_GET_CLIENT:
      return {
        panel: {
          loading: false,
          items: [],
          error: true,
        }, 
      };
  
    default:
      return state;
  }
};

export default crud_form_customer_reducer;
