import modal_types from "./modal.types";
 
const INITIAL_STATE = {
  visible: false
};

const modal_reducer = (state = INITIAL_STATE, action) => {
  
  switch (action.type) {
    case modal_types.SET_MODAL_PROPS:
      return {
        ...state, 
      };

    case modal_types.SUCCESS_SET_MODAL_PROPS:
      return {
        ...state,
        ...action.payload
    };

    case modal_types.FAIL_SET_MODAL_PROPS:
      return {
        ...INITIAL_STATE
    };

    case modal_types.CLOSE_MODAL:
      return {
        ...state,
        visible: false
      };

    case modal_types.FAIL_CLOSE_MODAL:
      return {
        ...state,
        visible: false
      }; 

    default:
      return state;
  }
};

export default modal_reducer;