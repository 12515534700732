const crud_form_custumers_types = {
  POST_NEW_CUSTUMER: "POST_NEW_CUSTUMER",
  SUCCESS_POST_NEW_CUSTUMER: "SUCCESS_POST_NEW_CUSTUMER",
  FAIL_POST_NEW_CUSTUMER: "FAIL_POST_NEW_CUSTUMER",
  GET_CUSTOMER: "GET_CUSTOMER",
  LOADING_GET_CUSTOMER: "LOADING_GET_CUSTOMER",
  SUCCESS_GET_CUSTOMER: "SUCCESS_GET_CUSTOMER",
  FAIL_GET_CUSTOMER: "FAIL_GET_CUSTOMER",
  GET_BY_ID_CUSTOMER: "GET_BY_ID_CUSTOMER",
  LOADING_GET_BY_ID_CUSTOMER: "LOADING_GET_BY_ID_CUSTOMER",
  SUCCESS_GET_BY_ID_CUSTOMER: "SUCCESS_GET_BY_ID_CUSTOMER",
  FAIL_GET_BY_ID_CUSTOMER: "FAIL_GET_BY_ID_CUSTOMER",
  PUT_CUSTOMER: "PUT_CUSTOMER",
  SUCCESS_PUT_CUSTOMER: "SUCCESS_PUT_CUSTOMER",
  FAIL_PUT_CUSTOMER: "FAIL_PUT_CUSTOMER",
  DEL_CUSTOMER: "DEL_CUSTOMER",
  SUCCESS_DEL_CUSTOMER: "SUCCESS_DEL_CUSTOMER",
  FAIL_DEL_CUSTOMER: "FAIL_DEL_CUSTOMER",
  GET_CUSTOMER_EXPORT: "GET_CUSTOMER_EXPORT",
  SUCCESS_GET_CUSTOMER_EXPORT: "SUCCESS_GET_CUSTOMER_EXPORT",
  FAIL_GET_CUSTOMER_EXPORT: "FAIL_GET_CUSTOMER_EXPORT",
  GET_CUSTOMER_IMPORT: "GET_CUSTOMER_IMPORT",
  SUCCESS_GET_CUSTOMER_IMPORT: "SUCCESS_GET_CUSTOMER_IMPORT",
  FAIL_GET_CUSTOMER_IMPORT: "FAIL_GET_CUSTOMER_IMPORT",
  CLEAR_GET_CUSTOMER: "CLEAR_GET_CUSTOMER"
};

export default crud_form_custumers_types;
