const crud_form_massified_types = {
  GET_INVOICES: "GET_INVOICES",
  LOADING_GET_INVOICES: "LOADING_GET_INVOICES",
  SUCCESS_GET_INVOICES: "SUCCESS_GET_INVOICES",
  FAIL_GET_INVOICES: "FAIL_GET_INVOICES",
  GET_INVOICES_BY_ID: "GET_INVOICES_BY_ID",
  LOADING_GET_INVOICES_BY_ID: "LOADING_GET_INVOICES_BY_ID",
  SUCCESS_GET_INVOICES_BY_ID: "SUCCESS_GET_INVOICES_BY_ID",
  FAIL_GET_INVOICES_BY_ID: "FAIL_GET_INVOICES_BY_ID",
  GET_INVOICES_BY_CUSTOMER_ID: "GET_INVOICES_BY_CUSTOMER_ID",
  LOADING_GET_INVOICES_BY_CUSTOMER_ID: "LOADING_GET_INVOICES_BY_CUSTOMER_ID",
  SUCCESS_GET_INVOICES_BY_CUSTOMER_ID: "SUCCESS_GET_INVOICES_BY_CUSTOMER_ID",
  FAIL_GET_INVOICES_BY_CUSTOMER_ID: "FAIL_GET_INVOICES_BY_CUSTOMER_ID", 
  POST_NEW_INVOICES: "POST_NEW_INVOICES",
  SUCCESS_POST_NEW_INVOICES: "SUCCESS_POST_NEW_INVOICES",
  FAIL_POST_NEW_INVOICES: "FAIL_POST_NEW_INVOICES",
  DEL_INVOICES: "DEL_INVOICES",
  SUCCESS_DEL_INVOICES: "SUCCESS_DEL_INVOICES",
  FAIL_DEL_INVOICES: "FAIL_DEL_INVOICES",
  POST_SEND_EMAIL: "POST_SEND_EMAIL",
  SUCCESS_POST_SEND_EMAIL: "SUCCESS_POST_SEND_EMAIL",
  FAIL_POST_SEND_EMAIL: "FAIL_POST_SEND_EMAIL", 
  GET_INVOICES_CALENDARY: "GET_INVOICES_CALENDARY", 
  SUCCESS_GET_INVOICES_CALENDARY: "SUCCESS_GET_INVOICES_CALENDARY",
  FAIL_GET_INVOICES_CALENDARY: "FAIL_GET_INVOICES_CALENDARY",
};

export default crud_form_massified_types;
