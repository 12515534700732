const GlobalTheme = {
  primary_color: "#5aac44",
  secondary_color: "#3399ff",
  third_color: "#FF861C",
  fourth_color: "#F65314",
  five_color: "#8c8c8c",
  default_color: "#EFF1F5",

  short_spacing: "4px",
  medium_spacing: "8px",
  long_spacing: "12px",
  longest_spacing: "16px",
  max_spacing: "24px",
  ultra_max_spacing: "32px",

  primary_font_color: "#191919",
  secondary_font_color: "#FFFFFF",
  third_font_color: "#A0A8BA", 
  fourth_font_color: "rgb(102, 204, 0)",
  five_font_color: "rgb(246, 83, 20)",
  default_font_color: "rgb(24,144,255)",
};

export default GlobalTheme;
