import styled, {keyframes} from "styled-components";
import {Link} from "react-router-dom"; 
import { Drawer } from 'antd' 

const fadeIn = keyframes`
  from {
    right: 10px
  }

  to {
    right: -160px;
  }
`;

const fadeOut = keyframes`
  from {
    right: -160px
  }

  to {
    right: 10px;
  }
`;

export const MenuStyle = styled.div`
  width: 100% !important; 
  & .ant-layout.ant-layout-has-sider > .ant-layout {
    overflow-x: none !important;
  }

  & .ant-layout {
    background-color: #f5f7f9 !important;
  }

  & .ant-layout-sider {
    @media only screen and (max-width: 1025px) {
      display: none;
    }
  }

  main {
    @media only screen and (max-width: 1025px) {
      padding: 5px !important;
    }
  
  }

  & .ant-drawer .ant-drawer-open .ant-drawer-left {
       display: none !important  ;
    
  }

  & .ant-drawer-mask{
    display: none !important  ;
  }
 

`;
export const ContainerButtonStyles = styled.div` 
  width: 60px; 
  cursor: pointer;
  &:hover{
    opacity:0.7;
  }
`; 

export const FocusStyles = styled.button`
  all: unset; 
  cursor: pointer; 
  font-size: 20px;
  color: #808080; 
  &:hover {
    opacity: 0.6;
  }
  &:focus{
    opacity: 0.6;
  }
`; 

export const PopOverItem = styled(Link)` 
  color: rgba(0, 0, 0, 0.65);
  &:hover {
    opacity: 0.6;
    color: rgba(0, 0, 0, 0.65) !important;
  }
`;

export const HeaderStyleContent = styled.div`
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100%;    
  padding-right: 20px;
  height: 60px;

`

export const HeaderStyleRight = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
`

export const CustomDrawer = styled(Drawer)`
 

  & .ant-drawer-header{
    background: #001529;
    border: none;
    & .ant-drawer-title{
      color: white !important;
    }
    

    & .ant-drawer-close{
      color: white !important;
    }
  }

  & .ant-drawer-body{
    background: #001529;
    color: white !important;
    padding: 0px !important;
  }

  @media only screen and (min-width: 1025px) {
    display: none;
  }
`

export const LogoImage = styled.div`
  width: 150px;
  height:  45px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  border-radius: 3px;
`

export const FormRegisterLogo = styled.div`
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: ${({ imageUrl }) => `url(${imageUrl})`};
    max-width: 135px;
    max-height: 37px;
` 

export const IconMenu =  styled.div`
  @media only screen and (min-width: 1025px) {
    display: none;
  }
`


export const ButtonApp =  styled.a` 
display: flex;
align-items: center; 
@media only screen and (max-width: 500px) { 
  z-index: 99999;
  position: fixed;
  bottom: 10px;
  right: 0px;
  animation:  ${({ scroll }) => scroll === false? fadeIn : fadeOut }   0.5s both;
}
`

export const Logo = styled.div`
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: ${({ imageUrl }) => `url(${imageUrl})`};
    min-width: 135px;
    min-height: 37px;
` 
