import { takeLatest, put } from "redux-saga/effects";

import crud_form_operator_types from "./crud-form-operators.types";

import api from "core/services/api";

export function* getOperatorsAsync({ payload }) {
  yield put({ type: crud_form_operator_types.LOADING_GET_OPERATOR });

  try {
    const { data } = yield api.get("user", payload, {
      headers: {
        "content-type": "application/json",
      },
    });

    data.map((item) => {
      item["key"] = item.id;
      return null
    });

    yield put({
      type: crud_form_operator_types.SUCCESS_GET_OPERATOR,
      payload: data,
    });
  } catch (err) {
    yield put({ type: crud_form_operator_types.FAIL_GET_OPERATOR, payload: err?.response?.data?.error || "Erro inesperado!" });
  }
}

export function* getOperatorsByIdAsync(params) {
  yield put({ type: crud_form_operator_types.LOADING_GET_BY_ID_OPERATOR });

  try {
    const { data } = yield api.get(`user/${params.payload}`, {
      headers: {
        "content-type": "application/json",
      },
    });

    yield put({
      type: crud_form_operator_types.SUCCESS_GET_BY_ID_OPERATOR,
      payload: data,
    });
  } catch (err) {
    yield put({ type: crud_form_operator_types.FAIL_GET_BY_ID_OPERATOR, payload: err?.response?.data?.error || "Erro inesperado!" });
  }
}

export function* getOperators() {
  yield takeLatest(crud_form_operator_types.GET_OPERATOR, getOperatorsAsync);
  yield takeLatest(
    crud_form_operator_types.GET_BY_ID_OPERATOR,
    getOperatorsByIdAsync
  );
}
