const form_reports_types = {
  GET_FINANCIAL_STATEMENT: "GET_FINANCIAL_STATEMENT",
  LOADING_GET_FINANCIAL_STATEMENT: "LOADING_GET_FINANCIAL_STATEMENT",
  SUCCESS_GET_FINANCIAL_STATEMENT: "SUCCESS_GET_FINANCIAL_STATEMENT",
  FAIL_GET_FINANCIAL_STATEMENT: "FAIL_GET_FINANCIAL_STATEMENT",

  GET_RECONCILIATION_WITHDRAWALS: "GET_RECONCILIATION_WITHDRAWALS",
  LOADING_GET_RECONCILIATION_WITHDRAWALS:
    "LOADING_GET_RECONCILIATION_WITHDRAWALS",
  SUCCESS_GET_RECONCILIATION_WITHDRAWALS:
    "SUCCESS_GET_RECONCILIATION_WITHDRAWALS",
  FAIL_GET_RECONCILIATION_WITHDRAWALS: "FAIL_GET_RECONCILIATION_WITHDRAWALS",

  GET_DAILY_REVENUE: "GET_DAILY_REVENUE",
  LOADING_GET_DAILY_REVENUE: "LOADING_GET_DAILY_REVENUE",
  SUCCESS_GET_DAILY_REVENUE: "SUCCESS_GET_DAILY_REVENUE",
  FAIL_GET_DAILY_REVENUE: "FAIL_GET_DAILY_REVENUE",  

  GET_EXTRACT_PAID_INVOICES: "GET_EXTRACT_PAID_INVOICES",
  LOADING_GET_EXTRACT_PAID_INVOICES: "LOADING_GET_EXTRACT_PAID_INVOICES",
  SUCCESS_GET_EXTRACT_PAID_INVOICES: "SUCCESS_GET_EXTRACT_PAID_INVOICES",
  FAIL_GET_EXTRACT_PAID_INVOICES: "FAIL_GET_EXTRACT_PAID_INVOICES",
  
  GET_INCOME_STATEMENT: "GET_INCOME_STATEMENT",
  LOADING_GET_INCOME_STATEMENT: "LOADING_GET_INCOME_STATEMENT",
  SUCCESS_GET_INCOME_STATEMENT: "SUCCESS_GET_INCOME_STATEMENT",
  FAIL_GET_INCOME_STATEMENT: "FAIL_GET_INCOME_STATEMENT", 
  
  GET_ANALYSIS: "GET_ANALYSIS",
  LOADING_GET_ANALYSIS: "LOADING_GET_ANALYSIS",
  SUCCESS_GET_ANALYSIS: "SUCCESS_GET_ANALYSIS",
  FAIL_GET_ANALYSIS: "FAIL_GET_ANALYSIS", 
  
  GET_REVENUE_BY_AGGREGATOR: "GET_REVENUE_BY_AGGREGATOR",
  LOADING_GET_REVENUE_BY_AGGREGATOR: "LOADING_GET_REVENUE_BY_AGGREGATOR",
  SUCCESS_GET_REVENUE_BY_AGGREGATOR: "SUCCESS_GET_REVENUE_BY_AGGREGATOR",
  FAIL_GET_REVENUE_BY_AGGREGATOR: "FAIL_GET_REVENUE_BY_AGGREGATOR",
  
  GET_CASH_FLOW: "GET_CASH_FLOW",
  LOADING_GET_CASH_FLOW: "LOADING_GET_CASH_FLOW",
  SUCCESS_GET_CASH_FLOW: "SUCCESS_GET_CASH_FLOW",
  FAIL_GET_CASH_FLOW: "FAIL_GET_CASH_FLOW",  
  
  GET_INCOME_STATEMENT_ADVANCED: "GET_INCOME_STATEMENT_ADVANCED", 
  SUCCESS_GET_INCOME_STATEMENT_ADVANCED: "SUCCESS_GET_INCOME_STATEMENT_ADVANCED",
  FAIL_GET_INCOME_STATEMENT_ADVANCED: "FAIL_GET_INCOME_STATEMENT_ADVANCED",
  
};

export default form_reports_types;
