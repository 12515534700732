import { takeLatest, put } from "redux-saga/effects";

import crud_form_customer_types from "./crud-form-customers.types";

import api from "core/services/api";

export function* getCustomerAsync({ payload }) {
  yield put({ type: crud_form_customer_types.LOADING_GET_CUSTOMER });

  try {
    const { data } = yield api.get("client", payload, {
      headers: {
        "content-type": "application/json",
      },
    });

    data.map((item) => {
     item["key"] = item.id;
     return null
    });

    yield put({
      type: crud_form_customer_types.SUCCESS_GET_CUSTOMER,
      payload: data,
    });
  } catch (err) {
    yield put({ type: crud_form_customer_types.FAIL_GET_CUSTOMER, payload: err?.response?.data?.error || "Erro inesperado!", });
  }
}

export function* getCustomerByIdAsync(params) {
  yield put({ type: crud_form_customer_types.LOADING_GET_BY_ID_CUSTOMER });

  try {
    const { data } = yield api.get(`client/${params.payload}`, {
      headers: {
        "content-type": "application/json",
      },
    });

    const p1 = data["telephone"];
    const p2 = data["accountant_cell_phone"];
    const p3 = data["cell_phone"];
    const p4 = data["accountant_telephone"];

    if(data['telephone'])
      data[
        "telephone"
      ] = `(${p1[0]}${p1[1]}) ${p1[2]}${p1[3]}${p1[4]}${p1[5]}-${p1[6]}${p1[7]}${p1[8]}${p1[9]}`;

      
    if(data['accountant_cell_phone'])
      data[
        "accountant_cell_phone"
      ] = `(${p2[0]}${p2[1]}) ${p2[2]}${p2[3]}${p2[4]}${p2[5]}${p2[6]}-${p2[7]}${p2[8]}${p2[9]}${p2[10]}`;

    if(data['cell_phone'])
      data[
        "cell_phone"
      ] = `(${p3[0]}${p3[1]}) ${p3[2]}${p3[3]}${p3[4]}${p3[5]}${p3[6]}-${p3[7]}${p3[8]}${p3[9]}${p3[10]}`;
      
    if(data['accountant_telephone'])
      data[
        "accountant_telephone"
      ] = `(${p4[0]}${p4[1]}) ${p4[2]}${p4[3]}${p4[4]}${p4[5]}-${p4[6]}${p4[7]}${p4[8]}${p4[9]}`;

    yield put({
      type: crud_form_customer_types.SUCCESS_GET_BY_ID_CUSTOMER,
      payload: data,
    });
  } catch (err) {
 
    yield put({ type: crud_form_customer_types.FAIL_GET_BY_ID_CUSTOMER, payload: err?.response?.data?.error || "Erro inesperado!", });
  }
}

export function* getCustomers() {
  yield takeLatest(crud_form_customer_types.GET_CUSTOMER, getCustomerAsync);
  yield takeLatest(
    crud_form_customer_types.GET_BY_ID_CUSTOMER,
    getCustomerByIdAsync
  );
}
