const ErrorReport = (state, action, Sentry) => { 
  if (action.type.toString().includes("FAIL")) {
    Sentry.configureScope((scope) => {
      scope.setLevel("Error").setExtra("store", state).setTag(action.type);
      if (state?.default?.user?.is_on) {
        scope.setUser({ email: state?.default?.user?.items?.email });
      }
    });
    Sentry.captureException(new Error(action.payload === "Erro inesperado!"? action.type : action.payload));
  }
};

export default ErrorReport;
