const types = {
    POST_INTEGRATION: "POST_INTEGRATION",
    SUCCESS_POST_INTEGRATION: "SUCCESS_POST_INTEGRATION",
    FAIL_POST_INTEGRATION: "FAIL_POST_INTEGRATION",

    GET_INTEGRATION: "GET_INTEGRATION",
    SUCCESS_GET_INTEGRATION: "SUCCESS_GET_INTEGRATION",
    FAIL_GET_INTEGRATION: "FAIL_GET_INTEGRATION"
};
  
export default types;
  