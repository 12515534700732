import React, { useState } from "react";
import { Form } from "antd";
import Button from "core/components/button/button.component";

import Notifications from "core/components/notification/notification.component";

import { UpdatePassordStyles } from "./uptade-password-form.styles";
import { InputPassword } from "core/components/input-password/input-password.component";

const UpdatePasswordForm = ({
  onClose = () => null,
  patchCustomerStatus = () => null,
  email = "",
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false); 
  const validateMessages = {
    required: "campo obrigatório!",
    types: {
      email: "campo tipo e-mail!",
      number: "campo tipo numero",
    },
  };

  console.log("aqui",email);
  const onFinish = async (fields) => {
    setLoading(true);
    delete fields.confirm_password;
    const response = await patchCustomerStatus(fields, email);
    if (response === true) {
      Notifications("success", "Senha salva com sucesso!");
      onClose();
    } else Notifications("error", response);
    setLoading(false);
  };

  return (
    <UpdatePassordStyles>
      <Form
        validateMessages={validateMessages}
        style={{ width: "100%" }}
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <InputPassword name={"password"} label={"Senha"} />
        <InputPassword
                  name={"confPassword"}
                  label={"Confirmar senha"}
                  rules={({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("As senhas não coincidem!");
                    },
                  })}
                />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "16px",
          }}
        >
          <Button
            onClick={() => {
              onClose();
            }}
            btntype="defaultOutlined"
          >
            Voltar
          </Button>
          <Button
            loading={loading}
            disabled={loading}
            htmlType="submit"
            btntype="secondary"
          >
            Salvar
          </Button>
        </div>
      </Form>
    </UpdatePassordStyles>
  );
};

export default UpdatePasswordForm;
