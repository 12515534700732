import seggints_types from "./settings.types";
import fluxopayAPI, { Api_v2 } from "core/services/api";

export const patchRootFinancial = (params) => async (dispatch) => {
  try {
    dispatch({
      type: seggints_types.PATCH_ROOT_FINANCIAL,
    });

    const { data } = await fluxopayAPI.patch(`root-user`, params);

    dispatch({
      type: seggints_types.SUCCESS_PATCH_ROOT_FINANCIAL,
      payload: data,
    });

    return true;
  } catch (err) {
    dispatch({
      type: seggints_types.FAIL_PATCH_ROOT_FINANCIAL,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
    let fail_response = "Erro inesperado!";

    if (err.response)
      if (err.response.data) fail_response = err.response.data.error;

    return fail_response;
  }
};

export const patchRootFees = (email, params) => async (dispatch) => {
  try {
    dispatch({
      type: seggints_types.PATCH_ROOT_FEE,
    });
    const { data } = await Api_v2.patch(`root-user/${email}`, params);

    dispatch({
      type: seggints_types.SUCCESS_PATCH_ROOT_FEE,
      payload: data,
    });

    return true;
  } catch (err) {
    dispatch({
      type: seggints_types.FAIL_PATCH_ROOT_FEE,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
    let fail_response = "Erro inesperado!";

    if (err.response)
      if (err.response.data) fail_response = err.response.data.error;

    return fail_response;
  }
};

export const patchRootImage = (params) => async (dispatch) => {
  try {
    dispatch({
      type: seggints_types.PATCH_ROOT_IMAGE,
    });

    const { data } = await fluxopayAPI.patch(`root-user/image`, params);

    dispatch({
      type: seggints_types.SUCCESS_PATCH_ROOT_IMAGE,
      payload: data,
    });

    return true;
  } catch (err) {
    dispatch({
      type: seggints_types.FAIL_PATCH_ROOT_IMAGE,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
    let fail_response = "Erro inesperado!";

    if (err.response)
      if (err.response.data) fail_response = err.response.data.error;

    return fail_response;
  }
};

export const postClearAccount = (params) => async (dispatch) => {
  dispatch({ type: seggints_types.POST_CLEAR_ACCOUNT });

  try {
    const { data } = await Api_v2.post(`root-user/clear-account`, params);

    dispatch({
      type: seggints_types.SUCCESS_POST_CLEAR_ACCOUNT,
      payload: data,
    });

    return true;
  } catch (err) {
    await dispatch({
      type: seggints_types.FAIL_POST_CLEAR_ACCOUNT,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });

    let fail_response = "Erro inesperado!";

    if (err.response)
      if (err.response.data)
        if (err.response.data.error) fail_response = err.response.data.error;

    return fail_response;
  }
};

export const postReviewFee = (params, email, fee) => async (dispatch) => {
  try {
    dispatch({
      type: seggints_types.POST_REVIEWFEE,
    });
    const payload = {};
    if (
      parseFloat(fee.new_credit_fee).toFixed() !==
      parseFloat(params.new_credit_fee).toFixed()
    )
      payload["new_credit_fee"] = parseFloat(params["new_credit_fee"])/100;
    if (
      parseFloat(fee.new_debit_fee).toFixed() !==
      parseFloat(params.new_debit_fee).toFixed()
    )
      payload["new_debit_fee"] = parseFloat(params["new_debit_fee"]);
    if (fee.new_boleto_fee !== params.new_boleto_fee)
      payload["new_boleto_fee"] = parseFloat(params["new_boleto_fee"]);
    if (
      parseFloat(fee.new_pix_fee).toFixed() !==
      parseFloat(params.new_pix_fee).toFixed()
    )
      payload["new_pix_fee"] = parseFloat(params["new_pix_fee"]) /100;
    if (fee.new_monthly_amount !== params.new_monthly_amount)
      payload["new_monthly_amount"] = parseFloat(params["new_monthly_amount"]);
    if (fee.new_max_sale_price !== params.new_max_sale_price)
      payload["new_max_sale_price"] = parseFloat(params["new_max_sale_price"]);
    payload["monthly_income"] = parseFloat(params["monthly_income"]);
    payload["description"] = params["description"];
    await Api_v2.post(`root-user/review-fee/${email}`, payload);
    
    dispatch({
      type: seggints_types.SUCCESS_POST_REVIEWFEE,
    });
    return true;
  } catch (err) {
    dispatch({
      type: seggints_types.FAIL_POST_REVIEWFEE,
    });

    return err?.response?.data?.error || "Erro inesperado!";
  }
};

export const postSettingsImportUrl = () => async (dispatch) => {
  dispatch({ type: seggints_types.POST_SETTINGS_IMPORT_URL });

  try {
    const { data } = await fluxopayAPI.post(`url?extension=png`);

    dispatch({
      type: seggints_types.SUCCESS_POST_SETTINGS_IMPORT_URL,
      payload: data,
    });

    return true;
  } catch (err) {
    await dispatch({
      type: seggints_types.FAIL_POST_SETTINGS_IMPORT_URL,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });

    let fail_response = "Erro inesperado!";

    if (err.response)
      if (err.response.data)
        if (err.response.data.error) fail_response = err.response.data.error;

    return fail_response;
  }
};

export const getRootFees = (email) => async (dispatch) => {
  dispatch({
    type: seggints_types.GET_ROOT_FEE,
    payload: email,
  });
};

export const getDocumentImportUrl = (payload) => async (dispatch) => {
  dispatch({ type: seggints_types.GET_DOCUMENT_IMPORT_URL, payload: payload });
};

export const getSocialContractImportUrl = (payload) => async (dispatch) => {
  dispatch({ type: seggints_types.GET_SOCIAL_CONTRACT, payload: payload });
};

export const getBankStatementImportUrl = (payload) => async (dispatch) => {
  dispatch({ type: seggints_types.GET_BANK_STATEMENT, payload: payload });
};

export const getClearAccount = (params) => async (dispatch) => {
  try {
    dispatch({
      type: seggints_types.GET_CLEAR_ACCOUNT,
    });

    const { data } = await Api_v2.get(`root-user/clear-account`, params);

    dispatch({
      type: seggints_types.SUCCESS_GET_CLEAR_ACCOUNT,
      payload: data,
    });

    return true;
  } catch (err) {
    dispatch({
      type: seggints_types.FAIL_GET_CLEAR_ACCOUNT,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
    let fail_response = "Erro inesperado!";

    if (err.response)
      if (err.response.data) fail_response = err.response.data.error;

    return fail_response;
  }
};

export const clearRootFees = (email) => async (dispatch) => {
  dispatch({
    type: seggints_types.CLEAR_ROOT_FEE,
  });
};
