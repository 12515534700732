import styled from "styled-components";

export const FormTermsUseStyle = styled.div`

`; 

export const BodyTermsStyle = styled.div`
  width: 100%;
  height: auto;
  max-height: 300px;
  overflow-y: auto;
`;


export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`; 
