import React from "react";

const TermsUse = () =>{ 
    return (
      <div> 
          <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
        <title />
        <meta name="generator" content="LibreOffice 7.0.4.2 (Linux)" />
        <meta name="author" content="Daniel Cruz" />
        <meta name="created" content="2021-01-29T20:15:00" />
        <meta name="changedby" content="Daniel Cruz" />
        <meta name="changed" content="2021-01-30T11:39:00" />
        <meta name="AppVersion" content={16.0000} />
        <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\t\t@page { size: 21cm 29.7cm; margin-left: 3cm; margin-right: 3cm; margin-top: 2.5cm; margin-bottom: 2.5cm }\n\t\tp { margin-bottom: 0.25cm; direction: ltr; color: #000000; line-height: 115%; text-align: left; orphans: 2; widows: 2; background: transparent }\n\t\tp.western { font-family: \"Calibri\", serif; font-size: 11pt; so-language: pt-BR }\n\t\tp.cjk { font-family: \"Calibri\"; font-size: 11pt; so-language: en-US }\n\t\tp.ctl { font-family: ; font-size: 11pt; so-language: ar-SA }\n\t\ta:link { color: #0563c1; text-decoration: underline }\n\t" }} />
          <p align="center" style={{marginBottom: '0.53cm', lineHeight: 'normal', background: '#ffffff'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={6} style={{fontSize: '27pt'}}><span style={{letterSpacing: '2.3pt'}}>TERMOS DE USO</span></font></font></span></p>
        <p align="right" style={{marginBottom: '0.53cm', lineHeight: 'normal', background: '#ffffff'}}>
          <font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><span style={{letterSpacing: '2.3pt'}}>Última
                revisão: 01/02/2020</span></font></font></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><br />
        </p>
        <p align="justify" style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Este
              site está no ar para fins informativos e de conexões entre
              operações financeiras aqui facilitadas, esperamos que seja
              prazeroso o acesso. Navegue pelo site e utilize os seus variados
              recursos, entretanto, observe que o uso do site está sujeito aos
              Termos de Uso abaixo. O uso deste site e dos Serviços (definidos
              abaixo) representa a concordância com os Termos de Uso.</font></font></p>
        <p align="justify" style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}><br />
              <br />
            </font></font><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}><b>CASO
                NÃO CONCORDE COM OS TERMOS DE USO, NÃO UTILIZE ESTE SITE.</b></font></font></p>
        <p align="justify" style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}><b><br />
              </b></font></font><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}><br />
              Você
              verá que incluímos breves resumos no início de determinadas
              seções. Estes resumos não substituem a leitura dos termos em sua
              totalidade. Os mesmos são para fins de conveniência apenas e não
              possuem qualquer efeito legal ou contratual.</font></font></p>
        <p align="justify" style={{marginLeft: '1cm', marginBottom: '0cm', lineHeight: 'normal'}}>
          <font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}><u><b><br />
                  <br />
                  Índice:</b></u></font></font></p>
        <p style={{marginLeft: '1cm', marginBottom: '0cm', lineHeight: 'normal'}}><br />
        </p>
        <p style={{marginLeft: '1cm', marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Propriedade,
              Operação e Concordância em se obrigar por estes Termos de
              Uso<br />
              Elegibilidade e Acesso<br />
              Uso do
              site<br />
              Cadastro<br />
              Privacidade<br />
              Propriedade e Uso dos
              Materiais, Produtos e Serviços do Site<br />
              Contribuições de
              Usuários<br />
              Propriedade sobre as Contribuições de
              Usuários<br />
              Licença a Outros Usuários do
              Site<br />
              Consentimentos<br />
              Escopo Geográfico do Site<br />
              Links a
              Outros Sites<br />
              Conteúdo da Isenção de Responsabilidade do
              Site<br />
              Operação da Isenção de Responsabilidade do Site<br />
              Limite
              de Responsabilidade<br />
              Indenização<br />
              Rescisão<br />
              Disponibilidade
              de Suporte<br />
              Direito Aplicável e Jurisdição<br />
              Individualidade
              das Cláusulas Contratuais<br />
              Renúncia<br />
              Efeitos Legais</font></font></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', textTransform: 'uppercase', letterSpacing: '1.5pt', lineHeight: 'normal'}}>
          <br />
          <br />
        </p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', textTransform: 'uppercase', letterSpacing: '1.5pt', lineHeight: 'normal'}}>
          <br />
          <br />
        </p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={6} style={{fontSize: '22pt'}}><span style={{letterSpacing: '1.5pt'}}><br />
                  1.
                  PROPRIEDADE, OPERAÇÃO E CONCORDÂNCIA EM SE OBRIGAR AO CONTRATO E A
                  ESTES TERMOS DE USO</span></font></font></span></p>
        <p align="justify" style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Este
              site (</font></font><font color="#0000ff"><u><a href="http://www.fluxopay.com.br/"><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>www.fluxopay.com.br</font></font></a></u></font><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>)
              e todas suas páginas pertencem a empresa </font></font><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}><b>FLUXO
                RESULTADOS ME </b></font></font><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>(por
              vezes abreviada para Fluxo Pay)</font></font><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}><b>,
              </b></font></font><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>pessoa
              jurídica, inscrita no Cadastro</font></font><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}><b>
              </b></font></font><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Nacional
              de Pessoas Jurídicas do Ministério da Fazenda (CNPJ/MF) sob o nº
              37.630.198/0001-05, com sede Rua Me. Rita Amada de Jesus, 181 -
              Granja Julieta, 04721-050, Cidade e Estado de São Paulo.<br />
              <br />
              O
              seu uso, pessoal ou em nome de uma empresa, deste site e de quaisquer
              aplicações, software, dados, produtos, transações, promoções e
              quaisquer outros serviços disponibilizados no, ou a partir do, ou
              por meio do site pela Fluxo Pay (coletivamente denominados “Serviços
              ”), está sujeito aos termos de um contrato legal por e entre você
              e a Fluxo Resultados ME. Este contrato legal é composto por estes
              Termos de Uso juntamente a:<br />
              <br />
            </font></font><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}><b>a
                Política de Privacidade, </b></font></font>
        </p>
        <p align="justify" style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}><b><br />
                a
                Notificação Legal, (coletivamente denominados “Políticas Fluxo”
                ).</b></font></font></p>
        <p align="justify" style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}><br />
              Na
              medida em que houver qualquer conflito entre os Termos de Uso e
              quaisquer das Políticas Fluxo, os Termos de Uso prevalecerão.
              <br />
              <br />
              Você concorda que podemos, de tempos em tempos, alterar
              ou rever estes Termos de Uso atualizando este texto e que tais
              alterações ou revisões entrarão em vigor no momento de sua
              postagem. </font></font>
        </p>
        <p align="justify" style={{marginBottom: '0cm', lineHeight: 'normal'}}><br />
        </p>
        <p align="justify" style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}><b>Visite
                esta área sempre que acessar o site para se manter atualizado com os
                Termos de Uso em vigor. O uso do site reitera a sua concordância com
                os Termos de Uso então em vigor.</b></font></font></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={6} style={{fontSize: '22pt'}}><span style={{letterSpacing: '1.5pt'}}><br />
                  2.
                  ELEGIBILIDADE E ACESSO</span></font></font></span></p>
        <p align="justify" style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}><b>O
                acesso a determinadas partes do site e/ou a Serviços específicos
                pode ser limitado a usuários registrados. Consulte a seção
                Cadastro abaixo. Em cada caso, identificaremos de forma clara as
                partes do site ou dos Serviços com acesso limitado e os termos de
                uso aplicáveis.</b></font></font><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}><br />
              <br />
              O
              acesso ao site é concedido temporariamente, e a Fluxo Resultados ME
              reserva o direito, a seu absoluto critério, de modificar ou fechar o
              site ou partes específicas do site sem aviso prévio. Não nos
              responsabilizamos se, por qualquer motivo, o site ficar indisponível
              a qualquer momento ou por qualquer período.<br />
              <br />
              Sujeitos à
              lei aplicável, reservamos o direito de suspender ou encerrar o
              acesso ao site e/ou os serviços sem notificação devido a uma não
              conformidade com os Termos de Uso ou com as Políticas Fluxo, devido
              a uma infração a direitos autorais ou qualquer outro direito de
              propriedade intelectual, infração de segurança ou por qualquer
              outro motivo (incluindo a recorrência de quebras a políticas). A
              decisão de encerrar um acesso individual ao site ou os Serviços
              será final e obrigatória.</font></font></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={6} style={{fontSize: '22pt'}}><span style={{letterSpacing: '1.5pt'}}><br />
                  3.
                  USO DO SITE</span></font></font></span></p>
        <p align="justify" style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}><b>É
                proibido copiar, monitorar ou acessar qualquer parte do site
                utilizando um mecanismo ou processo automatizado como, por exemplo,
                um robô ou spider. É proibido tentar obter acesso não autorizado a
                qualquer parte do site hackeando o site ou utilizando quaisquer
                outros meios similares. É proibido o uso do site para fins ilegais.</b></font></font></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={6} style={{fontSize: '22pt'}}><span style={{letterSpacing: '1.5pt'}}><br />
                  4.
                  CADASTRO</span></font></font></span></p>
        <p align="justify" style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Se
              você desejar se cadastrar, a Fluxo Pay solicitará informações
              pessoais, como, por exemplo</font></font><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}><b>,
                o seu nome, endereço, cpf, e-mail, endereço, número de telefone
                celular, data de nascimento e/ou o seu sexo, dados bancários etc</b></font></font><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>.
              As informações fornecidas devem ser verdadeiras e é proibido se
              passar por outra pessoa. Esta exigência se dá por ser um sistema de
              facilitação financeira, sendo que estes dados cedidos seguirão a
              política de privacidade da empresa.<br />
              <br />
              Como parte do
              processo de cadastro, solicitaremos que escolha um nome de usuário e
              uma senha. Você será responsável por todas as atividades
              realizadas sob o seu nome de usuário e por manter a sua senha
              segura. Ao descobrir qualquer quebra à segurança ou qualquer uso
              não autorizado de seu nome de usuário ou senha notifique a Fluxo
              Resultados ME imediatamente.<br />
              <br />
              Você concorda em fornecer
              informações exatas e completas ao se cadastrar e utilizar o site e
              os Serviços, concordando, ainda, em manter as suas informações
              atualizadas. </font></font>
        </p>
        <p align="justify" style={{marginBottom: '0cm', lineHeight: 'normal'}}><br />
        </p>
        <p align="justify" style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}><b>Você
                concorda que a Fluxo Resultados ME pode armazenar e utilizar as suas
                informações exclusivamente para os fins descritos nestes Termos de
                Uso e na Politica de privacidade, o que envolve ceder estes dados
                para a empresa de segurança Clearsale, marketing Google bem como
                para as operadoras financeiras Safetopay a fim de garantir que a
                transação ocorra de maneira legal em conformidade com o ordenamento
                jurídico brasileiro.</b></font></font></p>
        <p align="justify" style={{marginBottom: '0cm', lineHeight: 'normal'}}><br />
        </p>
        <p align="justify" style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}><b>A
                Fluxo Resultados ME poderá utilizar estes dados para envio de
                mensagens sms, e-mail e ligação em caso de inadimplência,
                atualização da plataforma ou funcionalidades de lembretes conforme
                politica de privacidade.</b></font></font></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={6} style={{fontSize: '22pt'}}><span style={{letterSpacing: '1.5pt'}}><br />
                  5.
                  PRIVACIDADE</span></font></font></span></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>A
              Fluxo Resultados ME leva a sua privacidade a sério e utilizará e
              protegerá quaisquer informações pessoais fornecidas neste site de
              acordo com a nossa Política de Privacidade. Ao utilizar os Serviços
              você concorda que podemos utilizar as suas informações pessoais
              fornecidas de acordo com a Politica de Privacidade mencionada.</font></font></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', textTransform: 'uppercase', letterSpacing: '1.5pt', lineHeight: 'normal'}}>
          <br />
          <br />
        </p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={6} style={{fontSize: '22pt'}}><span style={{letterSpacing: '1.5pt'}}><br />
                  6.
                  PROPRIEDADE E USO DOS MATERIAIS, PRODUTOS E SERVIÇOS DO SITE</span></font></font></span></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Com
              exceção às Contribuições de Usuários, a Fluxo Resultados ME
              detém os direito de propriedade intelectual para todos os materiais
              no site ou possui um direito legal de um terceiro de uso dos
              materiais no site. A Fluxo Resultados ME, ainda, detém ou possui um
              direito legal de uso de todas as marcas registradas, marcas de
              serviço, gráficos e logos utilizados neste site. A menos que
              expressamente declarado, nenhuma parte do site pode ser copiada,
              reproduzida, republicada, atualizada, postada, transmitida ou
              distribuída de qualquer modo, exceto pelo direito de realizar o
              download de uma cópia dos materiais em qualquer computador exclusivo
              para seu uso pessoal, não comercial e residencial apenas,
              ressalvando-se que mantenha todos os direitos autorais, marcas
              registradas e outras notificações proprietárias nos materiais
              intactas. Qualquer modificação aos materiais ou uso dos materiais
              para qualquer outro fim constitui uma violação aos direito de
              propriedade intelectual e direitos proprietários da Fluxo Resultados
              ME. O uso de quaisquer referidos materiais em qualquer outro site ou
              rede de computadores sem o prévio consentimento por escrito da Fluxo
              Resultados ME é estritamente proibido. O uso das marcas registradas,
              marcas de serviço, gráficos e logos neste site de qualquer modo que
              não seja conforme autorizado nestes Termos de Uso, ou conforme
              autorizado por escrito pela Fluxo Resultados ME, é estritamente
              proibido.<br />
              <br />
              Ao realizar o download do software ou de outro
              conteúdo digital, incluindo aplicativos, deste site (“Produtos”),
              os Produtos, e todos os arquivos, imagens e dados relacionados, serão
              licenciados a você pela Fluxo Resultados ME de acordo com os termos
              e condições aplicáveis. Você concorda e reconhece que você não
              é o proprietário dos Produtos baixados, e que a Fluxo Resultados ME
              não transfere a propriedade sobre os Produtos a você. A Fluxo
              Resultados ME retém plena propriedade e titularidade sobre os
              Produtos baixados e sobre todos os direito de propriedade intelectual
              relacionados. É proibida a redistribuição, venda, decompilação,
              engenharia reversa, desmontagem ou, de outro modo, reduzir os
              Produtos a uma forma legível. Você (e não a Fluxo Resultados ME)
              arcará com todos os custos da manutenção, reparo ou correção
              necessários a seu computador e software como consequência de
              quaisquer vírus, componentes danosos, erros ou quaisquer outros
              problemas de qualquer natureza que você possa ter como resultado da
              visita ao site.</font></font></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><br />
        </p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}><b>A
                Fluxo Pay destaca que em momento algum tem contato com o dinheiro
                transacionado pelo usuário e operador financeiro, assim a Fluxo
                Resultados ME é apenas e tão somente um sistema facilitador de
                aparência intuitiva com multifuncionalidade integrada. A Fluxo
                Resultados ME destaca que NÃO é um banco ou operador financeiro.
                Portanto em qualquer hipótese de fraude, clonagem e condutas
                ilícitas a Fluxo Resultados ME NÃO poderá ser responsabilizada ou
                processada, nem mesmo ressarcirá o dano. A Fluxo Resultados ME se
                compromete apenas a facilitar o contato entre os polos, usuário e
                operador financeiro.</b></font></font></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><br />
        </p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={6} style={{fontSize: '22pt'}}><span style={{letterSpacing: '1.5pt'}}><br />
                  <br />
                  7.
                  CONTRIBUIÇÕES DE USUÁRIOS</span></font></font></span></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={5} style={{fontSize: '16pt'}}>SUBMETER
                CONTEÚDO</font></font></span></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Determinadas
              partes do site podem solicitar ou permitir a postagem, upload,
              anexação, exibição ou, de outro modo, a comunicação de textos,
              mensagens, fotos, vídeos, gravações, músicas (incluindo suas
              letras), gráficos, desenhos, sugestões, ideias (incluindo ideias de
              produtos e de publicidade), o seu nome e/ou o nome de outras pessoas,
              suas imagens, vozes, nomes de usuário, execuções e outras
              informações, materiais ou conteúdo de propriedade ou criado por
              você (coletivamente, as “Contribuições de Usuários“).<br />
              <br />
              A
              FLUXO RESULTADOS ME CONSIDERARÁ AS CONTRIBUIÇÕES DE USUÁRIO COMO
              NÃO CONFIDENCIAIS E NÃO PROPRIETÁRIAS E, PORTANTO, NÃO É
              OBRIGADA A MANTER QUAISQUER INFORMAÇÕES CONTIDAS NAS CONTRIBUIÇÕES
              DE USUÁRIO CONFIDENCIAIS.</font></font></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={5} style={{fontSize: '16pt'}}><br />
                DIREITOS
                SOBRE O CONTEÚDO DE CONTRIBUIÇÕES</font></font></span></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Você
              declara e garante que possui (e continuará possuindo durante o uso
              do site) todos os direitos, licenças, consentimentos e permissões
              necessários para permitir que a Fluxo Resultados ME e/ou provedores
              de conteúdo utilizem as suas Contribuições de Usuários para os
              fins descritos no site e nestes Termos de Uso.<br />
              <br />
              Você
              declara e garante, ainda, que possui o consentimento, liberação
              e/ou a permissão de todo e qualquer indivíduo identificável em
              suas Contribuições de Usuários para utilizar o seu nome, nome de
              usuário ou imagem da forma contemplada pelo site e os Produtos,
              Serviços ou outros materiais no ou disponíveis a partir do site.</font></font></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={5} style={{fontSize: '16pt'}}><br />
                NATUREZA
                DO CONTEÚDO SUBMETIDO</font></font></span></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Em
              conexão com as Contribuições de Usuários, você concorda e
              garante que:<br />
              <br />
              i. não fará qualquer Contribuição de
              Usuário sujeita a qualquer direito de terceiros, incluindo, sem
              implicar em limitação a:<br />
              a. direitos autorais, patentes,
              marcas registradas, segredos comerciais ou outros direitos
              proprietários ou contratuais;<br />
              b. direito de sigilo ou
              publicidade; ou<br />
              c. informações confidenciais<br />
              <br />
              a
              menos que você seja o proprietário de tais direitos ou tenha a
              permissão do proprietário legal para realizar as Contribuições de
              Usuário e a outorgar os direitos de acordo com este
              instrumento;<br />
              <br />
              ii. nenhuma das Contribuições de Usuários,
              ou o seu uso conforme descrito nestes Termos de Uso, infringem ou
              violam os direitos de qualquer indivíduo ou entidade ou quaisquer
              leis aplicáveis;<br />
              iii. você não fará Contribuições de
              Usuários que você não tenha o direito legal de possuir em seu país
              de residência, ou que A Fluxo Resultados ME não tenha o direito
              legal de utilizar ou possuir em conexão com a disponibilização do
              site ou fornecimento dos Serviços; e<br />
              iv. você não fará uma
              Contribuição de Usuário que:<br />
              a. seja ilegal ou que estimule
              ou ignore atos ilegais ou a discussão de atos ilegais com a intenção
              de cometê-los, incluindo, sem implicar em limitação a, tráfico de
              drogas, uso de drogas, pornografia infantil, abuso sexual ou físico,
              assédio, roubo, fraude ou conspiração de um ato criminoso;<br />
              b.
              seja difamatória, intimidante, abusiva, vexatória ou que constitua
              uma invasão de privacidade;<br />
              c. seja discriminatória ou
              preconceituosa com base na raça, cor, religião, sexo, orientação
              sexual, idade, incapacidade ou outros;<br />
              d. seja obscena,
              pornográfica ou explícita sexualmente;<br />
              e. seja perigosa,
              irresponsável ou antissocial ou que estimule ou releve um
              comportamento perigoso, irresponsável ou antissocial;<br />
              f.
              contenha um vírus, spyware ou outro componente nocivo, ou que de
              outro modo prejudique, interrompa ou danifique o site, os Serviços
              ou qualquer rede conectada, ou que de outro modo interfira com o uso
              ou usufruto por um indivíduo ao site ou Serviços; ou<br />
              g.
              contenha qualquer publicidade, solicitação, correntes, pirâmides,
              oportunidades de investimento ou outras comunicações não
              solicitadas.<br />
              <br />
              Você compreende e concorda que é
              exclusivamente responsável por suas próprias Contribuições de
              Usuários e as consequências de postar ou publicar as mesmas.</font></font></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={5} style={{fontSize: '16pt'}}><br />
                A
                FLUXO PAY E SUAS CONTRIBUIÇÕES</font></font></span></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>i.
              A Fluxo Resultados ME não é obrigada de qualquer modo a utilizar
              sua Contribuição de Usuário e a Fluxo Resultados ME não será
              obrigada a fornecer motivos por ter rejeitado a sua Contribuição de
              Usuário ou a identificar as atividades da Fluxo Resultados ME que
              sejam relacionadas à sua Contribuição de Usuário de qualquer
              modo.<br />
              <br />
              ii. A Fluxo Resultados ME não é obrigada a dar
              qualquer tipo de compensação pelo uso de sua Contribuição de
              Usuário conforme descrito neste site e nestes Termos de Uso.<br />
              <br />
              iii.
              A Fluxo Resultados ME pode utilizar o seu nome para atribuir a você
              a sua Contribuição de Usuário a você (porém não seremos
              obrigados a fazer isso).<br />
              <br />
              iv. Além disso, você compreende
              e concorda que ao utilizar o site você pode ser exposta às
              Contribuições de Usuários postadas e/ou submetidas por outros
              usuários. A Fluxo Resultados ME não será responsável de qualquer
              modo pelas Contribuições de Usuários, e a Fluxo Resultados MEnão
              garante a exatidão, integridade, qualidade ou direitos de
              propriedade intelectual de, ou relacionados a, tais Contribuições
              de Usuários. Além disso, a Fluxo Resultados ME não pode garantir
              que Contribuições de Usuários nocivas, imprecisas, enganosas,
              ofensivas, ameaçadoras, difamatórias, ilegais ou de outro modo
              censuráveis não aparecerão no site.<br />
              <br />
              v. Sem implicar em
              limitação à generalidade do descrito acima, você reconhece e
              concorda que as informações, materiais e opiniões expressas ou
              incluídas em quaisquer Contribuições de Usuários não representam
              necessariamente informações, materiais e opiniões da Fluxo
              Resultados ME ou suas afiliadas ou entidades relacionadas ou
              provedores de conteúdo e a Fluxo Resultados ME expressamente se
              isenta de toda e qualquer responsabilidade relacionada a qualquer
              Contribuição de Usuário. Você concorda em renunciar, e pelo
              presente o faz, a quaisquer direitos ou remédio legais ou
              equiparáveis que você tenha ou que venha a ter contra a Fluxo
              Resultados ME com relação a quaisquer Contribuições de
              Usuários.<br />
              <br />
              vi. Ao realizar o upload das Contribuições de
              Usuários você compreende que as mesmas podem ser disponibilizadas
              ao público e concorda em não apresentar qualquer reivindicação em
              qualquer parte do mundo afirmando invasão de privacidade, quebra de
              seus direitos de acordo com qualquer lei aplicável ou apropriação
              de seu direito a publicidade resultante de ou oriundo do uso pela
              Fluxo Resultados ME de suas Contribuições de Usuários de acordo
              com estes Termos de Uso.<br />
              <br />
              vii. Sem prejuízo a nossos
              outros direitos de acordo com os Termos de Uso ou em lei, a Fluxo
              Resultados ME reserva o direito, a seu absoluto critério e sem aviso
              prévio, de recusar postar, excluir ou remover qualquer Contribuição
              de Usuário do site que viola os Termos de Uso e/ou das Políticas
              Fluxo.<br />
              <br />
              viii. A Fluxo Resultados ME cooperará plenamente
              com quaisquer autoridades legais, ordem judicial ou citações
              requerendo ou orientando a Fluxo Resultados ME a divulgar a
              identidade de qualquer indivíduo postando Contribuições de
              Usuários. Na medida permitida de acordo com a lei, reservamos o
              direito de identificar qualquer usuário a terceiros e/ou divulgar
              qualquer Contribuição de Usuário ou dados pessoais a qualquer
              terceiro reivindicando que uma Contribuição de Usuário que
              infrinja seus direito de propriedade intelectual ou seu direito a
              privacidade. Podemos, ainda, divulgar tais informações se
              acreditarmos de boa fé que tal divulgação seja razoavelmente
              necessária para proteger os direitos, propriedade ou segurança
              pessoal da Fluxo Resultados ME r, dos clientes ou do público.<br />
              <br />
              ix.
              Apesar de A Fluxo Resultados ME não ser obrigada a revisar ou
              monitorar as Contribuições de Usuários, a Fluxo Resultados ME
              reserva o direito exclusivo de assim o fazer, a seu exclusivo
              critério. Além disso, a Fluxo Resultados ME reserva o direito, a
              qualquer momento, de alterar, editar, rejeitar a postagem ou remover
              quaisquer Contribuições de Usuários do site, no todo ou em parte,
              por qualquer causa ou sem e sem aviso prévio, a seu critério. Você
              concorda que a Fluxo Resultados ME não possui qualquer obrigação
              de usar ou responder a qualquer Contribuição de Usuário.</font></font></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={6} style={{fontSize: '22pt'}}><span style={{letterSpacing: '1.5pt'}}><br />
                  8.
                  PROPRIEDADE SOBRE AS CONTRIBUIÇÕES DE USUÁRIOS</span></font></font></span></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Você
              reterá todos os direitos proprietários sobre suas Contribuições
              de Usuários, entretanto você é obrigado a conceder direitos
              limitados à Fluxo Resultados ME e a outros usuários do site.<br />
              <br />
              Em
              compensação pelo uso do site e dos Serviços, você concede à
              Fluxo Resultados ME uma licença não exclusiva, irrevogável,
              mundial, gratuita e transferível (com plenos direitos de
              sublicenciar) para usar suas Contribuições de Usuários para
              qualquer fim (na medida permitida por lei) perpetuamente e
              gratuitamente. A licença inclui, sem implicar em limitação ao
              direito de reproduzir, copiar, editar, adaptar, executar trabalhos
              derivados, combinar com outros trabalhos, disponibilizar,
              sincronizar, exibir, realizar publicamente, transmitir, publicar,
              distribuir, sublicenciar e deletar suas Contribuições de Usuários.
              Sem prejuízo ao acima, caso você tenha uma patente sobre sua
              Contribuição de Usuário, a Fluxo Resultados ME reconhece que estes
              Termos de Uso não concedem à Fluxo Resultados ME qualquer licença
              de acordo com qualquer tal patente.<br />
              <br />
              Você reconhece e
              concorda que caso a Fluxo Resultados ME edite, modifique ou realize
              um trabalho derivado de sua Contribuição de Usuário, ou combine
              sua Contribuição de Usuário a outros trabalhos, quaisquer direitos
              sobre tais modificações ou trabalhos derivados serão de
              propriedade exclusiva da Fluxo Resultados ME.<br />
              <br />
              Caso sua
              Contribuição de Usuário incluir uma ideia ou sugestão, você
              reconhece que a Fluxo Resultados ME, ou um terceiro agindo em seu
              nome pode ter trabalhado anteriormente ou pode estar trabalhando na
              mesma ou em uma ideia similar. Ao enviar sua ideia, você concorda
              que podemos prosseguir com o seu desenvolvimento (ou com o
              desenvolvimento de uma ideia similar) independente de, e sem o
              reconhecimento de, sua ideia.<br />
              <br />
              Você, pelo presente,
              irrevogável e incondicionalmente, isenta e mantém a Fluxo
              Resultados ME de todas e quaisquer ações, causas de agir,
              reivindicações, danos, responsabilidades e demandas, sejam elas
              absolutas ou contingentes, e de qualquer natureza, que você possa
              ter ou venha a ter no futuro contra a Fluxo Resultados ME com relação
              a sua Contribuição de Usuário, incluindo a forma na qual a Fluxo
              Resultados ME utiliza sua Contribuição de Usuário.</font></font></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={6} style={{fontSize: '22pt'}}><span style={{letterSpacing: '1.5pt'}}><br />
                  9.
                  LICENÇA A OUTROS USUÁRIOS DO SITE</span></font></font></span></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Você
              concede, ainda, a cada usuário do site, uma licença não exclusiva,
              mundial e gratuita para acesso às suas Contribuições de Usuários
              pelo site sem qualquer cobrança, e uma licença para o uso,
              reprodução, distribuição, preparação de trabalhos derivados,
              exibição e execução de tais Contribuições de Usuários na
              medida permitida pela funcionalidade do site e conforme descrito
              nestes Termos de Uso.</font></font></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={6} style={{fontSize: '22pt'}}><span style={{letterSpacing: '1.5pt'}}><br />
                  10.
                  CONSENTIMENTOS</span></font></font></span></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Você
              concede à Fluxo Resultados ME todos os consentimentos requeridos de
              acordo com a Lei de Direitos Autorais, Designs e Patentes de 1988 (e
              todas as outras leis existentes ou que venham a ser promulgadas no
              futuro em qualquer parte do mundo) que possam ser requeridos a serem
              utilizados pela Fluxo Resultados ME, e/ou provedores de conteúdo de
              suas Contribuições de Usuários de acordo com estes Termos de
              Uso.<br />
              <br />
              Você renuncia a todos os direitos morais aos quais
              possa ter direito de acordo com qualquer legislação existente ou
              promulgada no futuro em qualquer parte do mundo com relação a suas
              Contribuições de Usuários. Quando não for permitido por lei, você
              se compromete a não exercer os seus direitos morais em qualquer
              momento no futuro.</font></font></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={6} style={{fontSize: '22pt'}}><span style={{letterSpacing: '1.5pt'}}><br />
                  11.
                  ESCOPO GEOGRÁFICO DO SITE</span></font></font></span></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>A
              Fluxo Resultados ME controla e opera este site a partir do Brasil. A
              Fluxo Resultados ME não faz qualquer declaração de que os
              materiais neste site ou que os Produtos descritos no mesmo são
              apropriados ou que estão disponíveis para uso em sua localidade.
              Todos os visitantes do site são responsáveis pela conformidade com
              as leis locais aplicáveis com relação ao conteúdo e operação
              deste site.<br />
              <br />
              Você concorda em não aplicar qualquer link a
              este site em qualquer site não operado pela Fluxo Resultados ME, a
              menos que o prévio consentimento por escrito da Fluxo Resultados ME
              seja concedido.</font></font></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={6} style={{fontSize: '22pt'}}><span style={{letterSpacing: '1.5pt'}}><br />
                  12.
                  LINKS A OUTROS SITES</span></font></font></span></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Para
              sua conveniência e usufruto, este site pode disponibilizar links a
              outros sites na internet não operados pela Fluxo Resultados ME. A
              Fluxo Resultados ME não possui qualquer controle sobre estes sites e
              não é responsável por sua disponibilidade. A Fluxo Resultados ME
              não endossa, aprova ou patrocina, tampouco a Fluxo Resultados ME é
              responsável por, quaisquer informações ou conteúdo disponível
              nos sites direcionados ou quaisquer produtos, ou outros materiais nos
              ou disponíveis em tais sites. A Fluxo Resultados ME não é
              responsável pela transmissão na rede ou por qualquer outra forma de
              transmissão recebida a partir de qualquer site direcionado. Por fim,
              a Fluxo Resultados ME não é responsável, direta ou indiretamente,
              por qualquer dano ou perda causada ou alegada como tendo sido causada
              por ou em conexão com o seu uso de tal conteúdo, informações,
              produtos, mercadorias ou serviços disponibilizados no ou por meio de
              quaisquer tais sites direcionados.</font></font></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={6} style={{fontSize: '22pt'}}><span style={{letterSpacing: '1.5pt'}}><br />
                  13.
                  CONTEÚDO DA ISENÇÃO DE RESPONSABILIDADE DO SITE</span></font></font></span></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>O
              material no site (incluindo quaisquer gráficos, software, conteúdo
              digital, recomendações ou outros materiais) e qualquer material
              disponibilizado pelo site é fornecido “como estiver” e sem
              condições ou garantias de qualquer tipo, sejam expressas ou
              implícitas. Na medida máxima permitida de acordo com a lei, a Fluxo
              Resultados ME renuncia a todas as condições e garantias, expressas
              ou implícitas, incluindo, sem implicar em limitação a, todas as
              garantias implícitas de comerciabilidade, adequação a um fim
              específico, titularidade e não infração. A Fluxo Resultados ME
              não garante ou faz quaisquer declarações relacionadas ao uso ou
              aos resultados do uso do material no site com relação a sua
              precisão, exatidão, confiabilidade ou de outro modo. O material
              neste site pode incluir imprecisões técnicas ou erros tipográficos
              e pode ser impreciso ou se tornar impreciso devido a desenvolvimentos
              ocorrendo após suas respectivas datas. A Fluxo Resultados ME não é
              obrigada a verificar ou manter a precisão de tais informações.</font></font></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={6} style={{fontSize: '22pt'}}><span style={{letterSpacing: '1.5pt'}}><br />
                  14.
                  OPERAÇÃO DA ISENÇÃO DE RESPONSABILIDADE DO SITE</span></font></font></span></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>A
              Fluxo Resultados ME se compromete a manter este website e sua
              operação, porém não é, e não será, responsável pelos
              resultados de quaisquer defeitos que possam existir no site ou em sua
              operação. Com relação à operação do site, a Fluxo Resultados
              ME expressamente renuncia a todas as condições e garantias de
              qualquer tipo, expressas ou implícitas, incluindo, sem implicar em
              limitação a, todas as garantias implícitas de comerciabilidade ou
              adequação a um fim específico, titularidade e não infração. A
              Fluxo Resultados ME não faz qualquer garantia de que (i) a operação
              do site atenderá aos requerimentos do usuário; (ii) o acesso ao
              site será ininterrupto, tempestivo, seguro, isento de vírus, worms,
              cavalos de Tróia ou outros componentes danosos, ou livre de defeitos
              ou erros; (iii) os resultados que podem ser obtidos a partir do uso
              do site serão precisos ou confiáveis; ou (iv) defeitos serão
              corrigidos. Você (e não a Fluxo Resultados ME) arcará com todos os
              custos da manutenção, reparo ou correção necessários a seu
              computador e software como consequência de quaisquer vírus,
              componentes danosos, erros ou quaisquer outros problemas de qualquer
              natureza que você possa ter como resultado da visita ao site.</font></font></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={6} style={{fontSize: '22pt'}}><span style={{letterSpacing: '1.5pt'}}><br />
                  15.
                  LIMITE DE RESPONSABILIDADE</span></font></font></span></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Na
              medida requerida pela lei aplicável, não limitamos de qualquer
              forma nossa responsabilidade pela morte ou pelos danos pessoais
              causados por nossa negligência ou por declarações fraudulentas ou
              ocultações ou por qualquer outra responsabilidade que não possa
              ser excluída ou limitada pela lei aplicável.<br />
              <br />
              Sujeita à
              lei aplicável, em nenhuma circunstância A Fluxo Resultados ME será
              responsável perante você ou perante qualquer outra pessoa ou
              entidade por quaisquer danos diretos, indiretos, especiais,
              incidentais, consequentes, punitivos ou outros danos, incluindo
              lucros cessantes, danos pessoais (incluindo morte) e danos à
              propriedade, que possam resultar (a) do uso de, ou da incapacidade de
              usar, o site ou qualquer material no site, (b) da condução de
              qualquer usuário do site, seja em contrato, atos ilícitos
              (incluindo negligência) ou de outro modo. A Fluxo Resultados ME não
              será responsável mesmo que A Fluxo Resultados ME tenha sido
              informada da possibilidade de tais danos. Estes danos incluem, sem
              implicar em limitação a, danos causados por erro, omissão,
              interrupção, defeito, falha no desempenho, atraso na operação ou
              transmissão, falha na linha ou vírus de computador, worm, cavalo de
              Tróia ou outro componente danoso.<br />
              <br />
              A Fluxo Resultados ME
              não assume qualquer responsabilidade resultante do conteúdo de
              qualquer Contribuição de Usuário ou por qualquer difamação,
              calúnia, omissão, inexatidão, obscenidade, pornografia,
              profanação, risco, ilegalidade, infração a direito de propriedade
              intelectual, erro ou inexatidão em quaisquer das Contribuições de
              Usuários.<br />
              <br />
              A lei aplicável não permitirá a exclusão de
              determinadas garantias ou a limitação ou exclusão de
              responsabilidade por danos incidentais ou consequentes. Desse modo,
              algumas das limitações ou exclusões acima não ser aplicáveis a
              você. Entretanto, em nenhum caso a responsabilidade plena da Fluxo
              Resultados ME perante você por danos, perdas, e causas de agir, seja
              em contrato, atos ilícitos (incluindo negligência) ou de outro
              modo, excederá o valor pago a você, se houver, pelo acesso ao site.</font></font></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><br />
        </p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>LIMITAÇÕES
              DE UTILIZAÇÃO</font></font></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><br />
        </p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Você
              deve ter pelo menos dezoito (18) anos de idade para acessar este
              website. Se você não tem, pelo menos, 18 anos de idade, você não
              tem permissão para acessar este site por qualquer motivo.</font></font></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Se
              você receber uma senha para acessar este site, saiba que a senha é
              apenas para seu uso pessoal, salvo indicação em contrário. Você
              concorda em ser responsável pela segurança de sua senha.</font></font></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><br />
        </p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>REFERÊNCIAS
              DE TERCEIROS / HYPERLINKS</font></font></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><br />
        </p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Este
              site pode estar linkado a outros sites na Internet. Esses sites podem
              conter informações ou material que algumas pessoas podem achar
              impróprio ou ofensivo. Esses outros sites não estão sob o controle
              da empresa, e você reconhece que empresa não se responsabiliza pela
              exatidão, cumprimento de direitos autorais, legalidade, decência,
              ou qualquer outro aspecto do conteúdo de tais sites. A inclusão de
              uma tal link não implica o endosso de outro site, ou qualquer
              associação com seus operadores.</font></font></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><br />
        </p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>INFORMAÇÕES
              ENVIADAS POR VOCÊ</font></font></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><br />
        </p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Você
              garante que qualquer informação que você enviar à Fluxo
              Resultados ME através deste site é de sua propriedade e que você
              tem a autoridade necessária à apresentação dessas informações.
              Você concede autorização eterna, isenta de direitos autorais para
              exibir, modificar, adaptar, criar trabalhos derivados, e de outra
              forma utilizar qualquer sugestões, depoimentos, idéias e
              informações que você fornecer para empresa.</font></font></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Você
              também concorda que você não deve apresentar ou transmitir
              qualquer conteúdo deste site ou a empresa de forma:</font></font></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Obscena,
              vulgar ou pornográfica; Que incentive a prática de um crime ou
              violação de uma lei; Viole qualquer lei estadual ou federal do
              Brasil e/ou da jurisdição onde reside; Viola os direitos de
              propriedade intelectual de terceiros; Seja ofensivo ou inapropriado
              com base no tipo de conteúdo e informações fornecidas pela empresa
              e / ou de terceiros sobre este web site.</font></font></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>A
              Fluxo Resultados ME reserva-se o direito de remover ou excluir
              qualquer conteúdo ou sua apresentação que violar essas regras, ou
              que não são apropriadas a exclusivo critério da empresa, sem
              responsabilidade ou aviso prévio a você.</font></font></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Essa
              EMPRESA reserva-se o direito de cooperar com as autoridades policiais
              e oficiais de justiça na investigação e repressão de qualquer
              crime ou ação judicial. Você concorda em apoiar a Fluxo Resultados
              ME de todas as consequências e medidas tomadas pela EMPRESA em
              cooperação com tal investigação aplicação da lei ou ordem
              judicial.</font></font></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><br />
        </p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={6} style={{fontSize: '22pt'}}><span style={{letterSpacing: '1.5pt'}}><br />
                  16.
                  INDENIZAÇÃO</span></font></font></span></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Você,
              pelo presente, concorda em defender, indenizar e manter A Fluxo
              Resultados ME e cada um de seus respectivos agentes, licenciados,
              sucessores e cessionários, indenes de e contra todas e quaisquer
              reivindicações, ações ou procedimentos de qualquer tipo e contra
              todos e quaisquer danos, responsabilidades, custas e despesas,
              incluindo honorários advocatícios razoáveis, relacionados a ou
              oriundos de seu uso do site, de qualquer uso de suas Contribuições
              de Usuários conforme permitido por estes Termos de Uso e/ou de
              qualquer quebra ou quebra alegada a quaisquer garantias, declarações
              ou acordos de acordo com este instrumento.</font></font></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={6} style={{fontSize: '22pt'}}><span style={{letterSpacing: '1.5pt'}}><br />
                  17.
                  RESCISÃO</span></font></font></span></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Os
              Termos de Uso e as Políticas Fluxo são e permanecerão em vigor até
              que rescindidos. Você pode rescindir estes Termos de Uso deixando de
              usar o site e destruindo todo e qualquer material obtido por você a
              partir do site. Podemos rescindir, com efeito imediato, os Termos de
              Uso e as Políticas Fluxo, incluindo o seu acesso ao site, caso você
              quebre ou deixe de cumprir com qualquer termo ou disposição
              material dos Termos de Uso. Quando da rescisão, você deve
              interromper o uso do site e destruir todo e qualquer material obtido
              por você a partir do site.</font></font></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={6} style={{fontSize: '22pt'}}><span style={{letterSpacing: '1.5pt'}}><br />
                  18.
                  DISPONIBILIDADE DE SUPORTE</span></font></font></span></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Este
              site pode disponibilizar um arquivo de Suporte ou outras instruções
              para uso deste site. Entretanto, você compreende que A Fluxo
              Resultados ME não tem qualquer obrigação de fornecer qualquer
              suporte pelo uso do site.</font></font></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={6} style={{fontSize: '22pt'}}><span style={{letterSpacing: '1.5pt'}}><br />
                  19.
                  DIREITO APLICÁVEL E JURISDIÇÃO</span></font></font></span></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Estes
              Termos de Uso e quaisquer controvérsias resultante dos mesmos ou
              relacionadas a estes Termos de Uso (seja em contrato, por atos
              ilícitos (incluindo negligência), em um estatuto ou de outro modo)
              serão regidos pelas leis brasileiras, independente de seus conflitos
              de princípios de lei. Quaisquer ações legais, processos ou
              procedimentos oriundos destes Termos de Uso (seja em contrato, por
              atos ilícitos (incluindo negligência), em um estatuto ou de outro
              modo) serão instaurados exclusivamente na comarca de São Paulo/SP,
              e você, pelo presente, aceita e se submete à jurisdição pessoal
              destes tribunais com relação a quaisquer ações legais, processos
              ou procedimentos oriundos destes Termos de Uso.</font></font></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={6} style={{fontSize: '22pt'}}><span style={{letterSpacing: '1.5pt'}}><br />
                  20.
                  INDIVIDUALIDADE DAS CLÁUSULAS CONTRATUAIS</span></font></font></span></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Caso
              qualquer disposição destes Termos de Uso seja considerada ilegal,
              nula ou por qualquer motivo inexequível, então tal disposição
              será considerada excluída destes Termos de Uso e não afetará a
              validade e exequibilidade das disposições restantes.</font></font></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={6} style={{fontSize: '22pt'}}><span style={{letterSpacing: '1.5pt'}}><br />
                  21.
                  RENÚNCIA</span></font></font></span></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Nenhuma
              falha por parte da Fluxo Resultados ME em aplicar qualquer parte
              destes Termos de Uso constituirá uma renúncia a quaisquer dos
              direitos da Fluxo Resultados ME, de acordo com estes Termos de Uso, a
              ações passadas ou futuras por parte de qualquer indivíduo. Apenas
              uma renúncia específica por escrito assinada por um representante
              da Fluxo Resultados ME terá qualquer efeito legal.</font></font></p>
        <p style={{marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: 'normal'}}>
          <span style={{textTransform: 'uppercase'}}><font face="Arial, serif"><font size={6} style={{fontSize: '22pt'}}><span style={{letterSpacing: '1.5pt'}}><br />
                  22.
                  EFEITOS LEGAIS</span></font></font></span></p>
        <p style={{marginBottom: '0cm', lineHeight: 'normal'}}><font face="Arial, serif"><font size={2} style={{fontSize: '10pt'}}>Os
              cabeçalhos nestes Termos de Uso são para fins de conveniência
              apenas e não possuem qualquer efeito legal ou contratual.<br />
              <br />
              Para
              retornar ao site que estava visitando pressione o botão para voltar
              em seu navegador.<br />
            </font></font><br />
        </p>
        <p style={{marginBottom: '0.28cm', lineHeight: '108%'}}><br />
          <br />
        </p>
      </div>
    );
  };

  export default TermsUse;