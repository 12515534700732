const crud_form_massified_types = {
  GET_MASSIFIED: "GET_MASSIFIED",
  LOADING_GET_MASSIFIED: "LOADING_GET_MASSIFIED",
  SUCCESS_GET_MASSIFIED: "SUCCESS_GET_MASSIFIED",
  FAIL_GET_MASSIFIED: "FAIL_GET_MASSIFIED",
  GET_MASSIFIED_BY_ID: "GET_MASSIFIED_BY_ID",
  LOADING_GET_MASSIFIED_BY_ID: "LOADING_GET_MASSIFIED_BY_ID",
  SUCCESS_GET_MASSIFIED_BY_ID: "SUCCESS_GET_MASSIFIED_BY_ID",
  FAIL_GET_MASSIFIED_BY_ID: "FAIL_GET_MASSIFIED_BY_ID",
  POST_NEW_MASSIFIED: "POST_NEW_MASSIFIED",
  SUCCESS_POST_NEW_MASSIFIED: "SUCCESS_POST_NEW_MASSIFIED",
  FAIL_POST_NEW_MASSIFIED: "FAIL_POST_NEW_MASSIFIED",
  PUT_MASSIFIED: "PUT_MASSIFIED",
  SUCCESS_PUT_MASSIFIED: "SUCCESS_PUT_MASSIFIED",
  FAIL_PUT_MASSIFIED: "FAIL_PUT_MASSIFIED",
  DELETE_MASSIFIED: "DELETE_MASSIFIED",
  SUCCESS_DELETE_MASSIFIED: "SUCCESS_DELETE_MASSIFIED",
  FAIL_DELETE_MASSIFIED: "FAIL_DELETE_MASSIFIED",
  GET_MASSIFIED_MODEL: "GET_MASSIFIED_MODEL",
  SUCCESS_GET_MASSIFIED_MODEL: "SUCCESS_GET_MASSIFIED_MODEL",
  FAIL_GET_MASSIFIED_MODEL: "FAIL_GET_MASSIFIED_MODEL",
  POST_MASSIFIED_IMPORT: "POST_MASSIFIED_IMPORT",
  SUCCESS_POST_MASSIFIED_IMPORT: "SUCCESS_POST_MASSIFIED_IMPORT",
  FAIL_POST_MASSIFIED_IMPORT: "FAIL_POST_MASSIFIED_IMPORT",
  POST_MASSIFIED_IMPORT_URL: "POST_MASSIFIED_IMPORT_URL",
  SUCCESS_POST_MASSIFIED_IMPORT_URL: "SUCCESS_POST_MASSIFIED_IMPORT_URL",
  FAIL_POST_MASSIFIED_IMPORT_URL: "FAIL_POST_MASSIFIED_IMPORT_URL",
  GET_MASSIFIED_EXPORT: "GET_MASSIFIED_EXPORT",
  SUCCESS_GET_MASSIFIED_EXPORT: "SUCCESS_GET_MASSIFIED_EXPORT",
  FAIL_GET_MASSIFIED_EXPORT: "FAIL_GET_MASSIFIED_EXPORT",
};

export default crud_form_massified_types;
