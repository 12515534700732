import { combineReducers } from "redux";

//reducers
import subaccount from "./crud-form-subaccount/crud-form-subaccount.reducer";
import logs from "./crud-table-logs/crud-table-logs.reducer";
 
export const rootReducer = combineReducers({
  subaccount,
  logs, 
});

export default rootReducer;
