import negociation_panel from "./negociations-panel.types";

const INITIAL_STATE = {
  table: { 
    loading: true,
    items: [],
    error: false,
  },
  negociation: {
    loading: false,
    items: {},
    error: false,
  },
};

const negociation_panel_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case negociation_panel.LOADING_GET_NEGOCIATION_PANEL:
      return {
        table: {
          loading: true,
          items: [ ...state.table.items ],
          error: false,
        },
        negociation: {...state.negociation}
      };


    case negociation_panel.SUCCESS_GET_NEGOCIATION_PANEL:
      return {
        table: {
          loading: false,
          items: action.payload,
          error: false,
        },
        negociation: {...state.negociation}
      };


    case negociation_panel.FAIL_GET_NEGOCIATION_PANEL:
      return {
        table: { 
          loading: false,
          items: [],
          error: true,
        },
        negociation: {...state.negociation},
      };

    case negociation_panel.LOADING_GET_NEGOCIATION_BY_ID:
      return {
        ...state,
        negociation: {
          loading: true,
          items:  { ...state.negociation.items } ,
          error: false,
        },
      };


    case negociation_panel.SUCCESS_GET_NEGOCIATION_BY_ID:
      return {
        ...state,
        negociation: {
          loading: false,
          items:  action.payload,
          error: false,
        } 
      };


    case negociation_panel.FAIL_GET_NEGOCIATION_BY_ID:
      return {
        ...state,
        negociation: {
          loading: false,
          items:  {  } ,
          error: true,
        }
      };
    
    case "LOGOUT_USER":
      return INITIAL_STATE;
  
    default:
      return state;
  }
};

export default negociation_panel_reducer;
