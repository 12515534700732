import modal_types from "./modal.types";

export const setModalProps = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: modal_types.SET_MODAL_PROPS,
    });

    dispatch({
      type: modal_types.SUCCESS_SET_MODAL_PROPS,
      payload,
    });
  } catch (err) {
    dispatch({
      type: modal_types.FAIL_SET_MODAL_PROPS,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
};

export const onCloseModal = () => async (dispatch) => {
  try {
    dispatch({
      type: modal_types.CLOSE_MODAL,
    });
  } catch (err) {
    dispatch({
      type: modal_types.FAIL_CLOSE_MODAL,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
};
