import tour_types from "./tour.types";
 
const INITIAL_STATE = {
  visible: false,
  onFinish: () => null,
  steps: [
    {
      selector: '[data-tour="transaction-main"]',
      content: 'Aqui você pode lançar o financeiro da sua empresa! Coloque a data que você irá receber, valor, cliente, descrição e muito mais'
    },
  ],
  tours: {
    loading: false,
    items: [],
    error: false,
  },
};

const tour_reducer = (state = INITIAL_STATE, action) => { 
  switch (action.type) {
    case tour_types.SET_TOUR_PROPS:
      return {
        ...state, 
      };

    case tour_types.SUCCESS_SET_TOUR_PROPS:
      return {
        ...state,
        ...action.payload
    };

    case tour_types.FAIL_SET_TOUR_PROPS:
      return {
        ...INITIAL_STATE
    };

    case tour_types.CLOSE_TOUR:
      return {
        ...state,
        visible: false,
        steps: [
          {
            selector: '[data-tour="transaction-main"]',
            content: 'Aqui você pode lançar o financeiro da sua empresa! Coloque a data que você irá receber, valor, cliente, descrição e muito mais'
          },
        ]
      };

    case tour_types.FAIL_CLOSE_TOUR:
      return {
        ...state,
        visible: false,
        steps: [
          {
            selector: '[data-tour="transaction-main"]',
            content: 'Aqui você pode lançar o financeiro da sua empresa! Coloque a data que você irá receber, valor, cliente, descrição e muito mais'
          },
        ]
      };

      case tour_types.LOADING_GET_TOUR:
      return {
        ...state,
        tours: {
          loading: true,
          items: [],
          error: false,
        },
      };

    case tour_types.SUCCESS_GET_TOUR:
      return {
        ...state,
        tours: {
          loading: false,
          items: action.payload,
          error: false,
        },
      };

    case tour_types.FAIL_GET_TOUR:
      return {
        ...state,
        tours: {
          loading: false,
          items: [],
          error: true,
        },
      }; 
  
 
    default:
      return state;
  }
};

export default tour_reducer;