import cnpj_types from "./cnpj.types";
 
const INITIAL_STATE = {
  loading: true,
  items: {},
  error: false,
  error_message: {}
};

const crud_form_customer_reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case cnpj_types.LOADING_GET_CNPJ_VALIDATION:
      return {
        loading: true,
        items: {...state.items},
        error: false,
        error_message: {}
      };

    case cnpj_types.SUCCESS_GET_CNPJ_VALIDATION:
       
      return {
        loading: false,
        items: action.payload,
        error: false,
        error_message: {}
      };

    case cnpj_types.FAIL_GET_CNPJ_VALIDATION:
      return {
        loading: false,
        items: {},
        error: true,
        error_message: action.error_message
      };
    
    case "LOGOUT_USER":
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default crud_form_customer_reducer;