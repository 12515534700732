import dashboard_types from "./dashboard.types";
 
const INITIAL_STATE = {
  loading: true,
  items: {},
  error: false,
};

const dashboard_reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case dashboard_types.LOADING_GET_DASHBOARD:
      return {
        loading: true,
        items: {...state.items},
        error: false,
      
      };
    case dashboard_types.SUCCESS_GET_DASHBOARD:
       
      return {
        loading: false,
        items: action.payload,
        error: false,
 
      };
    case dashboard_types.FAIL_GET_DASHBOARD:
      return {
        loading: false,
        items: {},
        error: true,
      };
    
    case "LOGOUT_USER":
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default dashboard_reducer;