import crud_form_subaccount_types from "./crud-form-subaccount.types";

const INITIAL_STATE = {
  table:  {
    loading: true,
    items: [ ],
    error: false,
   },
  edit: {
    loading: true,
    items: {},
    error: false,
  }
};

const crud_form_subaccount_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case crud_form_subaccount_types.LOADING_GET_AUTH_SUBACCOUNT:
      return {
        ...state,
        table: { 
          loading: true,
          items: [...state.table.items],
          error: false,
        }
      };

    case crud_form_subaccount_types.SUCCESS_LOADING_GET_SUBACCOUNT:
      return {
        ...state,
        table:{
          loading: false,
          items: action.payload,
          error: false,
        }
      }

    case crud_form_subaccount_types.FAIL_LOADING_GET_SUBACCOUNT:
      return {
        ...state,
        table:{
          loading: false,
          items: [...state.table.items],
          error: true,
        }
      }
 
    case crud_form_subaccount_types.LOADING_GET_SUBACCOUNT_BY_ID:
      return {
        ...state,
        edit: { 
          loading: true,
          items: {...state.table.items},
          error: false,
        }
      };

    case crud_form_subaccount_types.SUCCESS_GET_SUBACCOUNT_BY_ID:
      return {
        ...state,
        edit:{
          loading: false,
          items: action.payload,
          error: false,
        }
      }

    case crud_form_subaccount_types.FAIL_GET_SUBACCOUNT_BY_ID:
      return {
        ...state,
        edit:{
          loading: false,
          items: {...state.table.items},
          error: true,
        }
      }
    
    case "LOGOUT_USER":
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default crud_form_subaccount_reducer;
