import { combineReducers } from "redux";

//reducers
import operator from "./crud-form-operators/crud-form-operators.reducer";
import report from "./table-report-panel/table-report-panel.reducer";

export const rootReducer = combineReducers({
  operator,
  report,
});

export default rootReducer;
