import { takeLatest, put } from "redux-saga/effects";

import plan_panel_types from "./plan-panel.types";

import api from "core/services/api";

export function* getPlansAsync() {
  yield put({ type: plan_panel_types.LOADING_GET_PLAN });

  try {
    const { data } = yield api.get("plan");

    yield put({
      type: plan_panel_types.SUCCESS_GET_PLAN,
      payload: data,
    });

    yield put({
      type: plan_panel_types.SET_PLAN,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: plan_panel_types.FAIL_GET_PLAN,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}

export function* getPlans() {
  yield takeLatest(plan_panel_types.GET_PLAN, getPlansAsync);
}
