import React, { useState } from "react";
import { Popover, Input, DatePicker, Form, message } from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";

//import ModalPro from "core/components/modal-pro/modal-pro.container";
import Button from "core/components/button/button.component";

import { ContentStyles, ContainerButton, Body } from "./anotation.styles";

const { TextArea } = Input;

const Anotation = ({
  //setModalProps = () => null,
  setNotificationVisible = () => null,
  postNotification = () => null,
  proActive = () => null,
  getPlan = () => null,
  title = "Nova anotação",
  children,
}) => {
  const [form] = Form.useForm();
  const [popVisible, setPopVisisble] = useState(false);
  const [loading, setLoading] = useState(false);
  const pro = getPlan({name: "notification"});

  const validateMessages = {
    required: "campo obrigatório!",
    types: {
      email: "campo tipo e-mail!",
      number: "campo tipo numero",
    },
  };

  const onFinish = async (fields) => {
    setLoading(true);
    if (pro === false) {
      const response = await postNotification(fields);
      if (response === true) {
        message.success("Salvo com successo!");
        form.resetFields();
        proActive()
      } else message.error(response);
      setPopVisisble(false);
      setLoading(false);
    }
  };

  const content = () => {
    return (
      <ContentStyles>
        <Form
          onFinish={onFinish}
          form={form}
          validateMessages={validateMessages}
        >
          <Body>
            <Form.Item name="notification_date" rules={[{ required: true }]}>
              <DatePicker
                locale={locale}
                showTime={{ format: "HH:mm" }}
                placeholder="Selecione a data"
                style={{ width: "100%" }}
                format="DD/MM/YYYY HH:mm"
              />
            </Form.Item>
            <Form.Item name="title" rules={[{ required: true }]}>
              <Input placeholder="Título" />
            </Form.Item>
            <Form.Item name="description" label="">
              <TextArea rows={4} placeholder="Escreva sua anotação aqui..." />
            </Form.Item>
            <ContainerButton>
              <Button
                btntype="defaultOutlined"
                onClick={() => setPopVisisble(false)}
              >
                Cancelar
              </Button>
              <Button btntype="secondary" htmlType="submit" loading={loading}>
                Salvar
              </Button>
            </ContainerButton>
          </Body>
        </Form>
      </ContentStyles>
    );
  };

  return (
    <Popover
      content={content}
      title={title}
      placement="leftTop"
      visible={popVisible && pro === false}
      trigger="click"
      onVisibleChange={(visible) => {
        setPopVisisble(visible);
        if (/* pro === true &&  */visible === true) {
         /*  setModalProps({
            visible: true,
            close: true,
            title: "",
            width: "400px",
            body: <ModalPro />,
          }); */
          setPopVisisble(false);
          setNotificationVisible(false);
        }
        form.resetFields();
      }}
    >
      {children}
    </Popover>
  );
};

export default Anotation;
