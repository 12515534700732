import React, { useState, useCallback, useEffect } from "react";
import { Popover, Badge, Tag, Tooltip } from "antd";
import moment from "moment";
import { FaBook } from "react-icons/fa/index";
import { GiReceiveMoney } from "react-icons/gi/index";
import { FcSurvey } from "react-icons/fc/index";
import { IoMdMegaphone, IoMdHelp } from "react-icons/io/index";
import { MdUpdate } from "react-icons/md/index";
import {
  BellOutlined,
  CheckCircleOutlined,
  PlusOutlined,
  MoreOutlined,
} from "@ant-design/icons/";

import Anotation from "core/components/anotation/anotation.container";
import Message from "core/components/notification/notification.component";

import {
  NotificationsList,
  Notification,
  HeaderNotification,
  NotificationIcon,
  NotificationTexts,
  NotificationTitle,
  NotificationDescription,
  NotificationDate,
  NotificationClose,
  NotificationButtons,
  ButtonNotification,
  FocusStyles,
  NotificationOthersItem,
} from "./notifications.styles";

const Notifications = ({
  postNotificationCallback = () => null,
  getNotifications = () => null,
  deleteNotifications = () => null,
  updatePlan = () => null,
  notifications_props = {}, 
}) => {
  const [activeNotifications, setActiveNotifications] = useState([false, 0]);
  const [notifications, setNotifications] = useState([]);
  const [notificationVisible, setNotificationVisible] = useState(false);

  const onGetNotificationsCallback = useCallback(async () => {
    getNotifications();
  }, [getNotifications]);

  useEffect(() => {
    onGetNotificationsCallback();
  }, [onGetNotificationsCallback]);

  useEffect(() => {
    if (!notifications_props.loading && notifications_props.items.length > 0) {
      setNotifications(notifications_props.items);
    }
  }, [notifications_props]);

  const getIconNotification = (type, color) => {
    switch (type) {
      case "receivable":
        return <GiReceiveMoney color={color} />;
      case "manual":
        return <FaBook color={color} />;
      case "survey":
        return <FcSurvey color={color} />;
      case "marketing":
        return <IoMdMegaphone color={color} />;
      case "updates":
        return <MdUpdate color={color} />;
      default:
        return <IoMdHelp color={color} />;
    }
  };

  const contentNotification = () => {
    return (
      <NotificationsList activeNotifications={activeNotifications}>
        {notifications.length <= 0 ? (
          <h2 style={{ textAlign: "center", verticalAlign: "center" }}>
            Não há notificações
          </h2>
        ) : (
          notifications.map((item, index) => {
            return (
              <Notification
                key={index}
                style={{ opacity: item.removing ? 0.5 : 1 }}
              >
                <NotificationIcon>
                  {getIconNotification(
                    item.notification_type,
                    "rgba(0, 0, 0, 0.5)"
                  )}
                </NotificationIcon>
                <NotificationTexts>
                  <NotificationTitle>
                    <Tooltip title={item.title}>
                      <p>{item.title}</p>
                    </Tooltip>
                    <NotificationClose
                      onClick={async () => {
                        let newData = notifications?.slice();
                        const itemSelec = newData[newData.indexOf(item)];
                        itemSelec.removing = true;
                        setNotifications(newData);
                        const response = await deleteNotifications(item.id);
                        if (response === true) {
                          itemSelec.removing = false;
                          const removed = notifications.filter((item2) => {
                            return item !== item2;
                          });
                          setActiveNotifications([true, index]);
                          setNotifications(removed);
                          setTimeout(() => {
                            setActiveNotifications([false, index]);
                          }, 500);
                        } else {
                          Message("error", response);
                        }
                      }}
                    >
                      x
                    </NotificationClose>
                  </NotificationTitle>
                  <Tooltip title={item.description || "Sem descrição"}>
                    <NotificationDescription>
                      {item.description || "Sem descrição"}
                    </NotificationDescription>
                  </Tooltip>
                  <NotificationButtons>
                    <NotificationDate>
                      {moment.unix(item.notification_date).format("DD/MM/YYYY")}
                    </NotificationDate>
                    <div style={{ display: "flex", gap: "5px" }}>
                      {item?.call_to_action?.length > 0 ? (
                        item?.call_to_action[1] ? (
                          <ButtonNotification
                            btntype="fiveOutlined"
                            size={"small"}
                            style={{ fontWeight: "300" }}
                            onClick={async () => {
                              let newData = notifications?.slice();
                              const itemSelec = newData[newData.indexOf(item)];
                              itemSelec.loading_btn2 = true;
                              setNotifications(newData);
                              const response = await postNotificationCallback(
                                item.id,
                                item?.call_to_action[1]
                              );
                              itemSelec.loading_btn2 = false;
                              if (response) {
                                delete item?.call_to_action;
                              } else {
                                Message("error", response);
                              }
                              newData = notifications?.slice();
                              itemSelec.loading_btn2 = false;
                              setNotifications(newData);
                            }}
                          >
                            {item?.call_to_action[1]?.name}
                          </ButtonNotification>
                        ) : null
                      ) : null}
                      {item?.call_to_action?.length > 0 ? (
                        item?.call_to_action[0] ? (
                          <ButtonNotification
                            btntype="secondary"
                            size={"small"}
                            style={{ fontWeight: "300" }}
                            loading={item.loading_btn1}
                            onClick={async () => {
                              let newData = notifications?.slice();
                              const itemSelec = newData[newData.indexOf(item)];
                              itemSelec.loading_btn1 = true;
                              setNotifications(newData);
                              const response = await postNotificationCallback(
                                item.id,
                                item?.call_to_action[0]
                              );
                              if (response === true) {
                                delete item?.call_to_action;
                              }
                              newData = notifications?.slice();
                              itemSelec.loading_btn1 = false;
                              setNotifications(newData);
                            }}
                          >
                            {item?.call_to_action[0].name}
                          </ButtonNotification>
                        ) : null
                      ) : item.notification_type === "receivable" ? (
                        <Tag icon={<CheckCircleOutlined />} color="success">
                          Pago
                        </Tag>
                      ) : null}
                    </div>
                  </NotificationButtons>
                </NotificationTexts>
              </Notification>
            );
          })
        )}
      </NotificationsList>
    );
  };

  return (
    <Badge count={notifications.length}>
      <FocusStyles>
        <Popover
          placement="leftTop"
          content={contentNotification()}
          trigger="click"
          visible={notificationVisible}
          onVisibleChange={(visible) => {
            setNotificationVisible(visible);
          }}
          overlayClassName="new-notification-custom"
          title={
            <HeaderNotification>
              <h3>Notificações</h3>
              <div
                style={{ display: "flex", gap: "16px", marginRight: "-8px" }}
              >
                <Anotation
                  setNotificationVisible={setNotificationVisible}
                  proActive={() => updatePlan({name: "notification"})}
                >
                  <Tooltip title="Nova anotação" placement="leftTop">
                    <NotificationOthersItem>
                      <PlusOutlined />
                    </NotificationOthersItem>
                  </Tooltip>
                </Anotation>
                <Popover
                  placement="rightTop"
                  content={
                    <div>
                      <NotificationOthersItem
                        onClick={async () => {
                          const response = await deleteNotifications(
                            notifications
                          );
                          if (response === true) {
                            setNotifications([]);
                          } else {
                            Message("error", response);
                          }
                        }}
                      >
                        Limpar notificações
                      </NotificationOthersItem>
                    </div>
                  }
                  trigger="click"
                  title={""}
                >
                  <Tooltip title="Mais opções" placement="rightTop">
                    <NotificationOthersItem>
                      <MoreOutlined style={{ fontSize: "18px" }} />
                    </NotificationOthersItem>
                  </Tooltip>
                </Popover>
              </div>
            </HeaderNotification>
          }
        >
          <BellOutlined />
        </Popover>
      </FocusStyles>
    </Badge>
  );
};

export default Notifications;
