const types = { 
    "GET_IMPORTS": "GET_IMPORTS", 
    "SUCCESS_GET_IMPORTS": "SUCCESS_GET_IMPORTS",
    "FAIL_GET_IMPORTS": "FAIL_GET_IMPORTS", 
    "PATCH_IMPORTS": "PATCH_IMPORTS", 
    "SUCCESS_PATCH_IMPORTS": "SUCCESS_PATCH_IMPORTS",
    "FAIL_PATCH_IMPORTS": "FAIL_PATCH_IMPORTS",
    "DELETE_IMPORTS": "DELETE_IMPORTS", 
    "SUCCESS_DELETE_IMPORTS": "SUCCESS_DELETE_IMPORTS",
    "FAIL_DELETE_IMPORTS": "FAIL_DELETE_IMPORTS", 
  };
  
  export default types;
  