import { combineReducers } from "redux";

//reducers
import cep from "./cep/cep.reducer";
import cnpj from "./cnpj/cnpj.reducer";
import drawer from "./drawer/drawer.reducer";
import bank from "./bank/bank.reducer";
import modal from "./modal/modal.reducer";
import tour from "./tour/tour.reducer";
import notification from "./notification/notification.reducer";
import cache from "./cache/cache.reducer";
import mcc from "./mcc/mcc.reducer";

export const rootReducer = combineReducers({
  drawer_props: drawer, 
  modal_props: modal,
  tour_props: tour,
  notification,
  cnpj,
  cep,
  bank,
  cache,
  mcc
});

export default rootReducer;
