import { all, call } from "redux-saga/effects";

import { authCnpj } from "./cnpj/cnpj.sagas";
import { authCep } from "./cep/cep.sagas";
import { authBank } from "./bank/bank.sagas"; 
import { getTour } from "./tour/tour.sagas";
import { getNotification } from "./notification/notification.sagas"; 
import { getCache } from "./cache/cache.sagas";

export default function* rootSaga() {
  yield all([
      call(authCnpj),
      call(authCep),
      call(authBank),
      call(getTour),
      call(getNotification),
      call(getCache)
    ]);
}
