import notification_types from "./notification.types";
import fluxopayAPI from "core/services/api"; 

export const postNotifications = (params) => async (dispatch) => {
  try {
    dispatch({
      type: notification_types.POST_NOTIFICATION,
    });
  
    const payload = {
      tours: params
    }
    await fluxopayAPI.post(`onboarding`, payload);

    dispatch({
      type: notification_types.SUCCESS_POST_NOTIFICATION,
      payload,
    }); 
    return true;
  } catch (err) {
    dispatch({
      type: notification_types.FAIL_POST_NOTIFICATION,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}; 

export const postNotificationCallback = (id, call_to_action) => async (dispatch) => {
  try {
    dispatch({
      type: notification_types.POST_CALLBACK_NOTIFICATION,
    });
  
    const payload = {
      notification_id: id,
      callback: call_to_action
    }
    await fluxopayAPI.post(`notification/callback`, payload);

    dispatch({
      type: notification_types.SUCCESS_POST_CALLBACK_NOTIFICATION,
      payload,
    }); 
    return true;
  } catch (err) {
    dispatch({
      type: notification_types.FAIL_POST_CALLBACK_NOTIFICATION,
      payload: err?.response?.data?.error || "Erro inesperado!",
    });
  }
}; 

export const getNotifications = () => (dispatch) => {   
  dispatch({
    type: notification_types.GET_NOTIFICATION, 
  }); 
}; 
 
export const deleteNotifications = (params) => async (dispatch) => {
  dispatch({ type: notification_types.DEL_NOTIFICATION });
  try { 
    let payload = params;
    if(Array.isArray(params)){ 
      payload = params.map((item) =>{ 
        return `${item.id}`
      }) 
    }   
    if(payload.length > 0){
      const { data } = await fluxopayAPI.delete(`notification?notification_ids=${payload}`);
      await dispatch({
        type: notification_types.SUCCESS_DEL_NOTIFICATION,
        payload: data,
      });
    }
    else
      await dispatch({
        type: notification_types.FAIL_DEL_NOTIFICATION,
      }); 
    return true;
  } catch (err) {
    await dispatch({
      type: notification_types.FAIL_DEL_NOTIFICATION,
      payload: err?.response?.data?.error || "Erro inesperado!",
    }); 
    return err?.response?.data?.error || "Erro inesperado!"
  }
};
 