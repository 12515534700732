import drawer_types from "./drawer.types";
 
const INITIAL_STATE = {
  visible: false
};

const drawer_reducer = (state = INITIAL_STATE, action) => {
  
  switch (action.type) {
    case drawer_types.SET_DRAWER_PROPS:
      return {
         ...INITIAL_STATE
      };

    case drawer_types.SUCCESS_SET_DRAWER_PROPS:
      return {
        ...action.payload
    };

    case drawer_types.FAIL_SET_DRAWER_PROPS:
      return {
        ...INITIAL_STATE
    };

    case drawer_types.CLOSE_DRAWER:
      return {
        visible: false
      };

    
    case drawer_types.FAIL_CLOSE_DRAWER:
      return {
        visible: false
      };
 
    default:
      return state;
  }
};

export default drawer_reducer;