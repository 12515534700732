import styled from "styled-components";


export const ContentStyles = styled.div`

`;

export const Body = styled.div`
display: flex;
flex-flow: column; 
width: 300px;
`;

export const ContainerButton = styled.div`
display: flex;
justify-content: flex-end;
gap: 8px;
width: 100%;
`;